import React, { useState } from "react";
import { PageHeader } from "../../components/page-header";
import { Button } from "@mui/material";
import { Card } from "../../components/card";
import { SearchField } from "../../components/fields";
import { TableView } from "../../components/table-view";
import { employeesColumns } from "./resource";
import { useFetchAllEmployeesQuery } from "../../core/store";
import get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { ROUTE_EMPLOYEES_CREATE } from "../../core/constants";
import isEmpty from "lodash/isEmpty";
import { EmptyState } from "../../components/empty-state";
import { IconFolder } from "../../components/icons";
import { getRangeByName, toBackFormat } from "../../core/utils/date.utils";
import { normalizeSort } from "../../core/utils/data.utils";

const range = getRangeByName("total");

export const EmployeesContainer = () => {
  const navigate = useNavigate();
  const [sortState, setSortState] = useState<Record<string, "desc" | "asc">>(
    {}
  );

  const [filter, setFilter] = useState({
    statsFromDate: toBackFormat(range.from),
    statsToDate: toBackFormat(range.to),
  });

  const { data, isLoading, isFetching } = useFetchAllEmployeesQuery(filter);

  const handleSearch = (search) => {
    setFilter((prev) => ({ ...prev, search }));
  };

  const handleSort = (sortState) => {
    setSortState(sortState);
    if (isEmpty(sortState)) {
      setFilter((prev) => ({ ...prev, sort: null }));
    } else {
      setFilter((prev) => ({ ...prev, sort: normalizeSort(sortState) }));
    }
  };

  return (
    <>
      <PageHeader title="Employees">
        <Button onClick={() => navigate(ROUTE_EMPLOYEES_CREATE)}>
          Add new employee
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <SearchField
          placeholder="Search by name"
          sx={{ mb: 4 }}
          onChange={handleSearch}
        />
        {isEmpty(data) || isFetching ? (
          <EmptyState
            icon={<IconFolder />}
            loading={isFetching}
            description="0 employees found"
          />
        ) : (
          <TableView
            striped
            defaultSortState={sortState}
            dataSource={get(data, "items", [])}
            onChangeSort={handleSort}
            columns={employeesColumns}
          />
        )}
      </Card>
    </>
  );
};
