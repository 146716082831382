import React, { ComponentType, memo, useState } from "react";
import useStyles from "./styles";
import {
  MenuItem,
  TextField,
  TextFieldProps,
  Box,
  Chip,
  ListSubheader,
} from "@mui/material";
import { IconClose, IconSelect } from "../../icons";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import { groupBy, isArray, map } from "lodash";

interface ItemType {
  label: string;
  value: any;
  group?: string;
}

interface Props extends Omit<TextFieldProps, "onChange"> {
  onChange: (value: any) => void;
  items: ItemType[];
  multiple?: boolean;
  placeholder?: string;
  group?: boolean;
}

export const SelectField: ComponentType<Props> = memo(
  ({
    items,
    multiple = false,
    onChange,
    label,
    value,
    placeholder,
    error,
    helperText,
    group,
  }) => {
    const classes = useStyles();
    const itemsKeyByValue = keyBy(items, "value");

    const handleChange = (e) => {
      onChange(e.target.value);
    };

    const handleDelete = (deleteValue: any) => () => {
      onChange(isArray(value) ? value.filter((v) => v !== deleteValue) : "");
    };

    return (
      <TextField
        select
        {...{ label, value, error, helperText }}
        SelectProps={{
          IconComponent: IconSelect,
          multiple,
          displayEmpty: !!placeholder,
          onChange: handleChange,
          renderValue: multiple
            ? (selected: any) => {
                if (selected.length === 0) {
                  return <>{placeholder}</>;
                }
                return (
                  <Box className={classes.chipContainer}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={get(itemsKeyByValue, [value, "label"], value)}
                        size="small"
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        deleteIcon={<IconClose />}
                        onDelete={handleDelete(value)}
                      />
                    ))}
                  </Box>
                );
              }
            : null,
        }}
      >
        {group
          ? map(groupBy(items, "group"), (groupItems, group) => [
              <ListSubheader key={group} className={classes.menuHeader}>
                {group}
              </ListSubheader>,
              ...groupItems.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  classes={{
                    root: classes.menuItem,
                    selected: classes.menuItemSelected,
                  }}
                >
                  {option.label}
                </MenuItem>
              )),
            ])
          : items.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                classes={{
                  root: classes.menuItem,
                  selected: classes.menuItemSelected,
                }}
              >
                {option.label}
              </MenuItem>
            ))}
      </TextField>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value &&
      prevProps.error === nextProps.error &&
      prevProps.helperText === nextProps.helperText
    );
  }
);
