export const profileFilterItems = [
  {
    label: "Month",
    value: "month",
  },
  {
    label: "3 months",
    value: "3_month",
  },
  {
    label: "6 months",
    value: "6_month",
  },
  {
    label: "Total",
    value: "total",
  },
];

export const groupLists = [
  { id: 1, name: "Soho, NY" },
  { id: 2, name: "Denver" },
];

export const statusList = [
  { label: "New", value: "new" },
  { label: "In progress", value: "in-progress" },
  { label: "On review", value: "on-review" },
  { label: "Matched", value: "matched" },
];

export const locationList = [
  { label: "New York", value: "new_york" },
  { label: "Los Angeles", value: "los_angeles" },
  { label: "Chicago", value: "chicago" },
  { label: "Houston", value: "houston" },
];

export const businessTypeList = [
  { label: "Shoes", value: "Shoes", group: "Retail" },
  { label: "Accessories", value: "Accessories", group: "Retail" },
  { label: "Clothing", value: "Clothing", group: "Retail" },
  { label: "Everyday products", value: "Everyday products", group: "Retail" },
  { label: "Electronics", value: "Electronics", group: "Retail" },
  { label: "Smoke shop", value: "Smoke shop", group: "Retail" },
  {
    label: "Health product/equipment",
    value: "Health product/equipment",
    group: "Retail",
  },
  { label: "Bank", value: "Bank", group: "Retail" },
  { label: "Grooming", value: "Grooming", group: "Service" },
  { label: "Beauty salon", value: "Beauty salon", group: "Service" },
  { label: "Health care", value: "Health care", group: "Service" },
  {
    label: "Lounge/event space",
    value: "Lounge/event space",
    group: "Service",
  },
  { label: "Gym/trainer", value: "Gym/trainer", group: "Service" },
  { label: "Gallery", value: "Gallery", group: "Service" },
  {
    label: "Dry use/ non venting",
    value: "Dry use/ non venting",
    group: "Food & Beverages",
  },
  {
    label: "Partial venting",
    value: "Partial venting",
    group: "Food & Beverages",
  },
  { label: "Full venting", value: "Full venting", group: "Food & Beverages" },
  {
    label: "Juices and drinks",
    value: "Juices and drinks",
    group: "Food & Beverages",
  },
  { label: "Coffee", value: "Coffee", group: "Food & Beverages" },
  { label: "Bakery", value: "Bakery", group: "Food & Beverages" },
  {
    label: "Bowls, and finger foods",
    value: "Bowls, and finger foods",
    group: "Food & Beverages",
  },
  {
    label: "Health products",
    value: "Health products",
    group: "Food & Beverages",
  },
  // { label: "Clothing", value: "clothing" },
  // { label: "Accessories", value: "accessories" },
  // { label: "Coffee", value: "coffee" },
  // { label: "Juices and drinks", value: "juices and drinks" },
  // { label: "Gym/trainer", value: "gym/trainer" },
  // { label: "Everyday products", value: "everyday products" },
  // { label: "Health product/ equipment", value: "health product/ equipment" },
  // { label: "Restaurant", value: "restaurant" },
  // { label: "Bar", value: "bar" },
  // { label: "Barbershop", value: "barbershop" },
  // { label: "Cafe", value: "cafe" },
  // { label: "Other", value: "other" },
];

export const areaOfPremisesList = [
  { label: ">200", value: "more200" },
  { label: "300 - 500", value: "300_500" },
];

export const ageGroupList = [
  { label: ">18", value: ">18" },
  { label: "18-30", value: "18-30" },
  { label: "30-45", value: "30-45" },
  { label: "45+", value: "45+" },
];

export const employeesList = [
  { label: "1-5", value: "1-5" },
  { label: "5-25", value: "5-25" },
  { label: "25-50", value: "25-50" },
  { label: "50-100", value: "50-100" },
  { label: "100-500", value: "100-500" },
  { label: "500-1000", value: "500-1000" },
];

export const employeeRoleList = [
  { label: "Sales manager", value: "SALES_MANAGER" },
  { label: "Admin", value: "ADMIN" },
];

export const genderList = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Unisex", value: "Unisex" },
  { label: "Other", value: "Other" },
];

export const budgetList = [
  { label: "$2,000-5,000", value: "2000-5000" },
  { label: "$5,000-10,000", value: "5000-10000" },
];

export const budgetListByKey = {
  "2000-5000": "$2,000-5,000",
  "5000-10000": "$5,000-10,000",
};

export const pricingList = [
  { label: "Affordable", value: "Affordable" },
  { label: "Average", value: "Average" },
  { label: "Luxury", value: "Luxury" },
  { label: "Very luxury", value: "Very luxury" },
];

export const portrayingList = [
  { label: "Lifestyle", value: "Lifestyle" },
  { label: "Casual", value: "Casual" },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Comfort",
    value: "Comfort",
  },
  {
    label: "Business Casual",
    value: "Business Casual",
  },
  {
    label: "Affordable",
    value: "Affordable",
  },
  {
    label: "Sustainable/environmental",
    value: "Sustainable/environmental",
  },
  {
    label: "Healthy Work Environment",
    value: "Healthy Work Environment",
  },
];

export const companyList = [
  { id: 1, name: "Binford Ltd." },
  { id: 2, name: "Abstergo Ltd." },
];

export const matchingTableData = [
  {
    id: 267401,
    status: statusList[0],
    company: companyList[0],
    businessType: [businessTypeList[0], businessTypeList[1]],
    location: locationList[0],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
  },
  {
    id: 267402,
    status: statusList[1],
    company: companyList[1],
    businessType: [businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[1],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
  },
  {
    id: 267403,
    status: statusList[1],
    company: companyList[1],
    businessType: [businessTypeList[1], businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[0],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
  },
  {
    id: 267404,
    status: statusList[3],
    company: companyList[0],
    businessType: [businessTypeList[3]],
    location: locationList[3],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
  },
];

const comment =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui massa, euismod at vulputate id, vestibulum id ante. Donec iaculis tortor eu nisl scelerisque suscipit. Sed tempus leo eu urna ornare, id finibus ante scelerisque. Nullam elementum ullamcorper sapien, sit amet porta nisl cursus vitae. Mauris semper risus non pulvinar mattis. Etiam gravida luctus dictum. Sed ac nibh ut est suscipit sodales vitae malesuada risus. Nunc vehicula orci at ipsum ullamcorper ullamcorper. Aliquam id augue ut erat fermentum faucibus non at metus. Mauris dapibus egestas risus auctor gravida. Aliquam ut nisi in arcu volutpat consequat sed eu neque. Donec a scelerisque orci. Curabitur eget libero orci.";

export const groupsTableData = [
  {
    id: 558612,
    group: groupLists[0],
    clients: 4,
    location: locationList[0],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558613,
    group: groupLists[1],
    clients: 3,
    location: locationList[1],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558614,
    group: groupLists[0],
    clients: 2,
    location: locationList[2],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558615,
    group: groupLists[1],
    clients: 1,
    location: locationList[3],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558616,
    group: groupLists[0],
    clients: 0,
    location: locationList[0],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558617,
    group: groupLists[1],
    clients: 4,
    location: locationList[1],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558618,
    group: groupLists[0],
    clients: 3,
    location: locationList[2],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558619,
    group: groupLists[1],
    clients: 2,
    location: locationList[3],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558620,
    group: groupLists[0],
    clients: 1,
    location: locationList[0],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558621,
    group: groupLists[1],
    clients: 0,
    location: locationList[1],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558622,
    group: groupLists[0],
    clients: 4,
    location: locationList[2],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558623,
    group: groupLists[1],
    clients: 3,
    location: locationList[3],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558624,
    group: groupLists[0],
    clients: 2,
    location: locationList[0],
    comment,
    createdAt: new Date().toISOString(),
  },
  {
    id: 558625,
    group: groupLists[1],
    clients: 1,
    location: locationList[1],
    comment,
    createdAt: new Date().toISOString(),
  },
];

export const clientsCompanyList = [
  { id: 1, name: "Binford Ltd." },
  { id: 2, name: "Abstergo Ltd." },
  { id: 3, name: "Client (New)" },
  { id: 4, name: "Client (In progress)" },
  { id: 5, name: "Client (On review)" },
  { id: 6, name: "Client (Matched)" },
];

export const clientsTableData = [
  {
    id: 1,
    status: statusList[0],
    company: clientsCompanyList[2],
    businessType: [businessTypeList[0], businessTypeList[1]],
    location: locationList[0],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 2,
    status: statusList[1],
    company: clientsCompanyList[3],
    businessType: [businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[1],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 3,
    status: statusList[1],
    company: clientsCompanyList[4],
    businessType: [businessTypeList[1], businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[0],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 4,
    status: statusList[3],
    company: clientsCompanyList[5],
    businessType: [businessTypeList[3]],
    location: locationList[3],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267405,
    status: statusList[0],
    company: clientsCompanyList[0],
    businessType: [businessTypeList[0], businessTypeList[1]],
    location: locationList[0],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267406,
    status: statusList[1],
    company: clientsCompanyList[1],
    businessType: [businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[1],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267407,
    status: statusList[1],
    company: clientsCompanyList[1],
    businessType: [businessTypeList[1], businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[0],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267408,
    status: statusList[3],
    company: clientsCompanyList[0],
    businessType: [businessTypeList[3]],
    location: locationList[3],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267409,
    status: statusList[0],
    company: clientsCompanyList[0],
    businessType: [businessTypeList[0], businessTypeList[1]],
    location: locationList[0],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267410,
    status: statusList[1],
    company: clientsCompanyList[1],
    businessType: [businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[1],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267411,
    status: statusList[1],
    company: clientsCompanyList[1],
    businessType: [businessTypeList[1], businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[0],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267412,
    status: statusList[3],
    company: clientsCompanyList[0],
    businessType: [businessTypeList[3]],
    location: locationList[3],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267413,
    status: statusList[0],
    company: clientsCompanyList[0],
    businessType: [businessTypeList[0], businessTypeList[1]],
    location: locationList[0],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267414,
    status: statusList[1],
    company: clientsCompanyList[1],
    businessType: [businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[1],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267415,
    status: statusList[1],
    company: clientsCompanyList[1],
    businessType: [businessTypeList[1], businessTypeList[2]],
    location: locationList[1],
    area: areaOfPremisesList[0],
    budget: budgetList[1],
    portrayed: portrayingList[1],
    pricing: pricingList[2],
    requestedAt: new Date().toISOString(),
  },
  {
    id: 267416,
    status: statusList[3],
    company: clientsCompanyList[0],
    businessType: [businessTypeList[3]],
    location: locationList[3],
    area: areaOfPremisesList[0],
    budget: budgetList[0],
    portrayed: portrayingList[0],
    pricing: pricingList[0],
    requestedAt: new Date().toISOString(),
  },
];

export const historyData = [
  {
    id: 1,
    action: "Match approved",
    group: groupLists[0],
    prevStatus: statusList[2],
    status: statusList[3],
    createdAt: new Date().toISOString(),
  },
  {
    id: 2,
    action: "Sent to client",
    group: groupLists[0],
    prevStatus: statusList[2],
    status: statusList[3],
    createdAt: new Date().toISOString(),
  },
  {
    id: 3,
    action: "Added to group",
    group: groupLists[0],
    prevStatus: statusList[2],
    status: statusList[3],
    createdAt: new Date().toISOString(),
  },
  {
    id: 4,
    action: "Account creation",
    group: groupLists[0],
    prevStatus: statusList[2],
    status: statusList[3],
    createdAt: new Date().toISOString(),
  },
];

export const employeeData = [
  {
    id: 1,
    name: "Annette Black",
    matchedGroups: 321,
    rejectedGroups: 123,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 2,
    name: "Wade Warren",
    matchedGroups: 123,
    rejectedGroups: 123,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 3,
    name: "Eleanor Pena",
    matchedGroups: 312,
    rejectedGroups: 12,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 4,
    name: "Kathryn Murphy",
    matchedGroups: 412,
    rejectedGroups: 31,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 5,
    name: "Guy Hawkins",
    matchedGroups: 656,
    rejectedGroups: 4,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 6,
    name: "Cameron Williamson",
    matchedGroups: 63,
    rejectedGroups: 2,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 7,
    name: "Theresa Webb",
    matchedGroups: 0,
    rejectedGroups: 12,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 8,
    name: "Arlene McCoy",
    matchedGroups: 33,
    rejectedGroups: 56,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 9,
    name: "Leslie Alexander",
    matchedGroups: 63,
    rejectedGroups: 1,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 10,
    name: "Floyd Miles",
    matchedGroups: 0,
    rejectedGroups: 0,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 11,
    name: "Dianne Russell",
    matchedGroups: 345,
    rejectedGroups: 1,
    lastActivity: new Date().toISOString(),
  },
  {
    id: 12,
    name: "Cody Fisher",
    matchedGroups: 1,
    rejectedGroups: 1,
    lastActivity: new Date().toISOString(),
  },
];
