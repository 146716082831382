import React, { ComponentType } from "react";
import { CardProps } from "@mui/material";
import { Card } from "./Card";

interface Props extends Omit<CardProps, "variant"> {
  shadow?: boolean;
  title?: any;
  color?: "secondary";
  variant?: "outlined" | "contained";
  size?: "small" | "medium";
}

export const AuthCard: ComponentType<Props> = ({ children, ...props }) => {
  return (
    <Card
      sx={{
        p: 1,
        border: "1px solid #fff",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
      }}
      {...props}
    >
      {children}
    </Card>
  );
};
