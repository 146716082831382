import { Box } from "@mui/system";
import { ComponentType, ReactNode } from "react";
import useStyles from "./styles";

interface Props {
  label: ReactNode;
}

export const LabeledRecord: ComponentType<Props> = ({ label, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.label}>{label}</Box>
      <Box className={classes.value}>{children}</Box>
    </Box>
  );
};
