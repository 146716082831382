import React, { ComponentType } from "react";
import { BaseLayout } from "./base-layout";

export const withBaseLayout =
  (ComposedComponent: ComponentType) => (props: any) => {
    return (
      <BaseLayout>
        <ComposedComponent {...props} />
      </BaseLayout>
    );
  };
