import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "auth-layout",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: "relative",
      minHeight: "100vh",
      overflow: "hidden",
      paddingBottom: 64,
      background:
        "radial-gradient(42.69% 68.12% at 67.01% 42.78%, rgba(255, 195, 140, 0.5) 0%, rgba(255, 178, 87, 0.095) 100%), linear-gradient(143.11deg, #FF9DC0 3.84%, rgba(255, 255, 255, 0) 78.56%), #FFFFFF",

      "&:after": {
        content: "''",
        background: "url(/assets/images/hand-min.png) no-repeat right center",
        top: -170,
        right: 0,
        bottom: -170,
        left: 0,
        backgroundSize: "auto 100%",
        position: "absolute",
      },
    },
    container: {
      maxWidth: 800,
      margin: "0 auto",
      position: "relative",
      zIndex: 2,
      padding: 3,
    },
    header: {
      maxWidth: 380,
      minHeight: 168,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
  options
);

export default useStyles;
