import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { IconClose, IconSearch } from "../../icons";
import React, { ComponentType, useState } from "react";

interface Props extends Omit<TextFieldProps, "onChange" | "variant"> {
  onChange?: (value: string) => void;
}

export const SearchField: ComponentType<Props> = ({ onChange, ...props }) => {
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange && onChange(value);
    setValue(value);
  };

  const handleClear = () => {
    setValue("");
    onChange && onChange("");
  };

  return (
    <TextField
      {...props}
      onChange={handleChange}
      helperText=""
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconClose
              fontSize="small"
              cursor="pointer"
              onClick={handleClear}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
