import { flatten, groupBy, map, reduce } from "lodash";
import get from "lodash/get";
import { ClientProperties } from "../constants";
import isEmpty from "lodash/isEmpty";

export const valueToSetAny = (property: string, values: string[]) => {
  return [
    {
      operator: "SET_ANY",
      value: values.join(";"),
      property,
      type: "string",
    },
  ];
};
export const valueToSetEq = (property: string, values: string[]) => {
  return [
    {
      operator: "SET_EQ",
      value: values.join(";"),
      property,
      type: "string",
    },
  ];
};

export const valueToEq = (property: string, value) => {
  return {
    operator: "EQ",
    value,
    property,
    type: "string",
  };
};

export const valueToNq = (property: string, value) => {
  return {
    operator: "NEQ",
    value,
    property,
    type: "string",
  };
};

export const normalizeGroupFilters = (groupFilters) => {
  const groupedFilters = groupBy(
    flatten(groupFilters),
    (x: any) => `${x.property}-${x.operator}`
  );

  const locationEq = get(groupedFilters, `${ClientProperties.CITY}-EQ`, []);
  const locationNeq = get(groupedFilters, `${ClientProperties.CITY}-NEQ`, []);

  const budgetEq = get(groupedFilters, `${ClientProperties.BUDGET}-EQ`, []);
  const budgetNeq = get(groupedFilters, `${ClientProperties.BUDGET}-NEQ`, []);

  const pricingEq = get(groupedFilters, `${ClientProperties.PRICING}-EQ`, []);
  const pricingNeq = get(groupedFilters, `${ClientProperties.PRICING}-NEQ`, []);

  const portrayEq = get(groupedFilters, `${ClientProperties.PORTRAY}-EQ`, []);
  const portrayNeq = get(groupedFilters, `${ClientProperties.PORTRAY}-NEQ`, []);

  const businessTypeEq = get(
    groupedFilters,
    `${ClientProperties.BUSINESS_TYPE}-EQ`,
    []
  );
  const businessTypeNeq = get(
    groupedFilters,
    `${ClientProperties.BUSINESS_TYPE}-NEQ`,
    []
  );

  const areaOfPremisesEq = get(
    groupedFilters,
    `${ClientProperties.AREA}-EQ`,
    []
  );
  const areaOfPremisesNeq = get(
    groupedFilters,
    `${ClientProperties.AREA}-NEQ`,
    []
  );

  const ageGroupEq = get(
    groupedFilters,
    `${ClientProperties.AGE_GROUP}-EQ`,
    []
  );
  const ageGroupNeq = get(
    groupedFilters,
    `${ClientProperties.AGE_GROUP}-NEQ`,
    []
  );

  const genderEq = get(groupedFilters, `${ClientProperties.GENDER}-EQ`, []);
  const genderNeq = get(groupedFilters, `${ClientProperties.GENDER}-NEQ`, []);

  return {
    locationEq,
    locationNeq,
    budgetEq,
    budgetNeq,
    pricingEq,
    pricingNeq,
    portrayEq,
    portrayNeq,
    businessTypeEq,
    businessTypeNeq,
    areaOfPremisesEq,
    areaOfPremisesNeq,
    ageGroupEq,
    ageGroupNeq,
    genderEq,
    genderNeq,
  };
};

export const filtersToValue = (filters) => {
  return map(filters, "value");
};

export const matchingFormToGroupValues = ({ filter, exception, ...values }) => {
  const filterValues = filter
    ? [
        !isEmpty(filter.businessTypes)
          ? [
              ...map(filter.businessTypes, (value) =>
                valueToEq(ClientProperties.BUSINESS_TYPE, value)
              ),
            ]
          : [],
        !isEmpty(filter.location)
          ? [
              ...map(filter.location, (value) =>
                valueToEq(ClientProperties.CITY, value)
              ),
            ]
          : [],
        !isEmpty(filter.areaPermises)
          ? [
              ...map(filter.areaPermises, (value) =>
                valueToEq(ClientProperties.AREA, value)
              ),
            ]
          : [],
        !isEmpty(filter.ageGroup)
          ? [
              ...map(filter.ageGroup, (value) =>
                valueToEq(ClientProperties.AGE_GROUP, value)
              ),
            ]
          : [],
        !isEmpty(filter.gender)
          ? [
              ...map(filter.gender, (value) =>
                valueToEq(ClientProperties.GENDER, value)
              ),
            ]
          : [],
        !isEmpty(filter.pricing)
          ? [
              ...map(filter.pricing, (value) =>
                valueToEq(ClientProperties.PRICING, value)
              ),
            ]
          : [],
        !isEmpty(filter.budget)
          ? [
              ...map(filter.budget, (value) =>
                valueToEq(ClientProperties.BUDGET, value)
              ),
            ]
          : [],
      ]
    : [];

  const exceptionsValues = exception
    ? [
        !isEmpty(exception.noBusinessTypes)
          ? [
              ...map(exception.noBusinessTypes, (value) =>
                valueToNq(ClientProperties.BUSINESS_TYPE, value)
              ),
            ]
          : [],
        !isEmpty(exception.noLocation)
          ? [
              ...map(exception.noLocation, (value) =>
                valueToNq(ClientProperties.CITY, value)
              ),
            ]
          : [],
        !isEmpty(exception.noAreaPermises)
          ? [
              ...map(exception.noAreaPermises, (value) =>
                valueToNq(ClientProperties.AREA, value)
              ),
            ]
          : [],
        !isEmpty(exception.noAgeGroup)
          ? [
              ...map(exception.noAgeGroup, (value) =>
                valueToNq(ClientProperties.AGE_GROUP, value)
              ),
            ]
          : [],
        !isEmpty(exception.noGender)
          ? [
              ...map(exception.noGender, (value) =>
                valueToNq(ClientProperties.GENDER, value)
              ),
            ]
          : [],
      ]
    : [];

  return {
    ...values,
    dynamic: false,
    city: !isEmpty(filter?.location) ? filter?.location.join(", ") : "-",
    filters: [
      ...reduce(
        filterValues,
        (acc, value) => {
          if (!isEmpty(value)) {
            return [...acc, value];
          }
          return acc;
        },
        []
      ),
      ...reduce(
        exceptionsValues,
        (acc, value) => {
          if (!isEmpty(value)) {
            return [...acc, value];
          }
          return acc;
        },
        []
      ),
    ],
  };
};

export const groupFiltersToMatching = (groupFilters) => {
  const values = normalizeGroupFilters(groupFilters);

  return {
    location: filtersToValue(values.locationEq),
    businessTypes: filtersToValue(values.businessTypeEq),
    areaPermises: filtersToValue(values.areaOfPremisesEq),
    ageGroup: filtersToValue(values.ageGroupEq),
    gender: filtersToValue(values.genderEq),
    budget: filtersToValue(values.budgetEq),
    pricing: filtersToValue(values.pricingEq),
    portraing: filtersToValue(values.portrayEq),
    noLocation: filtersToValue(values.locationNeq),
    noBusinessTypes: filtersToValue(values.businessTypeNeq),
    noAreaPermises: filtersToValue(values.areaOfPremisesNeq),
    noAgeGroup: filtersToValue(values.ageGroupNeq),
    noGender: filtersToValue(values.genderNeq),
    noBudget: filtersToValue(values.budgetNeq),
    noPricing: filtersToValue(values.pricingNeq),
    noPortraing: filtersToValue(values.portrayNeq),
  };
};
