import { Box } from "@mui/system";
import React, { ComponentType } from "react";
import useStyles from "./styles";
import { Sidebar } from "../sidebar";

export const ClearBaseLayout: ComponentType = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Sidebar width={216} mobileOpen={true} setMobileOpen={() => {}} />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};
