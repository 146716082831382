import React from "react";
import MaterialChip, { ChipProps } from "@mui/material/Chip";
import useStyles from "./styles";
import clsx from "clsx";

export type ChipColors =
  | "primary"
  | "secondary"
  | "default"
  | "mediumLightBlue"
  | "lightBlue"
  | "yellow"
  | "success"
  | "orange";

interface Props extends Omit<ChipProps, "color"> {
  color?: ChipColors;
}

export const Chip = ({ color, ...props }: Props) => {
  const classes = useStyles();

  return (
    <MaterialChip
      className={clsx(classes.root, {
        [classes.mediumLightBlue]: color === "mediumLightBlue",
        [classes.lightBlue]: color === "lightBlue",
        [classes.yellow]: color === "yellow",
        [classes.success]: color === "success",
        [classes.orange]: color === "orange",
      })}
      {...props}
    />
  );
};
