import { Expose, Transform, Type } from "class-transformer";
import { CompanyDto } from "./CompanyDto";

export class ClientDto {
  @Expose()
  id: number;

  @Expose()
  @Type(() => CompanyDto)
  company: CompanyDto;

  @Expose()
  location: number;

  @Expose()
  area: number;

  @Expose()
  budget: number;

  @Expose()
  portraying: number;

  @Expose()
  pricing: number;

  @Expose()
  status: number;

  @Expose()
  @Transform((value) => new Date(value))
  requestDate: Date;
}
