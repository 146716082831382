import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ClearBaseLayout } from "../components/layouts/clear-base-layout";

export const Page404 = () => {
  return (
    <ClearBaseLayout>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          background:
            "url(/assets/images/hand-404-min.png) no-repeat center center",
          backgroundSize: "auto 100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: 415, textAlign: "center" }}>
          <Typography
            variant="h1"
            sx={{ fontSize: 80, fontWeight: 700, lineHeight: "84px", mb: 1.5 }}
          >
            404
          </Typography>
          <Typography variant="h3" marginBottom={1.5}>
            Page not found
          </Typography>
          <Typography variant="body2">
            We're sorry, the page you were looking for isn't found here. The
            link you followed may either be broken or no longer exists. Please
            try again, or take a look at our.
          </Typography>
        </Box>
      </Box>
    </ClearBaseLayout>
  );
};
