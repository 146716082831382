import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Space } from "../../components/space";
import { GoogleButton, LinkButton } from "../../components/buttons";
import { ROUTE_FORGOT_PASSWORD, ROUTE_HOME } from "../../core/constants";
import { PasswordField } from "../../components/fields";
import { AuthCard } from "../../components/card/AuthCard";
import { useLogin } from "../../core/hooks/useLogin";
import { useNavigate } from "react-router-dom";

export const SignInContainer = () => {
  const navigation = useNavigate();
  const { handleSubmit, getFieldProps, errors, values, isLoading } = useLogin({
    onSuccess: () => {
      navigation(ROUTE_HOME);
    },
  });

  return (
    <Box sx={{ minWidth: 380, maxWidth: 380 }}>
      <Typography variant="h1" textAlign="center" mb={3}>
        Login
      </Typography>
      <AuthCard>
        <Space spacing={1} direction="column">
          <TextField label="Email" {...getFieldProps("email")} />
          <PasswordField label="Password" {...getFieldProps("password")} />
        </Space>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <LinkButton
            to={ROUTE_FORGOT_PASSWORD}
            variant="body2"
            color="common.grey"
          >
            Forgot password?
          </LinkButton>
          <Button onClick={() => handleSubmit()} disabled={isLoading}>
            Login
          </Button>
        </Stack>
        {/*{errors && (*/}
        {/*  <Alert variant="filled" severity="error" icon={false} sx={{ mt: 2 }}>*/}
        {/*    Authorization failed. Please make sure that Email and password are*/}
        {/*    correct*/}
        {/*  </Alert>*/}
        {/*)}*/}
      </AuthCard>
      {/*<Box sx={{ mt: 3, mb: 3, textAlign: "center" }}>*/}
      {/*  <Typography variant="body3" color="common.grey">*/}
      {/*    Or sign in with*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
      {/*<GoogleButton onClick={() => {}}>Sign in with Google</GoogleButton>*/}
      {/*<Typography*/}
      {/*  variant="body2"*/}
      {/*  color="common.grey"*/}
      {/*  textAlign="center"*/}
      {/*  marginTop={3}*/}
      {/*>*/}
      {/*  I dont have an account. {""}*/}
      {/*  <LinkButton to={ROUTE_SIGN_UP} color="common.grey">*/}
      {/*    Sign up*/}
      {/*  </LinkButton>*/}
      {/*</Typography>*/}
    </Box>
  );
};
