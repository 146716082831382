import { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DialogProps } from "../../../core/providers/dialog.provider";

interface Props extends DialogProps {
  onConfirmWithSame: () => void;
  onConfirmWithNew: () => void;
}

export const DuplicateDialog: ComponentType<Props> = ({
  popDialog,
  onConfirmWithSame,
  onConfirmWithNew,
}) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { maxWidth: 576 } }}
    >
      <DialogTitle>Do you want to duplicate group?</DialogTitle>
      <DialogActions>
        <Button onClick={onConfirmWithSame}>Duplicate with same filters</Button>
        <Button onClick={onConfirmWithNew}>Duplicate with new filters</Button>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
