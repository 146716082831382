import {
  useCreateClientMutation,
  useFetchOneClientMutation,
  useUpdateClientMutation,
} from "../store";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import get from "lodash/get";
import { map, reduce } from "lodash";
import { useNavigate } from "react-router-dom";
import { url } from "../utils/route.utils";
import { ClientProperties, ROUTE_CLIENT_DETAIL } from "../constants";
import { useSnackbar } from "./useSnackbar";
import isEmpty from "lodash/isEmpty";

interface UseClientFormProps {
  id?: number | string;
}

export const useClientForm = ({ id }: UseClientFormProps) => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [createOne] = useCreateClientMutation();
  const [updateOne] = useUpdateClientMutation();
  const [getClientData, { data }] = useFetchOneClientMutation();

  useEffect(() => {
    id && getClientData({ id });
  }, [id]);

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      [ClientProperties.COMPANY]: "",
      [ClientProperties.FIRSTNAME]: "",
      [ClientProperties.LASTNAME]: "",
      [ClientProperties.EMAIL_ADDRESS]: "",
      [ClientProperties.PHONE_NUMBER]: "",
      [ClientProperties.NUMBER_EMPLOYEES]: "",
      [ClientProperties.WEBSITE]: "",
      [ClientProperties.SOCIALS]: "",
      [ClientProperties.COMMENT]: "",
      [ClientProperties.RETAIL_SPOTS]: "",
      // requestStatus: [],
      [ClientProperties.CITY]: "",
      // [ClientProperties.STATE]: "",
      business_type_temp: [],
      [ClientProperties.BUSINESS_TYPE]: "",
      [ClientProperties.BUSINESS_TYPE2]: "",
      [ClientProperties.BUSINESS_TYPE3]: "",
      [ClientProperties.PRICING]: "",
      [ClientProperties.PORTRAY]: "",
      [ClientProperties.BUDGET]: "",
      [ClientProperties.GENDER]: "",
      [ClientProperties.AREA]: "",
      age_temp: [],
      [ClientProperties.AGE]: "",
      [ClientProperties.AGE2]: "",
    },
    validationSchema: yup.object().shape({
      [ClientProperties.COMPANY]: yup.string().required("Required field"),
      [ClientProperties.LASTNAME]: yup.string().required("Required field"),
      [ClientProperties.FIRSTNAME]: yup.string().required("Required field"),
      [ClientProperties.EMAIL_ADDRESS]: yup
        .string()
        .email()
        .required("Required field"),
      [ClientProperties.PHONE_NUMBER]: yup.string().required("Required field"),
      [ClientProperties.NUMBER_EMPLOYEES]: yup
        .string()
        .required("Required field"),
      [ClientProperties.WEBSITE]: yup
        .string()
        .url("Website must be a valid URL")
        .required("Required field"),
      [ClientProperties.SOCIALS]: yup.string().required("Required field"),
      [ClientProperties.RETAIL_SPOTS]: yup.string().required("Required field"),
      [ClientProperties.COMMENT]: yup.string().required("Required field"),
      // requestStatus: yup.array().min(1, "Required field"),
      [ClientProperties.CITY]: yup.string().required("Required field"),
      business_type_temp: yup
        .array()
        .min(1, "Required field")
        .max(3, "Max 3 items"),
      age_temp: yup.array().min(1, "Required field").max(2, "Max 2 items"),
      [ClientProperties.PRICING]: yup.string().required("Required field"),
      [ClientProperties.PORTRAY]: yup.string().required("Required field"),
      [ClientProperties.BUDGET]: yup.string().required("Required field"),
      [ClientProperties.GENDER]: yup.string().required("Required field"),
      [ClientProperties.AREA]: yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      const normalizedValues = normalizePayload(values);
      setIsLoading(true);
      createOne(normalizedValues)
        .unwrap()
        .then((res) => {
          navigate(url(ROUTE_CLIENT_DETAIL, { id: res.vid }));
          showMessage("Client created successfully");
        })
        .catch((err) => {
          const errorMessages = get(
            err,
            "data.errorDescription.validationResults"
          );
          const errors = get(err, "data.errorDescription.errors");

          if (!isEmpty(errorMessages)) {
            const message = reduce(
              errorMessages,
              (acc, item) => {
                return [...acc, item.message];
              },
              []
            );

            showMessage(message.join(", "), "error");
          } else if (!isEmpty(errors)) {
            const message = map(errors, (x) => x.message).join(", ");
            showMessage(message, "error");
          } else {
            showMessage("Something went wrong", "error");
          }
        })
        .finally(() => setIsLoading(false));
    },
  });

  const normalizePayload = ({
    business_type_temp,
    age_temp,
    ...values
  }: any) => {
    return {
      properties: [
        ...map(values, (value, key) => {
          return {
            property: key,
            value: Array.isArray(value) ? value.join(",") : value,
          };
        }),
        {
          property: "client_status",
          value: "new",
        },
      ],
    };
  };

  const getFieldProps = (fieldName) => {
    const error = get(errors, fieldName) && get(touched, fieldName);

    return {
      error: !!error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      value: get(values, fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    getFieldProps,
    isLoading,
  };
};
