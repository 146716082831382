import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "labeled-record",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    label: {
      fontSize: 12,
      lineHeight: "24px",
      fontWeight: 500,
      color: theme.palette.common.grey,
      marginBottom: theme.spacing(0.5),
    },
    value: {
      fontSize: 14,
      lineHeight: "20px",

      "&>*": {
        marginRight: 4,
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  }),
  options
);

export default useStyles;
