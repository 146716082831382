import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Card } from "../../../components/card";
import { Space } from "../../../components/space";
import { PasswordField } from "../../../components/fields";
import React, { useState } from "react";
import { useCreatePassword } from "../../../core/hooks/useCreatePassword";
import { useParams } from "react-router-dom";

export const CreateNewPasswordContainer = () => {
  const { code } = useParams();
  const { handleSubmit, getFieldProps, isLoading } = useCreatePassword({
    code,
    onSuccess: () => {
      console.log("onSuccess");
    },
    onFail: () => {
      console.log("onFail");
    },
  });

  return (
    <Box sx={{ minWidth: 380, maxWidth: 380 }}>
      <Typography variant="h1" textAlign="center" mb={3}>
        Create new password
      </Typography>
      <Card sx={{ p: 1, boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)" }}>
        <Space spacing={2} direction="column">
          <Space spacing={1} direction="column">
            <PasswordField
              label="New password"
              {...getFieldProps("password")}
            />
            <PasswordField
              label="Confirm new password"
              {...getFieldProps("passwordConfirmation")}
            />
          </Space>
        </Space>
        <Box marginTop={2}>
          <Button
            disabled={isLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </Box>
      </Card>
    </Box>
  );
};
