import { PageHeader } from "../components/page-header";
import { Card } from "../components/card";
import { withBaseLayout } from "../components/layouts/withBaseLayout";
import { Stack } from "@mui/material";
import { LinkButton } from "../components/buttons";
import {
  ROUTE_CLIENT_CREATE,
  ROUTE_CLIENT_DETAIL,
  ROUTE_CLIENTS,
  ROUTE_CREATE_NEW_PASSWORD,
  ROUTE_EMPLOYEES,
  ROUTE_EMPLOYEES_DETAIL,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_GROUP_DETAIL,
  ROUTE_GROUPS,
  ROUTE_INVITE,
  ROUTE_MATCHING,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
} from "../core/constants/route.constants";
import { url } from "../core/utils/route.utils";

export const DemoLinks = withBaseLayout(() => {
  return (
    <>
      <PageHeader title="Demo links" />
      <Card shadow title="Auth" sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <LinkButton to={ROUTE_SIGN_IN}>Login</LinkButton>
          <LinkButton to={ROUTE_FORGOT_PASSWORD}>Password recovery</LinkButton>
          <LinkButton to={ROUTE_CREATE_NEW_PASSWORD.replace(":code", "12345")}>
            Create new password
          </LinkButton>
          <LinkButton to={ROUTE_INVITE.replace(":code", "12345")}>
            Invite
          </LinkButton>
          <LinkButton to={ROUTE_SIGN_UP}>Sign up</LinkButton>
          <LinkButton to={ROUTE_SIGN_UP + `?success=1`}>
            Sign up (success)
          </LinkButton>
        </Stack>
      </Card>
      <Card shadow title="Matching" sx={{ mb: 2 }}>
        <LinkButton to={ROUTE_MATCHING}>Matching</LinkButton>
      </Card>
      <Card shadow title="Groups" sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <LinkButton to={ROUTE_GROUPS}>Groups</LinkButton>
          <LinkButton to={url(ROUTE_GROUP_DETAIL, { id: "1" })}>
            Group detail
          </LinkButton>
        </Stack>
      </Card>
      <Card shadow title="Clients" sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <LinkButton to={ROUTE_CLIENTS}>Clients</LinkButton>
          <LinkButton to={ROUTE_CLIENT_CREATE}>Add new client</LinkButton>
          <LinkButton to={url(ROUTE_CLIENT_DETAIL, { id: "1" })}>
            Client detail (New)
          </LinkButton>
          <LinkButton to={url(ROUTE_CLIENT_DETAIL, { id: "2" })}>
            Client detail (In progress)
          </LinkButton>
          <LinkButton to={url(ROUTE_CLIENT_DETAIL, { id: "3" })}>
            Client detail (On review)
          </LinkButton>
          <LinkButton to={url(ROUTE_CLIENT_DETAIL, { id: "4" })}>
            Client detail (Matched)
          </LinkButton>
        </Stack>
      </Card>
      <Card shadow title="Employees" sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <LinkButton to={ROUTE_EMPLOYEES}>Employees</LinkButton>
          <LinkButton to={url(ROUTE_EMPLOYEES_DETAIL, { id: "1" })}>
            Employees detail
          </LinkButton>
        </Stack>
      </Card>
      <Card shadow title="Others" sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <LinkButton to={"/404"}>404 Page</LinkButton>
        </Stack>
      </Card>
    </>
  );
});
