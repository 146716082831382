export const HistoryLabels = {
  CREATE_CLIENT: "Create client",
  REJECT_CLIENT: "Reject client",
  MATCH_CLIENT: "Match client",
  ARCHIVE_CLIENT: "Archive client",
  UPDATE_CLIENT: "Update client",

  CREATE_GROUP: "Create group",
  REJECT_GROUP: "Reject group",
  MATCH_GROUP: "Match group",
  ARCHIVE_GROUP: "Archive group",
  UPDATE_GROUP: "Update group",

  ADD_CLIENTS_TO_GROUP: "Add client to group",
  REMOVE_CLIENTS_FROM_GROUP: "Remove clients from group",
  CHANGE_CLIENT_STATUS: "Change client status",
  CHANGE_GROUP_STATUS: "Change group status",
};
