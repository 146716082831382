import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "template-component",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: 10,
    },
    button: {
      borderRadius: 8,
      color: theme.palette.common.grey,
      fontSize: 14,
      fontWeight: 400,
      "&:hover": {
        color: theme.palette.common.darkBlue,
        backgroundColor: "#fff",
        boxShadow: "0px 1px 3px rgba(30, 42, 75, 0.08)",
      },
    },
    buttonActive: {
      color: theme.palette.common.darkBlue,
      backgroundColor: "#fff",
      boxShadow: "0px 1px 3px rgba(30, 42, 75, 0.08)",
    },
  }),
  options
);

export default useStyles;
