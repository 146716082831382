import React, { ComponentType, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { TableView } from "../../../components/table-view";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { Tabs } from "../../../components/tabs";
import { PageHeader } from "../../../components/page-header";
import { Common } from "./Common";
import { Status } from "../../../core/enums/status.enum";
import { GroupPreview } from "../../groups/group-detail/GroupPreview";
import { searchGroupColumns } from "./resource";
import { useMatchingClients } from "../../../core/hooks/useMatchingClients";
import get from "lodash/get";
import { castArray } from "lodash";
import { matchingColumns } from "../../matching/resource";
import { useDialog } from "../../../core/hooks/useDialog";
import { GroupSelectionDialog } from "../../matching/GroupSelectionDialog";
import isEmpty from "lodash/isEmpty";
import { Card } from "../../../components/card";
import { EmptyState } from "../../../components/empty-state";
import { IconFolderClock } from "../../../components/icons";

const ByClient = ({ onClickName, clients, isLoading }) => {
  const [openGroupSelection, closeGroupSelection] =
    useDialog(GroupSelectionDialog);
  const [selected, setSelected] = useState([]);
  const items = get(clients, "items", []);

  const handleAddSelected = () => {
    openGroupSelection({
      clientIds: selected,
      groupValues: {},
      onSuccess: () => {
        closeGroupSelection();
      },
    });
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Button disabled={!selected.length} onClick={handleAddSelected}>
          Add selected clients to new group
        </Button>
      </Box>
      {isEmpty(items) ? (
        <Card color="secondary" variant="outlined">
          <EmptyState
            minHeight={200}
            icon={<IconFolderClock />}
            loading={isLoading}
            description="No results found"
          />
        </Card>
      ) : (
        <TableView
          striped
          rowSelection={{
            selectedRowKeys: [],
            onChange: setSelected,
          }}
          dataSource={items}
          columns={matchingColumns}
          renderProps={{
            onClickName,
          }}
        />
      )}
    </>
  );
};

const ByGroup = ({ onClickName, groups, isLoading }) => {
  const [selected, setSelected] = useState([]);

  const items = get(groups, "items", []);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Button disabled={!selected.length}>
          Add client to selected groups
        </Button>
      </Box>
      {isEmpty(items) ? (
        <Card color="secondary" variant="outlined">
          <EmptyState
            minHeight={200}
            icon={<IconFolderClock />}
            loading={isLoading}
            description="No results found"
          />
        </Card>
      ) : (
        <TableView
          striped
          rowSelection={{
            selectedRowKeys: [],
            onChange: setSelected,
          }}
          columns={searchGroupColumns}
          dataSource={items}
          renderProps={{
            onClickName,
          }}
        />
      )}
    </>
  );
};

interface Props extends DialogProps {
  client: any;
}

const enum Stages {
  CLIENT = "CLIENT",
  GROUP = "GROUP",
  DEFAULT = "DEFAULT",
}

export const SearchMatchDialog: ComponentType<Props> = ({
  client,
  popDialog,
}) => {
  const {
    clients,
    matchAllClients,
    isLoading: clientsIsLoading,
  } = useMatchingClients();
  const [stage, setStage] = React.useState<Stages>(Stages.DEFAULT);
  const handleClickClient = (record) => {
    setStage(Stages.CLIENT);
  };

  const handleClickGroup = (record) => {
    setStage(Stages.GROUP);
  };

  const handleClickBack = () => {
    setStage(Stages.DEFAULT);
  };

  const searchClients = () => {
    const businessTypes = get(client, "businessTypes", []).map((x) =>
      Array.isArray(x) ? x[0] : x
    );
    const location = get(client, "location");
    const areaPermises = get(client, "areaOfPremisses");
    const budget = get(client, "budget");
    const portraing = get(client, "portray");
    const pricing = get(client, "pricing");

    matchAllClients({
      businessTypes,
      location: location ? castArray(location) : [],
      areaPermises: areaPermises ? castArray(areaPermises) : [],
      budget: budget ? castArray(budget) : [],
      portraing: portraing ? castArray(portraing) : [],
      pricing: pricing ? castArray(pricing) : [],
    });
  };

  useEffect(() => {
    if (client) {
      searchClients();
    }
  }, [client]);

  const renderStages = () => {
    if (stage === Stages.CLIENT) {
      return (
        <>
          <DialogTitle>
            <PageHeader
              title="Biford Inc."
              onClickBack={handleClickBack}
              backTitle="Back"
            >
              <Button>Select client</Button>
            </PageHeader>
          </DialogTitle>
          <DialogContent>
            <Common
              client={{
                status: { label: "In Progress", value: Status.IN_PROGRESS },
              }}
            />
          </DialogContent>
        </>
      );
    } else if (stage === Stages.GROUP) {
      return (
        <>
          <DialogTitle>
            <PageHeader
              title="Group name"
              onClickBack={handleClickBack}
              backTitle="Back"
            >
              <Button>Add client to this group</Button>
            </PageHeader>
          </DialogTitle>
          <DialogContent>
            <GroupPreview />
          </DialogContent>
        </>
      );
    }

    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Search match</Box>
            <Box>
              <Button color="third" onClick={popDialog}>
                Close
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Divider sx={{ mb: 2 }} />
          <Tabs
            items={[
              {
                key: "byClient",
                label: "By clients",
                content: (
                  <ByClient
                    onClickName={handleClickClient}
                    clients={clients}
                    isLoading={clientsIsLoading}
                  />
                ),
              },
              {
                key: "byGroups",
                label: "By groups",
                content: (
                  <ByGroup
                    onClickName={handleClickGroup}
                    groups={[]}
                    isLoading={false}
                  />
                ),
              },
            ]}
          />
        </DialogContent>
      </>
    );
  };

  return (
    <Dialog open onClose={popDialog} maxWidth="lg">
      {renderStages()}
    </Dialog>
  );
};
