import { ComponentType, ReactNode } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card } from "./Card";
import { IconFailure, IconSuccess } from "../icons";

interface Props extends Omit<BoxProps, "title"> {
  success: boolean;
  title: ReactNode;
  subtitle?: string;
  action?: ReactNode;
  footer?: ReactNode;
}

export const AuthStatusCard: ComponentType<Props> = ({
  success,
  title,
  subtitle,
  action,
  footer,
  ...props
}) => {
  return (
    <Box {...props}>
      <Card
        sx={{
          p: 1,
          boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
          textAlign: "center",
        }}
      >
        {success ? (
          <IconSuccess sx={{ fontSize: 48, mb: 2 }} />
        ) : (
          <IconFailure sx={{ fontSize: 48, mb: 2 }} />
        )}

        <Typography variant="h3" marginBottom={2}>
          {title}
        </Typography>

        {subtitle && (
          <Typography variant="body2" marginBottom={2}>
            {subtitle}
          </Typography>
        )}

        {action}
      </Card>
      {footer}
    </Box>
  );
};
