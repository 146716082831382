import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "snackbar",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: "fixed",
      top: 24,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1400,

      "& .MuiPaper-root": {
        borderRadius: 8,
        boxShadow: "0px 16px 24px rgba(33, 21, 65, 0.16)",
        padding: theme.spacing(1.125, 2),
      },

      "& .MuiAlert-message": {
        fontSize: 14,
        color: theme.palette.primary.main,
      },

      "& .MuiAlert-standardInfo": {
        backgroundColor: "#fff",
      },

      "& .MuiAlert-standardError": {
        backgroundColor: "#FFEAEA",

        "& .MuiSvgIcon-root": {
          color: "#FE8E8E",
        },
      },

      "& .MuiAlert-standardSuccess": {
        backgroundColor: "#EAFFF5",

        "& .MuiSvgIcon-root": {
          color: "#6AE3A9",
        },
      },

      "& .MuiAlert-standardWarning": {
        backgroundColor: "#fceed0",

        "& .MuiSvgIcon-root": {
          color: "#fdd77b",
        },
      },
    },
  }),
  options
);

export default useStyles;
