import { reduce } from "lodash";

export const normalizeSort = (state: Record<string, "desc" | "asc">) => {
  return reduce(
    state,
    (acc, direction, key) => {
      if (direction == "asc") {
        return [...acc, `-${key}`];
      } else if (direction == "desc") {
        return [...acc, key];
      }
      return acc;
    },
    []
  );
};
