import React, { ComponentType, ReactNode } from "react";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "./TabPanel";

interface Props {
  items: {
    key: string;
    label: string;
    content: ReactNode;
  }[];
}

export const Tabs: ComponentType<Props> = ({ items }) => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs value={value} onChange={handleChange}>
          {items.map((item, index) => (
            <MuiTab key={index} label={item.label} />
          ))}
        </MuiTabs>
      </Box>
      {items.map((item, index) => (
        <TabPanel key={index} value={value} index={index}>
          {item.content}
        </TabPanel>
      ))}
    </>
  );
};
