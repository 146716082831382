import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { LoginDto } from "../../dto/Login.dto";
import {
  API_CHANGE_PASSWORD,
  API_INVITE_INFO,
  API_LOGIN,
  API_LOGIN_SOCIAL,
  API_RESET_PASSWORD,
  API_SIGN_UP,
} from "../endpoint.constants";
import { InviteInfoDto } from "../../dto/InviteInfoDto";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    login: builder.mutation<LoginDto, { email: string; password: string }>({
      query: (body) => ({
        url: API_LOGIN,
        method: "POST",
        body,
      }),
    }),
    signUp: builder.mutation<
      { token: string },
      { inviteCode: string; password: string }
    >({
      query: (body) => ({
        url: API_SIGN_UP,
        method: "POST",
        body,
      }),
    }),
    loginSocial: builder.mutation<
      LoginDto,
      { token: string; inviteCode: string }
    >({
      query: (body) => ({
        url: API_LOGIN_SOCIAL,
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<LoginDto, { email: string }>({
      query: (body) => ({
        url: API_RESET_PASSWORD,
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation<
      LoginDto,
      { code: string; password: string }
    >({
      query: (body) => ({
        url: API_CHANGE_PASSWORD,
        method: "POST",
        body,
      }),
    }),
    inviteInfo: builder.mutation<InviteInfoDto, { inviteCode: string }>({
      query: ({ inviteCode }) => ({
        url: API_INVITE_INFO,
        method: "GET",
        params: { inviteCode },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useLoginSocialMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useInviteInfoMutation,
} = authApi;
