import React, { ComponentType } from "react";
import { BlankLayout } from "./blank-layout";

export const withBlankLayout =
  (ComposedComponent: ComponentType) => (props: any) => {
    return (
      <BlankLayout>
        <ComposedComponent {...props} />
      </BlankLayout>
    );
  };
