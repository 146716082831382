import React, { ComponentType, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DialogProps } from "../../core/providers/dialog.provider";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { LinkButton } from "../../components/buttons";
import { url } from "../../core/utils/route.utils";
import { ROUTE_GROUP_DETAIL } from "../../core/constants";
import { useFormik } from "formik";
import * as yup from "yup";
import get from "lodash/get";
import { MatchingDisplayFilters } from "../../components/filters/MatchingDisplayFilters";
import {
  useAddClientsToGroupMutation,
  useCreateGroupMutation,
  useFetchOneGroupMutation,
} from "../../core/store";
import { matchingFormToGroupValues } from "../../core/utils/hubspot.util";
import { useNavigate } from "react-router-dom";

interface Props extends DialogProps {
  clientIds: number[];
  groupValues: any;
  onSuccess?: () => void;
}

export const GroupSelectionDialog: ComponentType<Props> = ({
  popDialog,
  groupValues,
  clientIds,
  onSuccess,
}) => {
  const [addClients] = useAddClientsToGroupMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [createOne] = useCreateGroupMutation();
  const [getOneGroup] = useFetchOneGroupMutation();
  const navigate = useNavigate();

  const {
    values,
    setFieldValue,
    errors,
    touched,
    setErrors,
    getFieldProps: formikGetFieldProps,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      comments: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      const normalizedValues = matchingFormToGroupValues({
        ...values,
        ...groupValues,
      });
      setIsLoading(true);
      createOne(normalizedValues)
        .unwrap()
        .then((res) => {
          const listId = get(res, "listId");
          let breakTry = false;
          let tryCount = 0;

          const tryAddClients = () => {
            tryCount++;

            addClients({
              listId,
              contactVids: clientIds,
            })
              .unwrap()
              .then(() => {
                popDialog();
                showMessage(successGroupCreatedMessage(values.name, listId));
                navigate(url(ROUTE_GROUP_DETAIL, { id: listId }));
                onSuccess && onSuccess();
                setIsLoading(false);
              })
              .catch((err) => {
                if (!breakTry || tryCount > 15) {
                  setTimeout(() => {
                    tryAddClients();
                  }, 3000);
                }
              });
          };

          tryAddClients();
        })
        .catch((err) => {
          setIsLoading(false);
          const message = get(err, "data.errorDescription.message", "");
          if (message.includes("LIST_EXISTS")) {
            setErrors({ name: "Group name already exists" });
          } else {
            showMessage("Error creating group", "error");
          }
        });
    },
  });
  const [cancelConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();

  const getFieldProps = (fieldName) => {
    const error = get(errors, fieldName) && get(touched, fieldName);

    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      value: get(values, fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  const successGroupCreatedMessage = (name, id) => (
    <>
      New group{" "}
      <LinkButton to={url(ROUTE_GROUP_DETAIL, { id })}>{name}</LinkButton> was
      created successfully
    </>
  );

  const onCancel = () => {
    cancelConfirm({
      title: "Are you sure you want to cancel?",
      onConfirm: () => {
        popDialog();
      },
    });
  };

  return (
    <Dialog open onClose={popDialog} maxWidth="md">
      <DialogTitle>Add selected clients in new group</DialogTitle>
      <DialogContent>
        <MatchingDisplayFilters sx={{ mb: 2 }} values={groupValues} />
        <Stack spacing={1.5}>
          <TextField label="Group name" {...getFieldProps("name")} />
          <TextField
            label="Description"
            multiline
            rows={5}
            placeholder="At least 60 symbols"
            {...getFieldProps("comments")}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
        <Button color="third" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
