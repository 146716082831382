import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { GroupDto } from "../../dto";
import { IDPayload } from "../../constants/types";

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    fetchAllGroup: builder.mutation<GroupDto[], Record<string, any>>({
      query: (body) => ({
        url: "/api/group/search",
        method: "POST",
        body,
      }),
    }),
    fetchOneGroup: builder.mutation<GroupDto[], IDPayload>({
      query: ({ id, ...params }) => ({
        url: `/api/group/${id}`,
        method: "GET",
        params,
      }),
    }),

    fetchAllGroupClients: builder.mutation<GroupDto[], IDPayload>({
      query: ({ id, ...params }) => ({
        url: `/api/hs/contacts/v1/lists/${id}/contacts/all`,
        method: "GET",
        params,
      }),
    }),

    createGroup: builder.mutation<GroupDto, Record<string, any>>({
      query: (body) => ({
        url: `/api/group`,
        method: "POST",
        body,
      }),
    }),
    updateGroup: builder.mutation<GroupDto, any>({
      query: (body) => ({
        url: `/api/group/${body.listId}`,
        method: "POST",
        body,
      }),
    }),
    archiveGroup: builder.mutation<GroupDto, IDPayload>({
      query: ({ id, ...body }) => ({
        url: `/api/group/${id}/archive`,
        method: "POST",
        body,
      }),
    }),
    addClientsToGroup: builder.mutation<
      GroupDto,
      { listId: number | string; contactVids: number[] }
    >({
      query: (body) => ({
        url: `/api/group/${body.listId}/addClients`,
        method: "POST",
        body,
      }),
    }),
    removeClientsFromGroup: builder.mutation<
      GroupDto,
      { listId: number | string; contactVids: number[] }
    >({
      query: (body) => ({
        url: `/api/group/${body.listId}/removeClients`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useFetchAllGroupMutation,
  useFetchOneGroupMutation,
  useFetchAllGroupClientsMutation,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useArchiveGroupMutation,
  useAddClientsToGroupMutation,
  useRemoveClientsFromGroupMutation,
} = groupApi;
