import { createTheme } from "@mui/material/styles";
import { lightPalette } from "./palettes";
import { typography } from "./typography";
import { shape } from "./shape";
import { shadows } from "./shadows";
import { breakpoints } from "./breakpoints";
import { themeComponents } from "./components";

export const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  shape,
  shadows,
  breakpoints,
  components: themeComponents(lightPalette),
});
