import { Expose, Type } from "class-transformer";
import { CompanyDto } from "./CompanyDto";

export class MatchingDto {
  @Expose()
  id: number;

  @Expose()
  name: string;

  @Expose()
  @Type(() => CompanyDto)
  company: CompanyDto;

  @Expose()
  businessType: number[];

  @Expose()
  location: number;

  @Expose()
  area: number;

  @Expose()
  budget: number;

  @Expose()
  portraying: number;

  @Expose()
  pricing: number;

  @Expose()
  status: number;
}
