import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { LoginDto } from "../../dto/Login.dto";
import { API_LOGIN } from "../endpoint.constants";

export const hubspotApi = createApi({
  reducerPath: "hubspotApi",
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    fetchClientProperties: builder.query<any, any>({
      query: ({ id, params }) => ({
        url: `/api/hs/properties/v1/contacts/properties`,
        params,
      }),
    }),
  }),
});

export const { useFetchClientPropertiesQuery } = hubspotApi;
