import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "base-layout",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: "100vh",
      display: "flex",
      backgroundColor: theme.palette.background.default,
    },
    main: {
      padding: theme.spacing(4, 3.75),
      flexGrow: 1,
      background:
        "radial-gradient(42.69% 68.12% at 67.01% 42.78%, rgba(255, 195, 140, 0.5) 0%, rgba(255, 178, 87, 0.095) 100%), linear-gradient(119.53deg, #FFA8A8 7.03%, rgba(255, 255, 255, 0) 100.57%), #FFFFFF",
    },
    header: {},
  }),
  options
);

export default useStyles;
