import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "blank-layout",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      minHeight: "100vh",
      overflow: "hidden",
      paddingBottom: 64,
      background:
        "radial-gradient(42.69% 68.12% at 67.01% 42.78%, rgba(255, 195, 140, 0.5) 0%, rgba(255, 178, 87, 0.095) 100%), linear-gradient(143.11deg, #FF9DC0 3.84%, rgba(255, 255, 255, 0) 78.56%), #FFFFFF",
    },
    header: {
      minHeight: 168,
      display: "flex",
      alignItems: "center",
    },
  }),
  options
);

export default useStyles;
