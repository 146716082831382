export enum ClientProperties {
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
  CONTACT_LIST_STATUSES = "contact_list_statuses_2",
  EMAIL_ADDRESS = "email",
  PHONE_NUMBER = "phone",
  WEBSITE = "website",
  COMPANY = "company",
  BUSINESS_TYPE = "business_type",
  BUSINESS_TYPE2 = "business_type_2",
  BUSINESS_TYPE3 = "business_type_3",
  STATE = "state",
  CITY = "city",
  AREA = "client_area__sq_ft_",
  BUDGET = "budget",
  PORTRAY = "portray",
  PRICING = "pricing",
  AGE_GROUP = "client_age_group",
  AGE = "age",
  AGE2 = "age_2",
  GENDER = "gender",
  NUMBER_EMPLOYEES = "numemployees",
  RETAIL_SPOTS = "retail_spots",
  SOCIALS = "socials",
  STATUS = "client_status",
  COMMENT = "description",
}
