import { Expose, Transform } from "class-transformer";

export enum Roles {
  ADMIN = "ADMIN",
  SALES = "SALES",
}

export class UserDto {
  @Expose()
  avatar: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  email: string;

  @Expose()
  @Transform(({ obj }) =>
    [obj.firstName, obj.lastName].filter(Boolean).join(" ")
  )
  fullName: string;

  @Expose()
  role: Roles;

  @Expose()
  @Transform(({ obj }) => obj.role === Roles.ADMIN)
  isAdmin: boolean;
}
