import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import { rtkQueryErrorLogger } from "./rtk.utils";
import {
  authApi,
  clientApi,
  employeeApi,
  groupApi,
  hubspotApi,
  userApi,
} from "./api";

export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        rtkQueryErrorLogger,
        clientApi.middleware,
        authApi.middleware,
        userApi.middleware,
        hubspotApi.middleware,
        employeeApi.middleware,
        groupApi.middleware,
      ]),
  });
};

export const store = makeStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
// export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
