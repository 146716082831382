import React, { ComponentType } from "react";
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { map } from "lodash";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import {
  ROUTE_CLIENTS,
  ROUTE_EMPLOYEES,
  ROUTE_GROUPS,
  ROUTE_HOME,
  ROUTE_MATCHING,
  ROUTE_PROFILE,
  ROUTE_SIGN_IN,
} from "../../../core/constants";
import {
  IconClients,
  IconGroups,
  IconLogo,
  IconLogout,
  IconMatching,
} from "../../icons";
import useStyles from "./styles";
import { startsWith } from "lodash";
import { useDialog } from "../../../core/hooks/useDialog";
import { ConfirmDialog } from "../../dialogs/ConfirmDialog";
import { AUTH_TOKEN } from "../../../core/constants";
import { useMeQuery } from "../../../core/store";

interface SidebarProps {
  width: number;
  mobileOpen: boolean;
  setMobileOpen: (state: boolean) => void;
}

const NAV_ITEMS = [
  { label: "Matching", path: ROUTE_MATCHING, icon: <IconMatching /> },
  { label: "Groups", path: ROUTE_GROUPS, icon: <IconGroups /> },
  { label: "Clients", path: ROUTE_CLIENTS, icon: <IconClients /> },
  {
    label: "Employees",
    path: ROUTE_EMPLOYEES,
    icon: <IconClients />,
    sx: { mt: 3 },
  },
];

export const Sidebar: ComponentType<SidebarProps> = ({
  width,
  mobileOpen,
  setMobileOpen,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openConfirmDialog, closeConfirmDialog] = useDialog(ConfirmDialog);
  const { data: user } = useMeQuery({});

  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    openConfirmDialog({
      title: "Are you sure you want to logout?",
      onConfirm: () => {
        closeConfirmDialog();
        localStorage.removeItem(AUTH_TOKEN);
        navigate(ROUTE_SIGN_IN);
      },
    });
  };

  const isSelected = (path: string) => {
    return startsWith(pathname, path);
  };

  const ListItemProps = (path: string): any => {
    return {
      button: true,
      selected: isSelected(path),
      classes: { root: classes.navItem, selected: classes.navItemSelected },
      component: NavLink,
      to: path,
    };
  };

  const drawer = (
    <>
      <Toolbar className={classes.toolbar}>
        <Link to={ROUTE_HOME}>
          <IconLogo sx={{ width: 180, height: 32, display: "block" }} />
        </Link>
      </Toolbar>
      <List className={classes.nav} disablePadding component="div">
        {map(NAV_ITEMS, (item, index) => (
          <ListItem
            {...ListItemProps(item.path)}
            key={index}
            sx={item.sx || {}}
          >
            <Box className={classes.navItemIcon}>{item.icon}</Box>
            <Typography
              variant="button"
              className={classes.navItemLabel}
              component="span"
            >
              {item.label}
            </Typography>
          </ListItem>
        ))}
        <ListItem {...ListItemProps(ROUTE_PROFILE)}>
          <Box className={classes.navItemIcon}>
            <Avatar
              alt={user.fullName}
              sx={{
                backgroundColor: "secondary.light",
                width: 24,
                height: 24,
              }}
            />
          </Box>
          <Typography
            variant="button"
            className={classes.navItemLabel}
            component="span"
          >
            {user.fullName}
          </Typography>
        </ListItem>
      </List>

      <ListItem
        button
        classes={{ root: classes.navItem, selected: classes.navItemSelected }}
        sx={{
          mt: "auto",
          mb: 5,
        }}
        onClick={handleLogout}
      >
        <Box className={classes.navItemIcon}>
          <IconLogout />
        </Box>
        <Typography
          variant="button"
          className={classes.navItemLabel}
          component="span"
        >
          Log out
        </Typography>
      </ListItem>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className={classes.root}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: width },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: width },
        }}
        open
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
