import { Editable } from "../../../components/editable";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Accordion } from "../../../components/accordion/Accordion";
import { DisplayFilters } from "../../../components/filters/DisplayFilters";
import { TableView } from "../../../components/table-view";
import { matchingColumns } from "../../matching/resource";
import React, { ComponentType } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { EmptyState } from "../../../components/empty-state";
import { IconFolder } from "../../../components/icons";
import { Card } from "../../../components/card";
import { useUpdateGroupMutation } from "../../../core/store";
import { useSnackbar } from "../../../core/hooks/useSnackbar";

interface Props {
  group?: any;
  onRemoveSelected?: (ids: number[]) => void;
}

export const GroupPreview: ComponentType<Props> = ({
  group,
  onRemoveSelected,
}) => {
  const [updateOne] = useUpdateGroupMutation();
  const [selected, setSelected] = React.useState<number[]>([]);
  const contacts = get(group, "contacts.items", []);
  const [comment, setComment] = React.useState(get(group, "comments", ""));
  const { showMessage } = useSnackbar();

  // useEffect(() => {
  //   setComment(get(group, "comments", ""));
  // }, [group]);

  const handleSubmitComment = () => {
    updateOne({
      name: get(group, "name", ""),
      city: get(group, "city", ""),
      comments: comment,
      filters: get(group, "groupFilters", []),
      listId: get(group, "listId", ""),
    })
      .unwrap()
      .catch((err) => {
        showMessage("Error updating group", "error");
      });
  };

  return (
    <>
      <Editable
        sx={{ mb: 2 }}
        actionText="Edit comment"
        editableView={
          <TextField
            multiline
            defaultValue={get(group, "comments")}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        }
        onSubmit={handleSubmitComment}
      >
        <Typography variant="body2">{comment}</Typography>
      </Editable>

      <Box sx={{ mb: 2 }}>
        <Accordion title="Filters">
          <DisplayFilters group={group} />
        </Accordion>
      </Box>

      {isEmpty(contacts) ? (
        <Card
          sx={{
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color="secondary"
          variant="outlined"
        >
          <EmptyState
            icon={<IconFolder />}
            description="You didn’t add client to this group"
          />
        </Card>
      ) : (
        <>
          {onRemoveSelected && (
            <Box sx={{ mb: 2 }}>
              <Button onClick={() => onRemoveSelected(selected)}>
                Remove selected clients from group
              </Button>
            </Box>
          )}
          <TableView
            striped
            rowSelection={{
              selectedRowKeys: [],
              onChange: setSelected,
            }}
            dataSource={contacts}
            columns={matchingColumns}
          />
        </>
      )}
    </>
  );
};
