import { useMeQuery } from "../store";
import { LoadingLayout } from "../../components/layouts/loading-layout";

export const withAuthorized = (ComposedComponent) => (props) => {
  const { data, isLoading } = useMeQuery({});

  if (isLoading) {
    return <LoadingLayout />;
  }

  if (!data) {
    return null;
  }

  return <ComposedComponent {...props} />;
};
