import {
  Button,
  Pagination as MuiPagination,
  PaginationItem,
  PaginationProps,
} from "@mui/material";
import React, { ComponentType } from "react";

export const Pagination: ComponentType<PaginationProps> = (props) => {
  return (
    <MuiPagination
      {...props}
      hidePrevButton
      renderItem={(item) => {
        if (item.type === "next") {
          return (
            <Button
              color="third"
              size="small"
              disabled={item.disabled}
              onClick={item.onClick}
              sx={{ color: "#585F84" }}
            >
              Next
            </Button>
          );
        }
        return <PaginationItem {...item} />;
      }}
    />
  );
};
