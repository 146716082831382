import { Card } from "../../components/card";
import { EmptyState } from "../../components/empty-state";
import { IconFolderClock } from "../../components/icons";
import { TableView } from "../../components/table-view";
import { historyColumns } from "../clients/client-detail/resource";
import React, { useEffect } from "react";
import { useHistoryEmployeeMutation } from "../../core/store";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const EmployeeHistory = ({ id }) => {
  const [getHistoryData, { data, isLoading }] = useHistoryEmployeeMutation();

  useEffect(() => {
    getHistoryData({ id });
  }, [id]);

  const items = get(data, "items", []);

  return (
    <>
      {isEmpty(items) ? (
        <Card color="secondary" variant="outlined">
          <EmptyState
            minHeight={200}
            icon={<IconFolderClock />}
            loading={isLoading}
            description="Employee doesn't have historical records yet."
          />
        </Card>
      ) : (
        <Card
          title="History"
          color="secondary"
          variant="outlined"
          size="medium"
          sx={{ p: 1 }}
        >
          <TableView striped columns={historyColumns} dataSource={items} />
        </Card>
      )}
    </>
  );
};
