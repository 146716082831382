export const ROUTE_HOME = "/";
export const ROUTE_UI = "/ui";

export const ROUTE_SIGN_IN = "/auth/sign-in";
export const ROUTE_SIGN_UP = "/auth/sign-up";
export const ROUTE_FORGOT_PASSWORD = "/auth/forgot";
export const ROUTE_CREATE_NEW_PASSWORD = "/auth/password/:code";
export const ROUTE_INVITE = "/auth/invite/:code";

export const ROUTE_PROFILE = "/profile";

export const ROUTE_MATCHING = "/matching";

export const ROUTE_GROUPS = "/groups";
export const ROUTE_GROUP_DETAIL = "/groups/:id";

export const ROUTE_CLIENTS = "/clients";
export const ROUTE_CLIENT_DETAIL = "/clients/view/:id";
export const ROUTE_CLIENT_CREATE = "/clients/create";

export const ROUTE_EMPLOYEES = "/employees";
export const ROUTE_EMPLOYEES_CREATE = "/employees/create";
export const ROUTE_EMPLOYEES_EDIT = "/employees/edit/:id";
export const ROUTE_EMPLOYEES_DETAIL = "/employees/view/:id";
