import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "loading",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    backdrop: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(28, 27, 46, 0.4)",
      zIndex: theme.zIndex.drawer + 1,
      inset: 0,
      position: "absolute",
    },
    message: {
      background: "#211541",
      color: "#fff",
      padding: theme.spacing(2, 4),
      borderRadius: "8px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",

      "& svg": {
        color: "#fff",
        fontSize: 32,
      },
    },
  }),
  options
);

export default useStyles;
