import { ComponentType, ReactNode } from "react";
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import useStyles from "./styles";
import { IconArrowDown, IconArrowUp } from "../icons";
import clsx from "clsx";

interface Props {
  amount: number;
  prevAmount?: number;
  title: string;
  icon?: ReactNode;
  color?: "success" | "warning" | "info" | "error" | "light";
}

export const Record: ComponentType<Props> = ({
  amount,
  prevAmount,
  icon,
  color = "default",
  title,
}) => {
  const classes = useStyles();
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const changePercent = prevAmount ? (amount - prevAmount) / prevAmount : 0;
  const percent = Math.abs(Math.round(changePercent * 100));
  const isPositive = changePercent > 0;

  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: `background.${color}` }}
    >
      <Stack direction="row" alignItems="center">
        {icon && hidden && (
          <Box
            className={classes.icon}
            sx={{
              color: `common.${color}`,
            }}
          >
            {icon}
          </Box>
        )}

        <Box>
          <Typography variant="h1" component="div">
            {amount.toFixed(0).replace(/\d(?=(\d{3}))/g, "$&,")}
          </Typography>
          {
            <Stack
              direction="row"
              alignItems="center"
              sx={{ mb: 0.25 }}
              className={clsx({
                [classes.up]: isPositive,
                [classes.down]: !isPositive,
              })}
            >
              {isPositive ? <IconArrowUp /> : <IconArrowDown />}
              <Typography variant="body1" fontWeight={500}>
                {percent}%
              </Typography>
            </Stack>
          }

          <Typography variant="body2" component="div">
            {title}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
