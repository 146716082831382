import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Space } from "../../../components/space";
import { PasswordField, SelectField } from "../../../components/fields";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AuthStatusCard } from "../../../components/card/AuthStatusCard";
import { Link, useSearchParams } from "react-router-dom";
import {
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
} from "../../../core/constants/route.constants";
import { LinkButton } from "../../../components/buttons";
import Alert from "@mui/material/Alert";
import { AuthCard } from "../../../components/card/AuthCard";

export const SignUpContainer = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const successParam = searchParams.get("success");
    if (successParam !== null) {
      setSuccess(successParam === "1");
    }
  }, [searchParams.get("success")]);

  const handleSubmit = () => {
    setError(!error);
  };

  if (success !== null) {
    return (
      <AuthStatusCard
        sx={{ minWidth: 380, maxWidth: 380 }}
        success={success}
        title={
          success ? (
            <>
              Welcome Sharefront
              <br />
              Matching System
            </>
          ) : (
            <>
              Opps,
              <br />
              Something wrong!
              <br />
              Profile wasn’t created.
            </>
          )
        }
        subtitle={
          success ? "You successfully created an account." : "Try again later."
        }
        action={
          success ? (
            <Button>Get started</Button>
          ) : (
            <Button component={Link} to={ROUTE_SIGN_UP}>
              Sign up
            </Button>
          )
        }
        footer={
          success ? null : (
            <Typography
              variant="body2"
              color="common.grey"
              textAlign="center"
              marginTop={2}
            >
              I have an account. {""}
              <LinkButton to={ROUTE_SIGN_IN} color="common.grey">
                Login
              </LinkButton>
            </Typography>
          )
        }
      />
    );
  }

  return (
    <Box sx={{ minWidth: 380, maxWidth: 380 }}>
      <Typography variant="h1" textAlign="center" mb={3}>
        Sign Up
      </Typography>
      <AuthCard>
        {error && (
          <Alert variant="filled" severity="error" icon={false} sx={{ mb: 2 }}>
            Something went wrong. Please try again or contact your administrator
          </Alert>
        )}
        <Space spacing={1} direction="column">
          <TextField
            label="Email"
            error={error}
            helperText={error ? "Incorrect email address" : ""}
          />
          <SelectField
            label="Role"
            items={[{ label: "Manager", value: "manager" }]}
            onChange={() => {}}
          />
          <PasswordField
            label="Create password"
            error={error}
            helperText="Password must contain at least 8 characters, including at least one number, one lowercase and one uppercase"
          />
          <PasswordField
            label="Confirm password"
            error={error}
            helperText={error ? "Passwords don’t match" : ""}
          />
        </Space>
        <Box marginTop={2}>
          <Button onClick={handleSubmit}>Registration</Button>
        </Box>
      </AuthCard>
    </Box>
  );
};
