import { ComponentType, ReactNode } from "react";
import useStyles from "./styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BackButton } from "../buttons/back-button/BackButton";

interface Props {
  title: ReactNode;
  meta?: ReactNode;
  backTitle?: string;
  onClickBack?: () => void;
}

export const PageHeader: ComponentType<Props> = ({
  title,
  meta,
  backTitle,
  children,
  onClickBack,
}) => {
  const classes = useStyles();

  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack();
    } else {
      window.history.back();
    }
  };

  return (
    <Box className={classes.root}>
      {backTitle && (
        <BackButton onClick={handleClickBack}>{backTitle}</BackButton>
      )}
      <Stack direction="row" alignItems="center">
        <Typography variant="h1">{title}</Typography>
        {meta && (
          <Stack direction="row" spacing={1} className={classes.meta}>
            {meta}
          </Stack>
        )}
        <Stack direction="row" spacing={1} className={classes.action}>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};
