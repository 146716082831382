import React, { ComponentType } from "react";
import { Box } from "@mui/material";
import { IconLogoDark } from "../../icons";
import useStyles from "./styles";

export const AuthLayout: ComponentType = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box component="main" className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <IconLogoDark sx={{ width: 142, height: 40 }} />
          </Box>
          {children}
        </Box>
      </Box>
    </>
  );
};
