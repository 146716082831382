import { useContext } from "react";
import LoadingContext from "../contexts/loading.context";

interface UseLoadingHook {
  showLoading: (message: string) => void;
  hideLoading: () => void;
}
export const useLoading = (): UseLoadingHook => {
  return useContext(LoadingContext);
};
