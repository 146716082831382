import { Card } from "../../../components/card";
import React, { useEffect } from "react";
import { EmptyState } from "../../../components/empty-state";
import { IconFolderClock } from "../../../components/icons";
import { TableView } from "../../../components/table-view";
import { historyColumns } from "./resource";
import { useHistoryClientMutation } from "../../../core/store";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const History = ({ id }) => {
  const [getHistoryData, { data, isLoading }] = useHistoryClientMutation();

  useEffect(() => {
    getHistoryData({ id });
  }, [id]);

  const items = get(data, "items", []);

  return (
    <>
      {isEmpty(items) ? (
        <Card color="secondary" variant="outlined">
          <EmptyState
            minHeight={200}
            icon={<IconFolderClock />}
            loading={isLoading}
            description="Client doesn't have historical records yet."
          />
        </Card>
      ) : (
        <Card
          title="History"
          color="secondary"
          variant="outlined"
          size="medium"
          sx={{ p: 1 }}
        >
          <TableView striped columns={historyColumns} dataSource={items} />
        </Card>
      )}
    </>
  );
};
