import { IconArrowLeft } from "../../icons";
import Button, { ButtonProps } from "@mui/material/Button";
import React from "react";
import useStyles from "./styles";

export const BackButton = <C extends React.ElementType>({
  children,
  ...props
}: ButtonProps<C, { component?: C }>) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={classes.root}
      variant="text"
      startIcon={<IconArrowLeft width={12} sx={{ ml: "-3px" }} />}
    >
      {children}
    </Button>
  );
};
