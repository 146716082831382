import { Box } from "@mui/system";
import React, { ComponentType } from "react";
import useStyles from "./styles";
import { CircularProgress } from "../../circular-progress";
import { Stack, Typography } from "@mui/material";

export const LoadingLayout: ComponentType = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          spacing={2.5}
        >
          <CircularProgress />
          <Typography variant="body1" className={classes.loadingText}>
            Loading ...
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
