import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as SvgLogo } from "./assets/logo.svg";
import { ReactComponent as SvgClients } from "./assets/clients.svg";
import { ReactComponent as SvgGroups } from "./assets/groups.svg";
import { ReactComponent as SvgLogout } from "./assets/logout.svg";
import { ReactComponent as SvgMatching } from "./assets/matching.svg";
import { ReactComponent as SvgArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as SvgClose } from "./assets/close.svg";
import { ReactComponent as SvgSorting } from "./assets/sorting.svg";
import { ReactComponent as SvgFiltering } from "./assets/filtering.svg";
import { ReactComponent as SvgOpen } from "./assets/open.svg";
import { ReactComponent as SvgSearch } from "./assets/search.svg";
import { ReactComponent as SvgCheckbox } from "./assets/checkbox.svg";
import { ReactComponent as SvgCheckboxChecked } from "./assets/checkbox-checked.svg";
import { ReactComponent as SvgCheckboxIndeterminate } from "./assets/checkbox-indeterminate.svg";
import { ReactComponent as SvgFolder } from "./assets/folder.svg";
import { ReactComponent as SvgAccordionArrow } from "./assets/accordion-arrow.svg";
import { ReactComponent as SvgSnackbarSuccess } from "./assets/snackbar-success.svg";
import { ReactComponent as SvgFolderClock } from "./assets/folder-clock.svg";
import { ReactComponent as SvgArrowRight } from "./assets/arrow-right.svg";
import { ReactComponent as SvgCalendar } from "./assets/calendar.svg";
import { ReactComponent as SvgArrowUp } from "./assets/arrow-up.svg";
import { ReactComponent as SvgArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as SvgVisibility } from "./assets/visibility.svg";
import { ReactComponent as SvgVisibilityOff } from "./assets/visibility-off.svg";
import { ReactComponent as SvgLogoDark } from "./assets/logo-dark.svg";
import { ReactComponent as SvgGoogleLogoMin } from "./assets/google-logo-min.svg";
import { ReactComponent as SvgSuccess } from "./assets/success.svg";
import { ReactComponent as SvgFailure } from "./assets/failure.svg";
import { ReactComponent as SvgSelect } from "./assets/select.svg";
import { ReactComponent as SvgLoading } from "./assets/loading.svg";

export const IconLogo = (props: SvgIconProps) => (
  <SvgIcon component={SvgLogo} viewBox="0 0 180 32" {...props} />
);
export const IconLogoDark = (props: SvgIconProps) => (
  <SvgIcon component={SvgLogoDark} viewBox="0 0 142 40" {...props} />
);

export const IconClients = (props: SvgIconProps) => (
  <SvgIcon component={SvgClients} viewBox="0 0 24 24" {...props} />
);

export const IconGroups = (props: SvgIconProps) => (
  <SvgIcon component={SvgGroups} viewBox="0 0 24 24" {...props} />
);

export const IconLogout = (props: SvgIconProps) => (
  <SvgIcon component={SvgLogout} viewBox="0 0 24 24" {...props} />
);

export const IconMatching = (props: SvgIconProps) => (
  <SvgIcon component={SvgMatching} viewBox="0 0 24 24" {...props} />
);

export const IconArrowLeft = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowLeft} viewBox="0 0 8 14" {...props} />
);

export const IconClose = (props: SvgIconProps) => (
  <SvgIcon component={SvgClose} viewBox="0 0 16 16" {...props} />
);

export const IconSorting = (props: SvgIconProps) => (
  <SvgIcon component={SvgSorting} viewBox="0 0 20 20" {...props} />
);

export const IconFiltering = (props: SvgIconProps) => (
  <SvgIcon component={SvgFiltering} viewBox="0 0 18 16" {...props} />
);

export const IconOpen = (props: SvgIconProps) => (
  <SvgIcon component={SvgOpen} viewBox="0 0 12 12" {...props} />
);

export const IconSearch = (props: SvgIconProps) => (
  <SvgIcon component={SvgSearch} viewBox="0 0 18 18" {...props} />
);

export const IconCheckbox = (props: SvgIconProps) => (
  <SvgIcon component={SvgCheckbox} viewBox="0 0 20 20" {...props} />
);

export const IconCheckboxChecked = (props: SvgIconProps) => (
  <SvgIcon component={SvgCheckboxChecked} viewBox="0 0 20 20" {...props} />
);

export const IconCheckboxIndeterminate = (props: SvgIconProps) => (
  <SvgIcon
    component={SvgCheckboxIndeterminate}
    viewBox="0 0 20 20"
    {...props}
  />
);

export const IconFolder = (props: SvgIconProps) => (
  <SvgIcon component={SvgFolder} viewBox="0 0 32 32" {...props} />
);

export const IconAccordionArrow = (props: SvgIconProps) => (
  <SvgIcon component={SvgAccordionArrow} viewBox="0 0 20 20" {...props} />
);

export const IconSnackbarSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgSnackbarSuccess} viewBox="0 0 24 24" {...props} />
);

export const IconFolderClock = (props: SvgIconProps) => (
  <SvgIcon component={SvgFolderClock} viewBox="0 0 32 33" {...props} />
);

export const IconArrowRight = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowRight} viewBox="0 0 16 16" {...props} />
);

export const IconCalendar = (props: SvgIconProps) => (
  <SvgIcon component={SvgCalendar} viewBox="0 0 16 18" {...props} />
);

export const IconArrowUp = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowUp} viewBox="0 0 24 24" {...props} />
);

export const IconArrowDown = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowDown} viewBox="0 0 24 24" {...props} />
);

export const IconVisibility = (props: SvgIconProps) => (
  <SvgIcon component={SvgVisibility} viewBox="0 0 20 20" {...props} />
);

export const IconVisibilityOff = (props: SvgIconProps) => (
  <SvgIcon component={SvgVisibilityOff} viewBox="0 0 20 20" {...props} />
);

export const IconGoogleLogoMin = (props: SvgIconProps) => (
  <SvgIcon component={SvgGoogleLogoMin} viewBox="0 0 23 23" {...props} />
);

export const IconSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgSuccess} viewBox="0 0 48 48" {...props} />
);

export const IconFailure = (props: SvgIconProps) => (
  <SvgIcon component={SvgFailure} viewBox="0 0 48 48" {...props} />
);

export const IconSelect = (props: SvgIconProps) => (
  <SvgIcon component={SvgSelect} viewBox="0 0 20 20" {...props} />
);

export const IconLoading = (props: SvgIconProps) => (
  <SvgIcon component={SvgLoading} viewBox="0 0 32 32" {...props} />
);
