import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { PageHeader } from "../../../components/page-header";
import { Card } from "../../../components/card";
import { Tabs } from "../../../components/tabs";
import { Common } from "./Common";
import { History } from "./History";
import { useNavigate, useParams } from "react-router-dom";
import get from "lodash/get";
import { StatusChip } from "../../../components/chip/StatusChip";
import { Status } from "../../../core/enums/status.enum";
import { useDialog } from "../../../core/hooks/useDialog";
import { MarkAsMatchedDialog } from "./MarkAsMatchedDialog";
import { ConfirmDialog } from "../../../components/dialogs/ConfirmDialog";
import { SearchMatchDialog } from "./SearchMatchDialog";
import {
  useArchiveClientMutation,
  useFetchClientGroupsMutation,
  useFetchOneClientMutation,
  useRejectClientMutation,
} from "../../../core/store";
import { ROUTE_CLIENTS } from "../../../core/constants";
import isEmpty from "lodash/isEmpty";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { useLoading } from "../../../core/hooks/useLoading";

const STATUS_BY_ID = {
  1: { label: "New", value: Status.NEW },
  2: { label: "In Progress", value: Status.IN_PROGRESS },
  3: { label: "On Review", value: Status.ON_REVIEW },
  4: { label: "Matched", value: Status.MATCHED },
};

export const ClientDetailContainer = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [rejectClient] = useRejectClientMutation();
  const [archiveClient] = useArchiveClientMutation();
  const [getClientGroups, { data: groupsData }] =
    useFetchClientGroupsMutation();
  const [openMarkAsMatchedDialog] = useDialog(MarkAsMatchedDialog);
  const [openSearchMatchDialog] = useDialog(SearchMatchDialog);
  const [openConfirmDialog, closeConfirmDialog] = useDialog(ConfirmDialog);
  const { showLoading, hideLoading } = useLoading();
  const { id } = useParams();
  const [getClientData, { data: client, error, isLoading }] =
    useFetchOneClientMutation();

  useEffect(() => {
    if (isLoading) {
      showLoading("Loading client...");
    } else {
      hideLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    getClientData({
      id,
    });
  }, [id]);

  useEffect(() => {
    getClientGroups({
      id,
    });
  }, [id]);

  useEffect(() => {
    if (get(error, "data.errorCode") === "hubspot_bad_request") {
      showMessage(get(error, "data.errorMessage"), "error");
      return navigate(ROUTE_CLIENTS);
    }
  }, [error]);

  const handleReject = () => {
    openConfirmDialog({
      title: "Are you sure you want to mark as rejected?",
      onConfirm: () => {
        rejectClient({ id })
          .unwrap()
          .then(() => {
            closeConfirmDialog();
            navigate(ROUTE_CLIENTS);
            showMessage("Client has been rejected", "success");
          });
      },
    });
  };

  const handleArchive = () => {
    openConfirmDialog({
      title: "Are you sure you want to archive?",
      onConfirm: () => {
        archiveClient({ id })
          .unwrap()
          .then(() => {
            closeConfirmDialog();
            navigate(ROUTE_CLIENTS);
            showMessage("Client archived successfully");
          });
      },
    });
  };

  const handleMatched = () => {
    openConfirmDialog({
      title: "Are you sure you want to mark as matched?",
      onConfirm: () => {
        openMarkAsMatchedDialog();
      },
    });
  };

  const handleSearchMatch = () => {
    openSearchMatchDialog({
      client,
    });
  };

  const renderActions = () => {
    // if (isNew || isInProgress) {
    return <Button onClick={handleSearchMatch}>Search match by client</Button>;
    // } else if (isOnReview) {
    //   return (
    //     <>
    //       <Button>Search match</Button>
    //       <Divider
    //         orientation="vertical"
    //         flexItem
    //         sx={{ borderColor: "#C7C2FF" }}
    //       />
    //       <Button color="secondary" onClick={handleMatched}>
    //         Mark as MATCHED
    //       </Button>
    //       <Button color="error" onClick={handleReject}>
    //         Mark as REJECTED
    //       </Button>
    //
    //       <Divider
    //         orientation="vertical"
    //         flexItem
    //         sx={{ borderColor: "#C7C2FF" }}
    //       />
    //     </>
    //   );
    // }
  };

  if (isEmpty(client)) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={
          get(client, "companyName") || get(client, "contactPerson") || "N/A"
        }
        meta={
          get(client, "status") === "new" && (
            <StatusChip label="New" value="new" />
          )
        }
        backTitle="Clients"
      >
        {renderActions()}
        <Button color="secondary" variant="outlined" onClick={handleArchive}>
          Archive client
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <Tabs
          items={[
            {
              key: "common",
              label: "Common",
              content: <Common {...{ client, onEdit: () => {}, groupsData }} />,
            },
            {
              key: "history",
              label: "History",
              content: <History id={get(client, "id")} />,
            },
          ]}
        />
      </Card>
    </>
  );
};
