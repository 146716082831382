import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "empty-state",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    loading: {
      "& .MuiCircularProgress-svg": {
        color: theme.palette.common.grey,
      },
    },
    loadingText: {
      color: theme.palette.common.grey,
    },
    icon: {
      marginBottom: theme.spacing(2),
      "& svg": {
        display: "block",
        fontSize: 26,
        color: theme.palette.primary.main,
      },
    },
    action: {
      marginTop: theme.spacing(1.5),
    },
  }),
  options
);

export default useStyles;
