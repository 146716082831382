import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "card",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: 8,
    },
    content: {
      "&:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
    shadow: {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
    },
    secondary: {
      backgroundColor: theme.palette.common.lightGrey,
    },
    third: {
      backgroundColor: theme.palette.common.lightBlue,
    },
    secondaryOutlined: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.common.lightBlue,
    },
    title: {},
    titleMedium: {
      ...theme.typography.h4,
      marginBottom: theme.spacing(2),
    },
    titleSmall: {
      ...theme.typography.body2,
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
    contained: {},
  }),
  options
);

export default useStyles;
