import { ReactNode, useCallback, useContext } from "react";
import DialogContext from "../contexts/dialog.context";

export const useDialog = (Component: ReactNode) => {
  const { pushDialog, popDialog } = useContext(DialogContext);

  const openDialog = useCallback(
    (props: any = null) => {
      pushDialog([Component, props]);
    },
    [Component, pushDialog]
  );

  const closeDialog = useCallback(() => {
    popDialog();
  }, [popDialog]);

  return [openDialog, closeDialog];
};
