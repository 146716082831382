import Box from "@mui/material/Box";
import { LinkButton } from "../../components/buttons";
import { url } from "../../core/utils/route.utils";
import { ROUTE_CLIENT_DETAIL } from "../../core/constants";
import { format } from "date-fns";
import { ChipList } from "../../components/chip-list";
import { get } from "lodash";
import { budgetListByKey } from "../mock-data";

export const resource = {
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Company name",
      dataIndex: "companyName",
      key: "companyName",
      render: (value, record: any) => {
        return (
          <LinkButton to={url(ROUTE_CLIENT_DETAIL, { id: record.id })}>
            {value || get(record, "contactPerson") || "N/A"}
          </LinkButton>
        );
      },
    },

    {
      title: "Business type",
      dataIndex: "businessTypes",
      key: "businessTypes",
      sortable: false,
      render: (value) => <ChipList value={value} />,
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sortable: false,
      render: (value: any) => {
        return value && value !== ", " && <ChipList value={value} />;
      },
    },
    {
      title: "Area (sq ft)",
      dataIndex: "areaOfPremisses",
      key: "areaOfPremisses",
      sortable: false,
      render: (value) => <ChipList value={value} />,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      sortable: false,
      render: (value) => <ChipList value={value} />,
    },
    {
      title: "Portraying",
      dataIndex: "portray",
      key: "portray",
      sortable: false,
      render: (value) => <ChipList value={value} />,
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
      sortable: false,
      render: (value) => <ChipList value={value} />,
    },
    {
      title: "Request date",
      dataIndex: "requestDate",
      key: "requestDate",
      sortable: false,
      cellProps: {
        width: "11%",
      },
      render: (value: any) => {
        return (
          value && (
            <Box sx={{ whiteSpace: "nowrap" }}>
              {format(new Date(value), "dd/MM/yyyy hh:mm a")}
            </Box>
          )
        );
      },
    },
  ],
};
