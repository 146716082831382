import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "chip",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    mediumLightBlue: {
      backgroundColor: `${theme.palette.common.mediumLightBlue} !important`,
    },
    lightBlue: {
      backgroundColor: `${theme.palette.common.lightBlue} !important`,
    },
    yellow: {
      backgroundColor: `${theme.palette.common.yellow} !important`,
    },
    orange: {
      backgroundColor: `${theme.palette.common.orange} !important`,
    },
    success: {
      backgroundColor: `${theme.palette.success.main} !important`,
    },
  }),
  options
);

export default useStyles;
