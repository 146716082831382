import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { PageHeader } from "../../../components/page-header";
import { Chip } from "../../../components/chip";
import { Card } from "../../../components/card";
import { useDialog } from "../../../core/hooks/useDialog";
import { ConfirmDialog } from "../../../components/dialogs/ConfirmDialog";
import { DuplicateDialog } from "./DuplicateDialog";
import { DuplicateWithNewFiltersDialog } from "./DuplicateWithNewFiltersDialog";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { LinkButton } from "../../../components/buttons";
import { url } from "../../../core/utils/route.utils";
import { ROUTE_GROUP_DETAIL, ROUTE_GROUPS } from "../../../core/constants";
import { useLoading } from "../../../core/hooks/useLoading";
import { SearchNewClientsDialog } from "./SearchNewClientsDialog";
import { GroupPreview } from "./GroupPreview";
import { ArchiveDialog } from "./ArchiveDialog";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateGroupMutation,
  useFetchOneGroupMutation,
  useRemoveClientsFromGroupMutation,
} from "../../../core/store";
import get from "lodash/get";
import truncate from "lodash/truncate";
import { format } from "date-fns";
import { GroupStatusLabels } from "../../../core/constants/group-status-labels";

export const GroupDetailContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getOneGroup, { data: group, isLoading }] = useFetchOneGroupMutation();
  const [removeClientsFromGroup] = useRemoveClientsFromGroupMutation();
  const [createGroup] = useCreateGroupMutation();
  const [openConfirmDialog, closeConfirmDialog] = useDialog(ConfirmDialog);
  const [openArchiveDialog] = useDialog(ArchiveDialog);
  const [openDuplicateDialog, closeDuplicateDialog] =
    useDialog(DuplicateDialog);
  const [openDuplicateWithNewFiltersDialog] = useDialog(
    DuplicateWithNewFiltersDialog
  );
  const [openSearchNewClientsDialog] = useDialog(SearchNewClientsDialog);
  const { showMessage } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    getOneGroup({ id })
      .unwrap()
      .catch((e) => {
        showMessage("An error occurred while getting the group", "error");
      });
  }, [id]);

  useEffect(() => {
    if (isLoading) {
      showLoading("Loading group...");
    } else {
      hideLoading();
    }
  }, [isLoading]);

  const handleRemoveClients = (ids) => {
    openConfirmDialog({
      title: "Are you sure you want to remove clients from the group?",
      onConfirm: () => {
        removeClientsFromGroup({
          listId: id,
          contactVids: ids,
        })
          .unwrap()
          .then(() => {
            closeConfirmDialog();
            getOneGroup({ id });
            showMessage("Clients were successfully removed from the group");
          })
          .catch((err) => {
            showMessage("Error removing clients from the group");
          });
      },
    });
  };

  const handleArchive = () => {
    openConfirmDialog({
      title: "Are you sure you want to archive this group?",
      onConfirm: () => {
        openArchiveDialog({
          group,
          onSuccess: () => {
            showMessage("Group was successfully archived");
            navigate(ROUTE_GROUPS);
          },
        });
      },
    });
  };

  const handleDuplicate = () => {
    openDuplicateDialog({
      onConfirmWithSame: () => {
        const date = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        createGroup({
          name: get(group, "name") + " - " + date,
          dynamic: false,
          city: get(group, "city"),
          filters: get(group, "groupFilters"),
        })
          .unwrap()
          .then((res) => {
            const name = get(res, "name");
            const id = get(res, "listId");
            showMessage(
              <>
                The group {name} was successfully duplicated.{" "}
                <LinkButton to={url(ROUTE_GROUP_DETAIL, { id })}>
                  Open duplicate
                </LinkButton>
              </>
            );
          })
          .catch(() => {
            showMessage("Error duplicating group");
          });
        closeDuplicateDialog();
      },
      onConfirmWithNew: () => {
        openDuplicateWithNewFiltersDialog({
          group,
        });
      },
    });
  };

  const handleSearchNewClients = () => {
    openSearchNewClientsDialog({
      group,
      showLoading,
      hideLoading,
      onSuccessfulAdd: () => {
        getOneGroup({ id });
      },
    });
  };

  if (!group) return null;

  return (
    <>
      <PageHeader
        title={truncate(get(group, "name"), { length: 50 })}
        meta={
          <Chip
            label={get(GroupStatusLabels, get(group, "groupStatus"), "new")}
            color="yellow"
          />
        }
        backTitle="Groups"
      >
        <Button onClick={handleDuplicate}>Duplicate</Button>
        <Button onClick={handleSearchNewClients}>
          Search new clients by group filters
        </Button>
        <Button color="secondary" variant="outlined" onClick={handleArchive}>
          Archive
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1 }}>
        <GroupPreview group={group} onRemoveSelected={handleRemoveClients} />
      </Card>
    </>
  );
};
