import { LinkButton } from "../../../components/buttons";
import { url } from "../../../core/utils/route.utils";
import { ROUTE_GROUP_DETAIL } from "../../../core/constants/route.constants";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Chip } from "../../../components/chip";
import { get } from "lodash";
import { StatusChip } from "../../../components/chip/StatusChip";
import { Space } from "../../../components/space";
import { DataTableColumn } from "../../../components/table-view/TableView";
import { IconArrowRight } from "../../../components/icons";
import { Link } from "@mui/material";
import Stack from "@mui/material/Stack";
import map from "lodash/map";
import { HistoryLabels } from "../../../core/constants/history-labels";
import { GroupStatusByKey } from "../../../core/http/mock-data";

const statusColor = {
  new: "mediumLightBlue",
  in_progress: "yellow",
};

export const resource = {
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Group name",
      dataIndex: "group",
      key: "group",
      render: (group: any, record: any) => {
        return (
          <LinkButton to={url(ROUTE_GROUP_DETAIL, { id: record.id })}>
            {group.name}
          </LinkButton>
        );
      },
    },
    {
      title: "Clients",
      dataIndex: "clients",
      key: "clients",
      sortable: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sortable: true,

      render: (record: any) => {
        return record && record.label;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sortable: true,

      render: (record: any) => {
        return (
          <Chip
            color={get(statusColor, record.value, "mediumLightBlue")}
            label={record.label}
          />
        );
      },
    },
    {
      title: "Creation date",
      dataIndex: "createdAt",
      key: "createdAt",
      sortable: true,
      cellProps: {
        width: "11%",
      },
      render: (record: any) => {
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            {record && format(new Date(record), "dd/MM/yyyy p")}
          </Box>
        );
      },
    },
  ],
};
export const searchGroupColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Group name",
    dataIndex: "group",
    key: "group",
    render: (record: any, _, { onClickName }) => {
      return (
        <Link onClick={() => onClickName && onClickName(record)}>
          {record.name}
        </Link>
      );
    },
  },
  {
    title: "Clients",
    dataIndex: "clients",
    key: "clients",
    sortable: true,
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    sortable: true,

    render: (record: any) => {
      return record && record.label;
    },
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      width: "11%",
    },
    render: (record: any) => {
      return (
        <Box sx={{ whiteSpace: "nowrap" }}>
          {record && format(new Date(record), "dd/MM/yyyy p")}
        </Box>
      );
    },
  },
];

export const clientGroupColumns = [
  {
    title: "ID",
    dataIndex: "listId",
    key: "listId",
  },
  {
    title: "Group name",
    dataIndex: "name",
    key: "name",
    render: (value: any, record) => {
      return (
        <LinkButton to={url(ROUTE_GROUP_DETAIL, { id: record.listId })}>
          {value}
        </LinkButton>
      );
    },
  },
  {
    title: "Clients",
    dataIndex: "clientsCount",
    key: "clientsCount",
    sortable: false,
  },
  // {
  //   title: "Location",
  //   dataIndex: "location",
  //   key: "location",
  //   sortable: true,
  //
  //   render: (record: any) => {
  //     return record;
  //   },
  // },
  {
    title: "Status",
    dataIndex: "groupStatus",
    key: "groupStatus",
    sortable: false,
    render: (value: any) => {
      return (
        <StatusChip label={get(GroupStatusByKey, value, value)} value={value} />
      );
    },
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: false,
    cellProps: {
      width: "11%",
    },
    render: (record: any) => {
      return (
        <Box sx={{ whiteSpace: "nowrap" }}>
          {record && format(new Date(record), "dd/MM/yyyy p")}
        </Box>
      );
    },
  },
];

export const historyColumns: DataTableColumn[] = [
  {
    title: "Action",
    dataIndex: "operation.name",
    key: "action",
    render: (record: any) => {
      return get(HistoryLabels, record, record);
    },
  },
  // {
  //   title: "Group name",
  //   dataIndex: "group",
  //   key: "group",
  //   render: (group: any, record: any) => {
  //     return (
  //       <LinkButton to={url(ROUTE_GROUP_DETAIL, { id: record.id })}>
  //         {group.name}
  //       </LinkButton>
  //     );
  //   },
  // },
  // {
  //   title: "Prev. status",
  //   dataIndex: "prevStatus",
  //   key: "prevStatus",
  //   render: (_, record: any) => {
  //     return (
  //       <Space spacing={0.5} alignItems="center">
  //         <StatusChip {...record.prevStatus} />
  //         <IconArrowRight sx={{ fontSize: 16 }} />
  //         <StatusChip {...record.status} />
  //       </Space>
  //     );
  //   },
  // },
  {
    title: "Date/time",
    dataIndex: "date",
    key: "date",
    sortable: true,
    cellProps: {
      width: "11%",
    },
    render: (record: any) => {
      return (
        <Box sx={{ whiteSpace: "nowrap" }}>
          {record && format(new Date(record), "dd/MM/yyyy p")}
        </Box>
      );
    },
  },
];

export const searchClientColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sortable: true,
    render: (record: any) => {
      return (
        <Chip
          color={get(statusColor, record.value, "mediumLightBlue")}
          label={record.label}
        />
      );
    },
  },
  {
    title: "Company name",
    dataIndex: "company",
    key: "company",
    render: (record: any, _, { onClickName }) => {
      return (
        <Link onClick={() => onClickName && onClickName(record)}>
          {record.name}
        </Link>
      );
    },
  },

  {
    title: "Business type",
    dataIndex: "businessType",
    key: "businessType",
    sortable: true,
    render: (items: any) => {
      return (
        <Stack
          direction="row"
          flexWrap="nowrap"
          width={115}
          sx={{
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {map(items, (x) => (
            <Chip color="lightBlue" key={x.label} label={x.label} />
          ))}
        </Stack>
      );
    },
  },

  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    sortable: true,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Area (sq ft)",
    dataIndex: "area",
    key: "area",
    sortable: true,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Budget",
    dataIndex: "budget",
    key: "budget",
    sortable: true,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Portraying",
    dataIndex: "portrayed",
    key: "portrayed",
    sortable: true,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Pricing",
    dataIndex: "pricing",
    key: "pricing",
    sortable: true,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
];
