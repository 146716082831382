import { Status } from "../../../core/enums/status.enum";

export const groupLists = [
  { id: 1, name: "Soho, NY" },
  { id: 2, name: "Denver" },
];

export const locationList = [
  { label: "New York", value: "new_york" },
  { label: "Los Angeles", value: "los_angeles" },
  { label: "Chicago", value: "chicago" },
  { label: "Houston", value: "houston" },
];

export const statusList = [
  { label: "New", value: Status.NEW },
  { label: "In Progress", value: Status.IN_PROGRESS },
  { label: "On Review", value: Status.ON_REVIEW },
  { label: "Matched", value: Status.MATCHED },
];

export const groupTableData = [
  {
    id: 558612,
    group: groupLists[0],
    clients: 4,
    location: locationList[0],
    status: statusList[0],
    createdAt: new Date().toISOString(),
  },
  {
    id: 558613,
    group: groupLists[1],
    clients: 3,
    location: locationList[1],
    status: statusList[1],
    createdAt: new Date().toISOString(),
  },
];
