import { useEffect, useState } from "react";
import { DataTableRowSelection } from "./TableView";

export const useTableSelection = (
  dataSource: Record<string, any>[],
  rowSelection?: DataTableRowSelection
) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const isSelectedRowsEmpty = selectedRows.length === 0;
  const isSelectedAll = selectedRows.length === dataSource.length;

  useEffect(() => {
    if (rowSelection?.onChange) {
      rowSelection?.onChange(selectedRows);
    }
  }, [selectedRows]);

  const handleSelectRow = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((item) => item !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (isSelectedRowsEmpty) {
      setSelectedRows(dataSource.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  return {
    selectedRows,
    isSelectedRowsEmpty,
    isSelectedAll,
    handleSelectRow,
    handleSelectAll,
  };
};
