import { ComponentType } from "react";
import { Stack, StackProps } from "@mui/material";

export const Space: ComponentType<StackProps> = ({ children, ...props }) => {
  return (
    <Stack direction="row" {...props}>
      {children}
    </Stack>
  );
};
