import { Card } from "../card";
import Stack from "@mui/material/Stack";
import Box, { BoxProps } from "@mui/material/Box";
import { LabeledRecord } from "../labeled-record";
import { Chip } from "../chip";
import { ComponentType } from "react";
import get from "lodash/get";
import { map } from "lodash";
import isEmpty from "lodash/isEmpty";
import { normalizeGroupFilters } from "../../core/utils/hubspot.util";

interface Props extends BoxProps {
  group?: any;
}

export const DisplayFilters: ComponentType<Props> = ({ group, ...props }) => {
  const groupFilters = get(group, "groupFilters", []);
  const {
    locationEq,
    locationNeq,
    businessTypeEq,
    businessTypeNeq,
    areaOfPremisesEq,
    areaOfPremisesNeq,
    ageGroupEq,
    ageGroupNeq,
    genderEq,
    genderNeq,
  } = normalizeGroupFilters(groupFilters);

  const exceptionIsEmpty =
    isEmpty(locationNeq) &&
    isEmpty(businessTypeNeq) &&
    isEmpty(areaOfPremisesNeq) &&
    isEmpty(ageGroupNeq) &&
    isEmpty(genderNeq);

  const filterIsEmpty =
    isEmpty(locationEq) &&
    isEmpty(businessTypeEq) &&
    isEmpty(areaOfPremisesEq) &&
    isEmpty(ageGroupEq) &&
    isEmpty(genderEq);

  return (
    <Box {...props}>
      <Card
        title="Filters"
        color="secondary"
        variant="outlined"
        size="small"
        sx={{ mb: 1 }}
      >
        {!filterIsEmpty ? (
          <Stack direction="row" spacing={1.5}>
            {!isEmpty(locationEq) && (
              <LabeledRecord label="Location">
                {map(locationEq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(businessTypeEq) && (
              <LabeledRecord label="Business type">
                {map(businessTypeEq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(areaOfPremisesEq) && (
              <LabeledRecord label="Area of premises (sq ft)">
                {map(areaOfPremisesEq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(ageGroupEq) && (
              <LabeledRecord label="Age group">
                {map(ageGroupEq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(genderEq) && (
              <LabeledRecord label="Gender">
                {map(genderEq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
          </Stack>
        ) : (
          <LabeledRecord label="No filters" />
        )}
      </Card>
      <Card title="Exceptions" size="small" color="secondary">
        {!exceptionIsEmpty ? (
          <Stack direction="row" spacing={1.5}>
            {!isEmpty(locationNeq) && (
              <LabeledRecord label="Location">
                {map(locationNeq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(businessTypeNeq) && (
              <LabeledRecord label="Business type">
                {map(businessTypeNeq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(areaOfPremisesNeq) && (
              <LabeledRecord label="Area of premises (sq ft)">
                {map(areaOfPremisesNeq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(ageGroupNeq) && (
              <LabeledRecord label="Age group">
                {map(ageGroupNeq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(genderNeq) && (
              <LabeledRecord label="Gender">
                {map(genderNeq, (x) => (
                  <Chip label={x.value} key={x.value} />
                ))}
              </LabeledRecord>
            )}
          </Stack>
        ) : (
          <LabeledRecord label="No exceptions" />
        )}
      </Card>
    </Box>
  );
};
