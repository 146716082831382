import {
  useCreateEmployeeMutation,
  useFetchOneEmployeeMutation,
  useUpdateEmployeeMutation,
} from "../store";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import get from "lodash/get";

interface UseEmployeeFormProps {
  id?: number | string;
  onSuccess: (res) => void;
  onFail: (res) => void;
}

export const useEmployeeForm = ({
  id,
  onSuccess,
  onFail,
}: UseEmployeeFormProps) => {
  const [createOne] = useCreateEmployeeMutation();
  const [updateOne] = useUpdateEmployeeMutation();
  const [getEmployeeData, { data }] = useFetchOneEmployeeMutation();

  useEffect(() => {
    if (id) getEmployeeData({ id });
  }, [id]);

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      role: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Please enter valid email.")
        .required("Required field"),
      name: yup.string().trim().required("Required field"),
      phone: yup.string().required("Required field"),
      role: yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      if (!id) {
        createOne(values).unwrap().then(handleSuccess).catch(handleFail);
      } else {
        updateOne({ id, values })
          .unwrap()
          .then(handleSuccess)
          .catch(handleFail);
      }
    },
  });

  const handleSuccess = (res) => {
    onSuccess(res);
  };
  const handleFail = (res) => {
    const errorCode = get(res, "data.errorCode");
    const message = get(res, "data.errorMessage");
    if (errorCode === "entity_already_exists") {
      if (message.includes("with phone")) {
        setFieldError("phone", "Phone already exists");
      } else {
        setFieldError("email", "Email already exists");
      }
    }
    onFail(res);
  };

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];

    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
