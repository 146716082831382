import { alpha } from "@mui/material";

export const darkPalette = {
  type: "dark",
  background: {
    default: "#212332",
    paper: "#383C51",
    title: "#4F5368",
    selected: "#2B2E40",
  },
  text: {
    primary: "#ffffff",
    secondary: "#7A7A7A",
  },
  primary: {
    main: alpha("#5999F1", 0.5),
    dark: alpha("#5999F1", 0.5),
    light: alpha("#5999F1", 0.5),
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: alpha("#52BE80", 0.5),
    dark: alpha("#52BE80", 0.5),
    light: alpha("#52BE80", 0.5),
    contrastText: "#FFFFFF",
  },
  third: {
    main: alpha("#AF74E6", 0.5),
    dark: alpha("#AF74E6", 0.5),
    light: alpha("#AF74E6", 0.5),
    contrastText: "#FFFFFF",
  },
  forth: {
    main: "#FC4788",
    dark: "#FC4788",
    light: "#FC4788",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#27AE60",
    dark: "#52BE80",
    light: alpha("#27AE60", 0.5),
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#F2994A",
    dark: "#F17459",
    light: alpha("#F2994A", 0.5),
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#EB5757",
    dark: "#EB5757",
    light: alpha("#EB5757", 0.5),
    contrastText: "#FFFFFF",
  },
  info: {
    dark: "#51D7E0",
    light: "#51D7E0",
    main: "#51D7E0",
    contrastText: "#383C51",
  },
  a11y: {
    light: "transparent",
    main: "#FFFFFF",
    dark: "#222222",
    contrastText: "transparent",
  },
  branding: {
    oneid: {
      color: "#4825C2",
      contrastText: "#ffffff",
    },
    perfectum: {
      color: "#F46A33",
      contrastText: "#ffffff",
    },
    beeline: {
      color: "#F6D052",
      contrastText: "#2B2E40",
    },
    ucell: {
      color: "#692881",
      contrastText: "#ffffff",
    },
    uzmobile: {
      color: "#1FB5FC",
      contrastText: "#ffffff",
    },
    humans: {
      color: "#FDD764",
      contrastText: "#2B2E40",
    },
  },
};
