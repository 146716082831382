import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { ClientDto, EmployeeDto, GroupDto } from "../../dto";
import { IDPayload } from "../../constants/types";

export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    fetchAllClients2: builder.mutation<ClientDto[], Record<string, any>>({
      query: (body) => ({
        url: "/api/hs/crm/v3/objects/contacts/search",
        method: "POST",
        body,
      }),
    }),
    fetchAllClients: builder.mutation<ClientDto[], Record<string, any>>({
      query: (body) => ({
        url: "/api/client/filter",
        method: "POST",
        body,
      }),
    }),
    matchingAllClients: builder.mutation<ClientDto[], Record<string, any>>({
      query: (body) => ({
        url: "/api/client/matching",
        method: "POST",
        body,
      }),
    }),
    fetchOneClient: builder.mutation<GroupDto[], IDPayload>({
      query: ({ id, ...params }) => ({
        url: `/api/client/${id}`,
        method: "GET",
        params,
      }),
    }),

    createClient: builder.mutation<any, Record<string, any>>({
      query: (body) => ({
        url: `/api/client`,
        method: "POST",
        body,
      }),
    }),

    updateClient: builder.mutation<ClientDto, Record<string, any>>({
      query: (body) => ({
        url: `/api/client`,
        method: "PUT",
        body,
      }),
    }),

    rejectClient: builder.mutation<
      ClientDto,
      { id: string | number; [key: string]: any }
    >({
      query: ({ id, ...body }) => ({
        url: `/api/client/${id}/reject`,
        method: "POST",
        body: {
          listId: id,
          ...body,
        },
      }),
    }),

    archiveClient: builder.mutation<
      ClientDto,
      { id: string | number; [key: string]: any }
    >({
      query: ({ id, ...body }) => ({
        url: `/api/client/${id}/archive`,
        method: "POST",
        body: {
          vid: id,
          ...body,
        },
      }),
    }),

    historyClient: builder.mutation<EmployeeDto, { id: string | number }>({
      query: ({ id }) => ({
        url: `/api/client/${id}/history`,
        method: "GET",
      }),
    }),

    matchClient: builder.mutation<
      ClientDto,
      { id: string | number; [key: string]: any }
    >({
      query: ({ id, ...body }) => ({
        url: `/api/client/${id}/match`,
        method: "POST",
        body,
      }),
    }),

    fetchClientGroups: builder.mutation<GroupDto[], IDPayload>({
      query: ({ id, ...params }) => ({
        url: `/api/client/${id}/groups`,
        method: "GET",
      }),
    }),

    setClientInProgress: builder.mutation<GroupDto[], IDPayload>({
      query: ({ id }) => ({
        url: `/api/client-in-progress`,
        method: "post",
        body: {
          vid: id,
        },
      }),
    }),
  }),
});

export const {
  useFetchAllClients2Mutation,
  useFetchAllClientsMutation,
  useMatchingAllClientsMutation,
  useFetchOneClientMutation,
  useCreateClientMutation,
  useUpdateClientMutation,
  useRejectClientMutation,
  useArchiveClientMutation,
  useMatchClientMutation,
  useHistoryClientMutation,
  useFetchClientGroupsMutation,
  useSetClientInProgressMutation,
} = clientApi;
