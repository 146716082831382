import React, { ComponentType, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DialogProps } from "../../core/providers/dialog.provider";
import { Card } from "../../components/card";
import {
  ageGroupList,
  areaOfPremisesList,
  budgetList,
  businessTypeList,
  genderList,
  locationList,
  portrayingList,
  pricingList,
} from "../mock-data";
import { get } from "lodash";
import { SelectField } from "../../components/fields";
import Grid from "@mui/material/Grid";
import { useGroupForm } from "../../core/hooks/useGroupForm";
import { LocationField } from "../../components/fields/location-field";
import { useSnackbar } from "../../core/hooks/useSnackbar";

interface Props extends DialogProps {
  onSuccess: () => void;
}

export const CreateNewGroupDialog: ComponentType<Props> = ({
  popDialog,
  onSuccess,
}) => {
  const { showMessage } = useSnackbar();
  const { handleSubmit, values, errors, getFieldProps, isLoading } =
    useGroupForm({
      onSuccess: () => {
        popDialog();
        showMessage("Group created successfully");
        onSuccess && onSuccess();
      },
    });
  const [filter, setFilter] = useState({});
  const [exception, setException] = useState({});
  const handleFilter = (name) => (value) => {
    setFilter({ ...filter, [name]: value });
  };

  const handleException = (name) => (value) => {
    setException({ ...exception, [name]: value });
  };

  const handleClearException = () => {
    setException({});
  };

  return (
    <Dialog
      open
      // onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { maxWidth: 640 } }}
    >
      <DialogTitle>Create new group</DialogTitle>
      <DialogContent>
        <Stack rowGap={1.5}>
          <TextField label="Group name" {...getFieldProps("name")} />
          <TextField
            label="Description"
            rows={4}
            multiline
            placeholder="At least 60 symbols"
            {...getFieldProps("comments")}
          />

          <Card
            title="Filters"
            color="secondary"
            variant="outlined"
            size="small"
          >
            <Stack rowGap={1}>
              <LocationField
                multiple
                label="Location"
                {...getFieldProps("filters.state")}
              />
              <SelectField
                group
                multiple
                placeholder="Select"
                label="Business type"
                items={businessTypeList}
                {...getFieldProps("filters.business_type")}
              />
              <SelectField
                multiple
                placeholder="Select"
                label="Area of premises (sq ft)"
                items={areaOfPremisesList}
                {...getFieldProps("filters.area_of_premises")}
              />
              <Grid container columnSpacing={1}>
                <Grid item lg={6}>
                  <SelectField
                    multiple
                    placeholder="Select"
                    label="Age group"
                    items={ageGroupList}
                    {...getFieldProps("filters.age_group")}
                  />
                </Grid>
                <Grid item lg={6}>
                  <SelectField
                    multiple
                    placeholder="Select"
                    label="Gender"
                    items={genderList}
                    {...getFieldProps("filters.gender")}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={1}>
                <Grid item lg={6}>
                  <SelectField
                    multiple
                    placeholder="Select"
                    label="Pricing"
                    items={pricingList}
                    {...getFieldProps("filters.pricing")}
                  />
                </Grid>
                <Grid item lg={6}>
                  <SelectField
                    multiple
                    placeholder="Select"
                    label="Budget"
                    items={budgetList}
                    {...getFieldProps("filters.budget")}
                  />
                </Grid>
              </Grid>
              <SelectField
                multiple
                placeholder="Select"
                label="Portraying"
                items={portrayingList}
                {...getFieldProps("filters.portraying")}
              />
            </Stack>
          </Card>

          <Card
            title="Exceptions"
            color="secondary"
            variant="outlined"
            size="small"
          >
            <Stack rowGap={1}>
              <LocationField
                multiple
                label="Location"
                value={get(filter, "location", [])}
                onChange={handleFilter("location")}
                {...getFieldProps("exceptions.state")}
              />
              <SelectField
                group
                multiple
                placeholder="Select"
                label="Business type"
                items={businessTypeList}
                {...getFieldProps("exceptions.business_type")}
              />
              <SelectField
                multiple
                placeholder="Select"
                label="Area of premises (sq ft)"
                items={areaOfPremisesList}
                {...getFieldProps("exceptions.area_of_premises")}
              />
              <Grid container columnSpacing={1}>
                <Grid item lg={6}>
                  <SelectField
                    multiple
                    placeholder="Select"
                    label="Age group"
                    items={ageGroupList}
                    {...getFieldProps("exceptions.age_group")}
                  />
                </Grid>
                <Grid item lg={6}>
                  <SelectField
                    multiple
                    placeholder="Select"
                    label="Gender"
                    items={genderList}
                    {...getFieldProps("exceptions.gender")}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
