import React, { ComponentType, useState } from "react";
import useStyles from "./styles";
import { TextField, TextFieldProps, Autocomplete } from "@mui/material";
import { IconClose, IconSelect } from "../../icons";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

interface Props extends Omit<TextFieldProps, "onChange"> {
  onChange: (value: any) => void;
  multiple?: boolean;
  placeholder?: string;
}

export const LocationField: ComponentType<Props> = ({
  multiple = false,
  onChange,
  label,
  value,
  placeholder,
  error,
  helperText,
}) => {
  const classes = useStyles();

  const options = [
    {
      title: "Soho, NY",
      value: "Soho",
      state: "NY",
    },
    {
      title: "Other",
      value: "other",
      state: "",
    },
  ];

  const handleChange = (e, option) => {
    if (Array.isArray(option)) {
      onChange(
        !isEmpty(option)
          ? option.map((x) => ({ state: x.state, city: x.value }))
          : []
      );
    } else {
      onChange(option ? { state: option.state, city: option.value } : null);
    }
  };

  const handleDelete = (deleteValue: any) => () => {
    onChange(isArray(value) ? value.filter((v) => v !== deleteValue) : "");
  };

  return (
    <Autocomplete
      multiple={multiple}
      getOptionLabel={(option: any) => option.title}
      classes={{
        option: classes.menuItem,
        groupLabel: classes.menuItem,
      }}
      ChipProps={{
        size: "small",
        deleteIcon: <IconClose />,
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      popupIcon={<IconSelect />}
      clearIcon={null}
      onChange={handleChange}
      options={options}
      renderInput={(params) => (
        <TextField
          {...{
            ...params,
            label,
            value,
            error,
            helperText,
            placeholder: "Select",
            InputLabelProps: {
              shrink: true,
            },
          }}
        />
      )}
    />
  );
};
