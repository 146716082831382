import React from "react";
import { BaseLayout } from "../components/layouts/base-layout";
import { PageHeader } from "../components/page-header";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { SelectField } from "../components/fields";
import { Card } from "../components/card";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export const UiPage = () => {
  const [currency, setCurrency] = React.useState("EUR");
  const [value, setValue] = React.useState(["EUR"]);

  const handleChange = (value) => {
    setCurrency(value);
  };
  const handleChange2 = (value) => {
    setValue(value);
  };

  return (
    <BaseLayout>
      <PageHeader title="UI"></PageHeader>
      <Card title="Inputs">
        <TextField label="Required" defaultValue="Hello World" />
        <SelectField
          label="select"
          value={currency}
          items={currencies}
          onChange={handleChange}
        />
        <SelectField
          multiple
          label="multiple select"
          value={value}
          items={currencies}
          onChange={handleChange2}
        />

        <Card shadow={false} color="secondary">
          lorem ipsum
        </Card>
      </Card>

      <Card>
        <Box sx={{ mb: 2 }}>
          <Button size="small">Create new group</Button>
          <Button size="medium" disabled>
            Create new group
          </Button>
          <Button size="large">Create new group</Button>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Button size="small" variant="outlined">
            Create new group
          </Button>
          <Button size="medium" variant="outlined" disabled>
            Create new group
          </Button>
          <Button size="large" variant="outlined">
            Create new group
          </Button>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Button size="small" color="secondary">
            Create new group
          </Button>
          <Button size="medium" color="secondary">
            Create new group
          </Button>
          <Button size="large" color="secondary">
            Create new group
          </Button>
        </Box>

        <Box>
          <Button size="small" color="secondary" variant="outlined">
            Create new group
          </Button>
          <Button size="medium" color="secondary" variant="outlined" disabled>
            Create new group
          </Button>
          <Button size="large" color="secondary" variant="outlined">
            Create new group
          </Button>
        </Box>
      </Card>
    </BaseLayout>
  );
};
