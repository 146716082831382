import { ComponentType, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Fade from "@mui/material/Fade";
import LoadingContext from "../../contexts/loading.context";
import { CircularProgress } from "../../../components/circular-progress";
import useStyles from "./styles";

const DEFAULT_MESSAGE = "Loading...";

export const LoadingProvider: ComponentType = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(DEFAULT_MESSAGE);

  const showLoading = (message: string) => {
    setIsLoading(true);
    setMessage(message);
  };
  const hideLoading = () => {
    setIsLoading(false);
    setMessage(DEFAULT_MESSAGE);
  };

  const classes = useStyles();

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      <Fade in={isLoading} unmountOnExit>
        <Box className={classes.backdrop}>
          <Zoom in={isLoading} unmountOnExit>
            <Stack
              className={classes.message}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <CircularProgress sx={{ fontSize: 32 }} />
              <Typography variant="body1">{message}</Typography>
            </Stack>
          </Zoom>
        </Box>
      </Fade>
    </LoadingContext.Provider>
  );
};
