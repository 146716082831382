import { ComponentType } from "react";
import { Chip, ChipColors } from "./Chip";
import get from "lodash/get";
import { Status } from "../../core/enums/status.enum";

interface Props {
  label: string;
  value: Status | string;
}
export const StatusChip: ComponentType<Props> = ({ label, value }) => {
  const statusColor: Record<string, ChipColors> = {
    new: "mediumLightBlue",
    in_progress: "yellow",
    on_review: "orange",
    matched: "success",
  };

  const color = get(statusColor, value, "mediumLightBlue");

  return <Chip {...{ label, color }} />;
};
