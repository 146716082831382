import { combineReducers } from "redux";
import {
  clientApi,
  authApi,
  userApi,
  hubspotApi,
  employeeApi,
  groupApi,
} from "./api";

export const rootReducer = combineReducers({
  [clientApi.reducerPath]: clientApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [hubspotApi.reducerPath]: hubspotApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
});
