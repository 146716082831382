import { TextField, TextFieldProps } from "@mui/material";
import React, { ComponentType } from "react";
import NumberFormat from "react-number-format";

interface Props extends Omit<TextFieldProps, "onChange"> {
  onChange?: (value: string) => void;
}

export const PhoneField: ComponentType<Props> = ({ onChange, ...props }) => {
  const handleChange = ({ value }) => {
    onChange?.(value);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NumberFormat
      {...props}
      customInput={TextField}
      format="(###) ###-####"
      type="text"
      onValueChange={handleChange}
    />
  );
};
