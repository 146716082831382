import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "sidebar",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: "none",
    },
    drawer: {},
    drawerPaper: {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      border: "none",
    },
    toolbar: {
      padding: theme.spacing(5, 3, 4, 3),
    },
    nav: {
      // padding: 0
    },
    navItem: {
      padding: theme.spacing(1.5, 3),
      "&$navItemSelected, &$navItemSelected:hover, &:hover": {
        backgroundColor: theme.palette.common.violet,
      },
    },
    navItemSelected: {},
    navItemIcon: {
      display: "flex",
      marginRight: theme.spacing(1),
    },
    navItemLabel: {
      // margin: 0,
    },
  }),
  options
);

export default useStyles;
