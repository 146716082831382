import { Card } from "../../../components/card";
import { Editable } from "../../../components/editable";
import {
  Button,
  TextField,
  Typography,
  Link,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import { LabeledRecord } from "../../../components/labeled-record";
import { LinkButton } from "../../../components/buttons";
import { Chip } from "../../../components/chip";
import React, { ComponentType } from "react";
import { usPhoneNumber } from "../../../core/utils/format.utils";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { TableView } from "../../../components/table-view";
import { clientGroupColumns } from "./resource";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { useUpdateClientMutation } from "../../../core/store";

interface Props {
  client: any;
  onEdit?: () => void;

  groupsData?: any;
}

export const Common: ComponentType<Props> = ({
  client,
  onEdit,
  groupsData,
}) => {
  const [updateOne] = useUpdateClientMutation();
  const [comment, setComment] = React.useState(get(client, "comment"));
  const { showMessage } = useSnackbar();

  const handleSubmitComment = () => {
    updateOne({
      vid: get(client, "id"),
      properties: [
        {
          property: "description",
          value: comment,
        },
      ],
    })
      .unwrap()
      .catch((err) => {
        showMessage("Error updating client", "error");
      });
  };

  const id = get(client, "id");
  const location = get(client, "location");
  const businessTypes = get(client, "businessTypes");
  const hubspotProfileLink = get(client, "hubspotProfileLink");
  const contactPerson = get(client, "contactPerson");
  const email = get(client, "emailAddress");
  const phone = get(client, "phoneNumber");
  const website = get(client, "website");
  const socials = get(client, "socials");
  const employees = get(client, "employees");
  const retailSpots = get(client, "retailSpots");
  const areaOfPremisses = get(client, "areaOfPremisses");
  const ageGroup = get(client, "ageGroup");
  const gender = get(client, "gender");
  const budget = get(client, "budget");
  const pricing = get(client, "pricing");
  const portray = get(client, "portray");
  const groups = get(groupsData, "items", []);

  return (
    <>
      <Card
        color="secondary"
        variant="outlined"
        size="small"
        sx={{ mb: 1, p: 1 }}
      >
        <Editable
          actionText="Edit comment"
          editable={!!onEdit}
          editableView={
            <TextField
              multiline
              defaultValue={get(client, "comment")}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          }
          onSubmit={handleSubmitComment}
        >
          <Typography variant="body2">{comment}</Typography>
        </Editable>
      </Card>
      <Card
        title={
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Client information</Box>
            <Box>
              {hubspotProfileLink && (
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  component={Link}
                  target="_blank"
                  href={hubspotProfileLink}
                >
                  Open profile in Hubspot
                </Button>
              )}
            </Box>
          </Box>
        }
        color="secondary"
        variant="outlined"
        sx={{ mb: 1, p: 1 }}
      >
        <Grid container sx={{ mb: 1.5 }}>
          <Grid item md={3}>
            <LabeledRecord label="ID:">{id}</LabeledRecord>
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Contact person:">
              {contactPerson || "-"}
            </LabeledRecord>
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Email address:">{email || "-"}</LabeledRecord>
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Phone number:">
              {phone ? usPhoneNumber(get(client, "phoneNumber")) : "-"}
            </LabeledRecord>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={3}>
            <LabeledRecord label="Website:">{website || "-"}</LabeledRecord>
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Socials:">
              {socials ? (
                <LinkButton to={socials} color="secondary">
                  {socials}
                </LinkButton>
              ) : (
                "-"
              )}
            </LabeledRecord>
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Employees:">{employees || "-"}</LabeledRecord>
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Retail spots:">
              {retailSpots || "-"}
            </LabeledRecord>
          </Grid>
        </Grid>
      </Card>
      <Card
        title="Quiz report"
        color="secondary"
        variant="outlined"
        sx={{ mb: 1, p: 1, pb: 0 }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{ "&>*": { flex: "0 0 20%", mb: 1.5 } }}
        >
          <LabeledRecord label="Location">
            {location ? <Chip label={location} /> : "-"}
          </LabeledRecord>
          <LabeledRecord label="Business type">
            {!isEmpty(businessTypes)
              ? map(businessTypes, (businessType) => (
                  <Chip label={businessType} key={businessType} />
                ))
              : "-"}
          </LabeledRecord>
          <LabeledRecord label="Area of premises (sq ft)">
            {!isEmpty(areaOfPremisses)
              ? map(areaOfPremisses, (area) => <Chip label={area} key={area} />)
              : "-"}
          </LabeledRecord>
          <LabeledRecord label="Age group">
            {!isEmpty(ageGroup) ? <Chip label={ageGroup} /> : "-"}
          </LabeledRecord>
          <LabeledRecord label="Gender">
            {gender && gender != "-" ? <Chip label={gender} /> : "-"}
          </LabeledRecord>
          <LabeledRecord label="Budget">
            {budget ? <Chip label={budget} /> : "-"}
          </LabeledRecord>
          <LabeledRecord label="Pricing:">
            {pricing ? <Chip label={pricing} /> : "-"}
          </LabeledRecord>
          <LabeledRecord label="Portraying:">
            {!isEmpty(portray) ? <Chip label={portray} /> : "-"}
          </LabeledRecord>
        </Stack>
      </Card>
      {!isEmpty(groups) && (
        <Card
          title="Groups"
          color="secondary"
          variant="outlined"
          sx={{ p: 1, pb: 0 }}
        >
          <TableView
            striped
            keyExtractor={(item) => item.listId}
            columns={clientGroupColumns}
            dataSource={groups}
          />
        </Card>
      )}
    </>
  );
};
