import { Link } from "@mui/material";
import map from "lodash/map";
import Stack from "@mui/material/Stack";
import { Chip } from "../../components/chip";
import { get } from "lodash";
import { ChipList } from "../../components/chip-list";
import { budgetListByKey } from "../mock-data";

const statusColor = {
  new: "mediumLightBlue",
  in_progress: "yellow",
};

export const matchingColumnsOld = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sortable: false,
    render: (record: any) => {
      return (
        <Chip
          color={get(statusColor, record.value, "mediumLightBlue")}
          label={record.label}
        />
      );
    },
  },
  {
    title: "Company name",
    dataIndex: "company",
    key: "company",
    render: (record: any) => {
      return <Link>{record.name}</Link>;
    },
  },

  {
    title: "Business type",
    dataIndex: "businessType",
    key: "businessType",
    sortable: false,
    render: (items: any) => {
      return (
        <Stack
          direction="row"
          flexWrap="nowrap"
          width={115}
          sx={{
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {map(items, (x) => (
            <Chip color="lightBlue" key={x.label} label={x.label} />
          ))}
        </Stack>
      );
    },
  },

  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    sortable: false,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Area (sq ft)",
    dataIndex: "area",
    key: "area",
    sortable: false,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Budget",
    dataIndex: "budget",
    key: "budget",
    sortable: false,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Portraying",
    dataIndex: "portrayed",
    key: "portrayed",
    sortable: false,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
  {
    title: "Pricing",
    dataIndex: "pricing",
    key: "pricing",
    sortable: false,
    render: (record: any) => {
      return record && <Chip color="lightBlue" label={record.label} />;
    },
  },
];

export const matchingColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   sortable: false,
  //   render: (record: any) => {
  //     const status = record == "-" ? "new" : record;
  //     return (
  //       status && (
  //         <Chip
  //           color={get(statusColor, status, "mediumLightBlue")}
  //           label={status}
  //         />
  //       )
  //     );
  //   },
  // },
  {
    title: "Company name",
    dataIndex: "companyName",
    key: "companyName",
    render: (value: any, record) => {
      return <Link>{value || get(record, "contactPerson") || "N/A"}</Link>;
    },
  },

  {
    title: "Business type",
    dataIndex: "businessTypes",
    key: "businessTypes",
    sortable: false,
    render: (value) => <ChipList value={value} />,
  },

  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    sortable: false,
    render: (record: any) => {
      return (
        record && record !== ", " && <Chip color="lightBlue" label={record} />
      );
    },
  },
  {
    title: "Area (sq ft)",
    dataIndex: "area",
    key: "area",
    sortable: false,
    render: (value) => <ChipList value={value} />,
  },
  {
    title: "Budget",
    dataIndex: "budget",
    key: "budget",
    sortable: false,
    render: (value) => <ChipList value={value} />,
  },
  {
    title: "Portraying",
    dataIndex: "portray",
    key: "portray",
    sortable: false,
    render: (value) => <ChipList value={value} />,
  },
  {
    title: "Pricing",
    dataIndex: "pricing",
    key: "pricing",
    sortable: false,
    render: (value) => <ChipList value={value} />,
  },
];
