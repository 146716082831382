import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { EmployeeDto } from "../../dto";
import { plainToClass } from "class-transformer";

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    fetchAllEmployees: builder.query<
      {
        items: EmployeeDto[];
        totalCount: number;
        itemsPerPage: number;
        currentPage: number;
        pagesCount: number;
      },
      Record<string, any>
    >({
      query: (params) => ({
        url: "/api/employee",
        params,
      }),
    }),
    fetchOneEmployee: builder.mutation<EmployeeDto, Record<string, any>>({
      query: ({ id, ...params }) => ({
        url: `/api/employee/${id}`,
        params,
      }),
      transformResponse: (response) => plainToClass(EmployeeDto, response),
    }),
    createEmployee: builder.mutation<EmployeeDto, Record<string, any>>({
      query: (body) => ({
        url: `/api/employee`,
        method: "POST",
        body,
      }),
    }),
    updateEmployee: builder.mutation<
      EmployeeDto,
      { id: string | number; [key: string]: any }
    >({
      query: ({ id, body }) => ({
        url: `/api/employee/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteEmployee: builder.mutation<EmployeeDto, { id: string | number }>({
      query: ({ id }) => ({
        url: `/api/employee/${id}`,
        method: "DELETE",
      }),
    }),
    historyEmployee: builder.mutation<EmployeeDto, { id: string | number }>({
      query: ({ id }) => ({
        url: `/api/employee/${id}/history`,
        method: "GET",
      }),
    }),
    inviteEmployee: builder.mutation<EmployeeDto, { id: string | number }>({
      query: ({ id }) => ({
        url: `/api/employee/${id}/invite`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useFetchAllEmployeesQuery,
  useFetchOneEmployeeMutation,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useHistoryEmployeeMutation,
  useInviteEmployeeMutation,
} = employeeApi;
