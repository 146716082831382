import React from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import {
  UiPage,
  MatchingIndex,
  GroupsIndex,
  ClientsIndex,
  GroupDetail,
  ClientDetail,
  ClientCreate,
  SignIn,
  Forgot,
  Password,
  Invite,
  SignUp,
  EmployeesIndex,
  EmployeeForm,
  EmployeesDetail,
  Page404,
} from "./pages";
import {
  ROUTE_GROUPS,
  ROUTE_HOME,
  ROUTE_MATCHING,
  ROUTE_CLIENTS,
  ROUTE_UI,
  ROUTE_PROFILE,
  ROUTE_GROUP_DETAIL,
  ROUTE_CLIENT_DETAIL,
  ROUTE_CLIENT_CREATE,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_CREATE_NEW_PASSWORD,
  ROUTE_INVITE,
  ROUTE_EMPLOYEES,
  ROUTE_EMPLOYEES_DETAIL,
  ROUTE_EMPLOYEES_CREATE,
  ROUTE_EMPLOYEES_EDIT,
} from "./core/constants";
import { ProfileIndex } from "./pages/profile";
import { DemoLinks } from "./pages/demo-links";

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path={"/demo"} element={<DemoLinks />} />
      <Route path={ROUTE_HOME} element={<MatchingIndex />} />
      <Route path={ROUTE_SIGN_IN} element={<SignIn />} />
      <Route path={ROUTE_SIGN_UP} element={<SignUp />} />
      <Route path={ROUTE_FORGOT_PASSWORD} element={<Forgot />} />
      <Route path={ROUTE_CREATE_NEW_PASSWORD} element={<Password />} />
      <Route path={ROUTE_INVITE} element={<Invite />} />
      <Route path={ROUTE_UI} element={<UiPage />} />
      <Route path={ROUTE_MATCHING} element={<MatchingIndex />} />
      <Route path={ROUTE_GROUPS} element={<GroupsIndex />} />
      <Route path={ROUTE_GROUP_DETAIL} element={<GroupDetail />} />
      <Route path={ROUTE_CLIENTS} element={<ClientsIndex />} />
      <Route path={ROUTE_CLIENT_CREATE} element={<ClientCreate />} />
      <Route path={ROUTE_CLIENT_DETAIL} element={<ClientDetail />} />
      <Route path={ROUTE_PROFILE} element={<ProfileIndex />} />
      <Route path={ROUTE_EMPLOYEES} element={<EmployeesIndex />} />
      <Route path={ROUTE_EMPLOYEES_CREATE} element={<EmployeeForm />} />
      <Route path={ROUTE_EMPLOYEES_EDIT} element={<EmployeeForm />} />
      <Route path={ROUTE_EMPLOYEES_DETAIL} element={<EmployeesDetail />} />
      <Route path="*" element={<Page404 />} />
    </RouterRoutes>
  );
};

function App() {
  return <Routes />;
}

export default App;
