import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { PageHeader } from "../../../components/page-header";
import { PhoneField, SelectField } from "../../../components/fields";
import { Card } from "../../../components/card";
import { employeeRoleList } from "../../mock-data";
import { useEmployeeForm } from "../../../core/hooks/useEmployeeForm";
import { useNavigate, useParams } from "react-router-dom";
import { useInviteEmployeeMutation } from "../../../core/store";
import get from "lodash/get";
import { ROUTE_EMPLOYEES } from "../../../core/constants";
import { useSnackbar } from "../../../core/hooks/useSnackbar";

export const EmployeeFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [invite, setInvite] = useState(false);
  const [sendInvite] = useInviteEmployeeMutation();
  const { handleSubmit, getFieldProps, values, errors } = useEmployeeForm({
    id,
    onSuccess: (res) => {
      const employeeId = get(res, "id");
      const fullName = get(res, "fullName");
      if (employeeId && invite) {
        sendInvite({ id: employeeId })
          .unwrap()
          .then(() => {
            navigate(ROUTE_EMPLOYEES);
            showMessage(`New employee ${fullName} was created successfully.`);
          });
      } else {
        navigate(ROUTE_EMPLOYEES);
        showMessage(`New employee ${fullName} was created successfully.`);
      }
    },
    onFail: (res) => {
      console.log("onFail", res);
    },
  });

  const handleSave = () => {
    handleSubmit();
  };

  const handleSaveAndInvite = () => {
    setInvite(true);
    handleSubmit();
  };

  const handleCancel = () => {
    navigate(ROUTE_EMPLOYEES);
  };

  return (
    <>
      <PageHeader title="Add new employee" backTitle="Employees">
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleSaveAndInvite}>Save and send invite</Button>
        <Button color="secondary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Employee information
        </Typography>
        <Grid container columnSpacing={1} sx={{ mb: 2 }}>
          <Grid item md={4}>
            <TextField label="Name" {...getFieldProps("name")} />
          </Grid>
          <Grid item md={4}>
            <TextField label="Email address" {...getFieldProps("email")} />
          </Grid>
          <Grid item md={4}>
            <PhoneField label="Phone number" {...getFieldProps("phone")} />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={4}>
            <SelectField
              label="User role"
              items={employeeRoleList}
              {...getFieldProps("role")}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
