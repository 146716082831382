import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "google-button",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow:
        "0px 2px 3px rgba(0, 0, 0, 0.16), 0px 0px 3px rgba(0, 0, 0, 0.08)",
      padding: theme.spacing(1.5),
      width: "100%",
      borderRadius: 8,
      cursor: "pointer",
    },
    icon: {
      marginRight: theme.spacing(1.31),
    },
    text: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(0, 0, 0, 0.54)",
    },
  }),
  options
);

export default useStyles;
