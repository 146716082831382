import React from "react";
import Stack from "@mui/material/Stack";
import { DatePicker, RadioGroup } from "../../components/fields";
import { profileFilterItems } from "../mock-data";
import Grid from "@mui/material/Grid";
import { Record } from "../../components/record";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const EmployeeInfo = ({
  employee,
  employeePrev,
  handlePickerChange,
  handleMonthChange,
}) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <RadioGroup
          items={profileFilterItems}
          defaultValue="month"
          onChange={handleMonthChange}
        />
        <DatePicker onClose={handlePickerChange} />
      </Stack>
      <Grid container spacing={1.5}>
        <Grid item md={4}>
          <Record
            color="warning"
            amount={get(employee, "newClientsCount", 0)}
            prevAmount={get(employeePrev, "newClientsCount", 0)}
            title="New clients"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            color="success"
            amount={get(employee, "matchedClientsCount", 0)}
            prevAmount={get(employeePrev, "matchedClientsCount", 0)}
            title="MATCHED clients"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            color="error"
            amount={get(employee, "rejectedClientsCount", 0)}
            prevAmount={get(employeePrev, "rejectedClientsCount", 0)}
            title="REJECTED Clients"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            color="warning"
            amount={get(employee, "createdGroupsCount", 0)}
            prevAmount={get(employeePrev, "createdGroupsCount", 0)}
            title="CREATED groups"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            color="success"
            amount={get(employee, "matchedGroupsCount", 0)}
            prevAmount={get(employeePrev, "matchedGroupsCount", 0)}
            title="MATCHED groups"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            color="error"
            amount={get(employee, "rejectedGroupsCount", 0)}
            prevAmount={get(employeePrev, "rejectedGroupsCount", 0)}
            title="REJECTED groups"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 1.5, mb: 3, color: "common.grey" }}>
        <Typography variant="body3">
          * % - Changes vs previous period
        </Typography>
      </Box>
    </>
  );
};
