import React, { useState } from "react";
import { Box, Button, Stack, TextField, Popover } from "@mui/material";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import { DateRange } from "@mui/lab";
import CalendarPicker from "@mui/lab/CalendarPicker";
import { RadioGroup } from "../radio-group";
import { IconCalendar } from "../../icons";
import useStyles from "./styles";

export const radioItems = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Period",
    value: "period",
  },
];

interface DatePickerProps {
  onClose?: (value: any) => void;
}

export const DatePicker = ({ onClose }: DatePickerProps) => {
  const [type, setType] = useState("period");
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const [values, setValues] = useState<DateRange<Date>>([null, null]);
  const [value, setValue] = useState<Date>(new Date());

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose(type === "period" ? values : value);
    setValue(null);
    setValues([null, null]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();

  return (
    <>
      <Button
        size="small"
        color="third"
        endIcon={<IconCalendar />}
        onClick={handleClick}
      >
        Select period
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={10}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box pt={3} px={3.75}>
          <Stack alignItems="center" mb={1}>
            <RadioGroup
              items={radioItems}
              defaultValue={type}
              onChange={setType}
            />
          </Stack>
          <Box className={classes.calendar}>
            {type === "period" ? (
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={values}
                onChange={setValues}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            ) : (
              <CalendarPicker date={value} onChange={setValue} />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
