import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "date-picker",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    calendar: {
      "&>div>div>div>div:not(:last-of-type)": {
        borderColor: "transparent",
      },
      "& .MuiButtonBase-root": {
        fontSize: 11,
        fontWeight: 700,

        "&:hover, &:focus": {
          borderColor: "#8275FF",
          backgroundColor: "transparent",
        },
      },

      "& .MuiTypography-subtitle1": {
        fontSize: 11,
        fontWeight: 700,
        color: "#C7C2FF",
      },

      "& .MuiTypography-caption": {
        fontSize: 11,
        color: "#C7C2FF",
        fontWeight: 700,
      },

      "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
        backgroundColor: "#EAE8FF",

        "&  button": {
          color: "#8275FF",
        },
      },

      "& .Mui-selected": {
        backgroundColor: "#8275FF",
        color: "#fff!important",

        "&:hover, &:focus": {
          backgroundColor: "#8275FF!important",
          color: "#fff!important",
        },
      },
    },
  }),
  options
);

export default useStyles;
