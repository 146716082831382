import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { API_USER_ME, API_USER_RESET_PASSWORD } from "../endpoint.constants";
import { EmployeeDto } from "../../dto";
import { plainToClass } from "class-transformer";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    me: builder.query<
      EmployeeDto,
      { statsFromDate?: string; statsToDate?: string }
    >({
      query: (params) => ({
        url: API_USER_ME,
        method: "GET",
        params,
      }),
      transformResponse: (response) => plainToClass(EmployeeDto, response),
    }),
    profile: builder.mutation<
      EmployeeDto,
      { statsFromDate?: string; statsToDate?: string }
    >({
      query: (params) => ({
        url: API_USER_ME,
        method: "GET",
        params,
      }),
      transformResponse: (response) => plainToClass(EmployeeDto, response),
    }),
    profileResetPassword: builder.mutation<EmployeeDto, { password: string }>({
      query: (body) => ({
        url: API_USER_RESET_PASSWORD,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useMeQuery,
  useProfileMutation,
  useProfileResetPasswordMutation,
} = userApi;
