import React, { ComponentType, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { matchingColumns } from "../../matching/resource";
import { TableView } from "../../../components/table-view";
import get from "lodash/get";
import {
  useArchiveGroupMutation,
  useSetClientInProgressMutation,
} from "../../../core/store";
import { useSnackbar } from "../../../core/hooks/useSnackbar";

interface Props extends DialogProps {
  group: any;
  onSuccess: () => void;
}

export const ArchiveDialog: ComponentType<Props> = ({
  group,
  popDialog,
  onSuccess,
}) => {
  const { showMessage } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [archive] = useArchiveGroupMutation();
  const [setClientInProgress] = useSetClientInProgressMutation();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const contacts = get(group, "contacts.items");

  const handleArchive = () => {
    setIsSubmitting(true);
    archive({
      id: get(group, "listId"),
    })
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        Promise.all(
          selectedIds.map((id) =>
            setClientInProgress({
              id,
            }).unwrap()
          )
        ).then(() => {
          console.log("done");
        });
        popDialog();
        onSuccess();
      })
      .catch(() => {
        showMessage("Failed to archive group");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog open onClose={popDialog} maxWidth="lg">
      <DialogTitle>Archive group</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={3}>
          Select clients who will be returned to “In progress” status. All the
          rest will be archived.
        </Typography>
        <TableView
          striped
          rowSelection={{
            selectedRowKeys: [],
            onChange: setSelectedIds,
          }}
          dataSource={contacts}
          columns={matchingColumns}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleArchive}>
          Archive group
        </Button>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
