import { ComponentType, ReactNode } from "react";
import clsx from "clsx";
import { Box, Typography, Stack } from "@mui/material";
import useStyles from "./styles";
import { CircularProgress } from "../circular-progress";
import { BoxProps } from "@mui/material/Box";

interface Props extends BoxProps {
  icon: ReactNode;
  description: string;
  action?: ReactNode;
  loading?: boolean;
}

export const EmptyState: ComponentType<Props> = ({
  icon,
  action,
  description,
  loading = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box
      {...props}
      className={clsx(classes.root, {
        [classes.loading]: loading,
      })}
    >
      {loading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          spacing={2.5}
          minHeight={300}
        >
          <CircularProgress />
          <Typography variant="body1" className={classes.loadingText}>
            Loading data ...
          </Typography>
        </Stack>
      ) : (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <div className={classes.icon}>{icon}</div>
          <Typography variant="body2">{description}</Typography>
          <div className={classes.action}>{action}</div>
        </Stack>
      )}
    </Box>
  );
};
