import Box from "@mui/material/Box";
import truncate from "lodash/truncate";
import { format } from "date-fns";
import { url } from "../../core/utils/route.utils";
import { ROUTE_GROUP_DETAIL } from "../../core/constants";
import { LinkButton } from "../../components/buttons";

export const groupColumns = [
  {
    title: "ID",
    dataIndex: "listId",
    key: "listId",
    cellProps: {
      width: "1%",
    },
  },
  {
    title: "Group name",
    dataIndex: "name",
    key: "name",
    render: (group: any, record: any) => {
      return (
        <LinkButton to={url(ROUTE_GROUP_DETAIL, { id: record.listId })}>
          {group}
        </LinkButton>
      );
    },
  },
  {
    title: "Clients",
    dataIndex: "clientsCount",
    key: "clientsCount",
    sortable: false,
    cellProps: {
      width: "1%",
    },
  },
  // {
  //   title: "Location",
  //   dataIndex: "city",
  //   key: "city",
  //   sortable: false,
  //   cellProps: {
  //     width: 150,
  //   },
  //   render: (record: any) => {
  //     return record && record;
  //   },
  // },

  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    cellProps: {
      sx: {
        width: "316px",
      },
    },
    render: (record: any) => {
      return truncate(record, { length: 55 });
    },
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: false,
    cellProps: {
      width: "11%",
    },
    render: (record: any) => {
      return (
        <Box sx={{ whiteSpace: "nowrap" }}>
          {record && format(new Date(record), "dd/MM/yyyy p")}
        </Box>
      );
    },
  },
];
