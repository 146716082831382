import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Card } from "../../components/card";
import { PageHeader } from "../../components/page-header";
import { SearchField } from "../../components/fields";
import { TableView } from "../../components/table-view";
import { groupColumns } from "./resource";
import { EmptyState } from "../../components/empty-state";
import { IconFolder } from "../../components/icons";
import { useDialog } from "../../core/hooks/useDialog";
import { CreateNewGroupDialog } from "./CreateNewGroupDialog";
import { FilterableTabs } from "../../components/tabs/FilterableTabs";
import { useFetchAllGroupMutation } from "../../core/store";
import get from "lodash/get";

const TAB_ITEMS = [
  {
    key: "in_progress",
    label: "In progress",
  },
  {
    key: "on_review",
    label: "On review",
  },
  {
    key: "matched",
    label: "Matched",
  },
  {
    key: "archived",
    label: "Archived",
  },
];

export const GroupsContainer = () => {
  const [getGroups, { data, isLoading }] = useFetchAllGroupMutation();
  const [openCreateNewGroupDialog] = useDialog(CreateNewGroupDialog);
  const [payload, setPayload] = useState<Record<string, any>>({
    status: "in_progress",
    limit: 20,
    page: 1,
  });

  useEffect(() => {
    getGroups(payload);
  }, [payload]);

  const handleChangeTab = (key: number) => {
    const selectedTab = TAB_ITEMS[key];
    setPayload((prev) => ({ ...prev, status: selectedTab.key, page: 1 }));
  };

  const handleChangeSearch = (search: string) => {
    setPayload((prev) => ({ ...prev, search }));
  };

  const handleCreateNewGroup = () => {
    openCreateNewGroupDialog({
      onSuccess: () => {
        getGroups(payload);
      },
    });
  };

  const handlePageChange = (page) => {
    setPayload((prev) => ({ ...prev, page }));
  };

  const items = get(data, "items", []);
  const pagesCount = get(data, "pagesCount", 0);
  const showEmptyState =
    isEmpty(items) && (pagesCount === 0 || pagesCount === 1);

  return (
    <>
      <PageHeader title="Groups">
        <Button onClick={handleCreateNewGroup}>Create new group</Button>
      </PageHeader>
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <FilterableTabs items={TAB_ITEMS} onChange={handleChangeTab}>
          <SearchField
            placeholder="Search by ID client, Company name, Group name"
            sx={{ mb: 4 }}
            onChange={handleChangeSearch}
          />
          {showEmptyState ? (
            <EmptyState
              icon={<IconFolder />}
              loading={isLoading}
              description="0 groups found"
            />
          ) : (
            <TableView
              striped
              pagination={{
                page: payload.page,
                total: get(data, "totalCount", 0),
                perPage: get(data, "itemsPerPage", 20),
                onChange: handlePageChange,
              }}
              keyExtractor={(list) => list.listId}
              dataSource={items}
              columns={groupColumns}
            />
          )}
        </FilterableTabs>
      </Card>
    </>
  );
};
