import React, { ComponentType, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { SearchFilters } from "../../../components/filters/SearchFilters";
import { LinkButton } from "../../../components/buttons";
import { url } from "../../../core/utils/route.utils";
import { ROUTE_GROUP_DETAIL } from "../../../core/constants";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import get from "lodash/get";
import {
  filtersToValue,
  matchingFormToGroupValues,
  normalizeGroupFilters,
} from "../../../core/utils/hubspot.util";
import { format } from "date-fns";
import { useCreateGroupMutation } from "../../../core/store";

interface Props extends DialogProps {
  group: any;
}

export const DuplicateWithNewFiltersDialog: ComponentType<Props> = ({
  group,
  popDialog,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createGroup] = useCreateGroupMutation();
  const [filter, setFilter] = useState({});
  const [exception, setException] = useState({});
  const { showMessage } = useSnackbar();

  useEffect(() => {
    if (group) {
      const groupFilters = get(group, "groupFilters");
      const filters = normalizeGroupFilters(groupFilters);

      setFilter({
        location: filtersToValue(filters.locationEq),
        businessTypes: filtersToValue(filters.businessTypeEq),
        areaPermises: filtersToValue(filters.areaOfPremisesEq),
        ageGroup: filtersToValue(filters.ageGroupEq),
        gender: filtersToValue(filters.genderEq),
        budget: filtersToValue(filters.budgetEq),
        pricing: filtersToValue(filters.pricingEq),
        portraing: filtersToValue(filters.portrayEq),
      });

      setException({
        noLocation: filtersToValue(filters.locationNeq),
        noBusinessTypes: filtersToValue(filters.businessTypeNeq),
        noAreaPermises: filtersToValue(filters.areaOfPremisesNeq),
        noAgeGroup: filtersToValue(filters.ageGroupNeq),
        noGender: filtersToValue(filters.genderNeq),
        noBudget: filtersToValue(filters.budgetNeq),
        noPricing: filtersToValue(filters.pricingNeq),
        noPortraing: filtersToValue(filters.portrayNeq),
      });
    }
  }, [group]);

  const handleFilter = (name) => (value) => {
    setFilter({ ...filter, [name]: value });
  };

  const handleException = (name) => (value) => {
    setException({ ...exception, [name]: value });
  };

  const handleClearException = () => {
    setException({});
  };

  const handleSave = () => {
    const date = format(new Date(), "yyyy-MM-dd HH:mm:ss");

    const payload = matchingFormToGroupValues({
      filter,
      exception,
      name: get(group, "name") + " - " + date,
    });
    setIsSubmitting(true);
    createGroup(payload)
      .unwrap()
      .then((res) => {
        const name = get(res, "name");
        const id = get(res, "listId");
        showMessage(
          <>
            The group {name} was successfully duplicated.{" "}
            <LinkButton to={url(ROUTE_GROUP_DETAIL, { id })}>
              Open duplicate
            </LinkButton>
          </>
        );

        popDialog();
      })
      .catch(() => {
        showMessage("Error duplicating group");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog open onClose={popDialog} maxWidth="lg">
      <DialogTitle>Edit filters to duplicate</DialogTitle>
      <DialogContent>
        <SearchFilters
          {...{
            filter,
            exception,
            handleFilter,
            handleException,
            handleClearException,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} disabled={isSubmitting}>
          Save and duplicate
        </Button>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
