import { alpha } from "@mui/material";

const black = "#000";
const white = "#fff";
const darkBlue = "#211541";
const violet = "#8275FF";
const pink = "#FFD0D9";
const grey = "#585F84";
const mediumLightBlue = "#C7C2FF";
const lightBlue = "#EAE8FF";
const lightGrey = "#F4F6FF";
const yellow = "#F2C94C";
const orange = "#F2994A";
const red = "#AC0202";

export const lightPalette = {
  type: "light",
  background: {
    default: lightGrey,
    light: "#F6F6FC",
    info: "#EAFFFF",
    warning: "#FFFAED",
    success: "#EDFFF7",
    error: "#FFEDED",
  },
  common: {
    black,
    white,
    darkBlue,
    violet,
    pink,
    grey,
    mediumLightBlue,
    lightBlue,
    lightGrey,
    yellow,
    orange,
    red,
  },
  text: {
    primary: darkBlue,
    secondary: "#222222",
  },
  primary: {
    contrastText: white,
    dark: darkBlue,
    light: "#36285B",
    main: darkBlue,
  },
  secondary: {
    contrastText: white,
    dark: violet,
    light: "#A59CFF",
    main: violet,
  },
  third: {
    contrastText: darkBlue,
    dark: lightGrey,
    light: lightGrey,
    main: lightGrey,
  },
  info: {
    contrastText: darkBlue,
    dark: "#EAFFFF",
    light: "#EAFFFF",
    main: "#EAFFFF",
  },
  success: {
    contrastText: "#131730",
    dark: "#4AF2A1",
    light: "#4AF2A1",
    main: "#4AF2A1",
  },
  warning: {
    contrastText: "#131730",
    dark: "#C27A3B",
    light: "#F5AD6E",
    main: "#F2994A",
  },
  error: {
    contrastText: white,
    dark: red,
    light: "#FE8E8E",
    main: "#FE8E8E",
  },
  gradient: {
    main: "linear-gradient(90deg, #667EEA 0%, #764BA2 100%);",
    contrastText: "#FFFFFF",
  },
  a11y: {
    light: "transparent",
    main: "#FFFFFF",
    dark: "#222222",
    contrastText: "transparent",
  },
  action: {
    disabledBackground: "#F6F6FC",
    disabled: "#BEC1D8",
  },
  divider: "#F6F6FC",
  grey: {
    50: alpha("#222222", 0.05),
    100: alpha("#222222", 0.1),
    200: alpha("#222222", 0.2),
    300: alpha("#222222", 0.3),
    400: alpha("#222222", 0.4),
    500: alpha("#222222", 0.5),
    600: alpha("#222222", 0.6),
    700: alpha("#222222", 0.7),
    800: alpha("#222222", 0.8),
    900: alpha("#222222", 0.9),
  },
  light: {
    50: alpha("#FFFFFF", 0.05),
    100: alpha("#FFFFFF", 0.1),
    200: alpha("#FFFFFF", 0.2),
    300: alpha("#FFFFFF", 0.3),
    400: alpha("#FFFFFF", 0.4),
    500: alpha("#FFFFFF", 0.5),
    600: alpha("#FFFFFF", 0.6),
    700: alpha("#FFFFFF", 0.7),
    800: alpha("#FFFFFF", 0.8),
    900: alpha("#FFFFFF", 0.9),
  },
};
