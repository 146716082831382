import { Expose, Transform } from "class-transformer";
import { get } from "lodash";
import { Roles } from "../enums/roles.enum";

export class EmployeeDto {
  @Expose()
  id: number;

  @Expose()
  email: string;

  @Expose()
  phoneNumber: string;

  @Expose()
  fullName: string;

  @Expose()
  role: string;

  @Expose()
  newClientsCount: number;

  @Expose()
  matchedClientsCount: number;

  @Expose()
  rejectedClientsCount: number;

  @Expose()
  createdGroupsCount: number;

  @Expose()
  matchedGroupsCount: number;

  @Expose()
  rejectedGroupsCount: number;

  @Expose()
  lastActivity: string;

  @Expose()
  @Transform((_, { role }) => get(Roles, role))
  roleLabel: string;
}
