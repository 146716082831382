import { useLoginMutation } from "../store";
import { FormikValues, useFormik } from "formik";
import * as yup from "yup";
import get from "lodash/get";
import { AUTH_TOKEN } from "../constants";

interface UseLoginProps {
  onSuccess: () => void;
}

export const useLogin = ({ onSuccess }: UseLoginProps) => {
  const [login, { isLoading }] = useLoginMutation();

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Please enter valid email.")
        .required("Required field"),
      password: yup.string().required("Required field"),
    }),
    onSubmit: ({ email, password }) => {
      login({ email, password }).unwrap().then(handleSuccess).catch(handleFail);
    },
  });

  const handleSuccess = (res) => {
    localStorage.setItem(AUTH_TOKEN, res.token);
    onSuccess();
  };

  const handleFail = (res) => {
    const errorCode = get(res, "data.errorCode");
    if (errorCode === "incorrect_credentials") {
      setFieldError("password", "Incorrect credentials");
    }
  };

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    isLoading,
  };
};
