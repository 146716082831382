import {
  useCreateGroupMutation,
  useFetchOneGroupMutation,
  useUpdateGroupMutation,
} from "../store";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import get from "lodash/get";
import {
  valueToSetAny,
  valueToEq,
  valueToSetEq,
  valueToNq,
} from "../utils/hubspot.util";
import { map, reduce } from "lodash";
import isEmpty from "lodash/isEmpty";
import { ClientProperties } from "../constants";

interface UseEmployeeFormProps {
  id?: number | string;
  onSuccess?: () => void;
}

export const useGroupForm = ({ id, onSuccess }: UseEmployeeFormProps) => {
  const [createOne] = useCreateGroupMutation();
  const [updateOne] = useUpdateGroupMutation();
  const [getGroupData, { data }] = useFetchOneGroupMutation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    id && getGroupData({ id });
  }, [id]);

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      comments: "",
      filters: {
        state: [],
        business_type: [],
        area_of_premises: [],
        age_group: [],
        gender: [],
        pricing: [],
        budget: [],
        portraying: [],
      },
      exceptions: {
        state: [],
        business_type: [],
        area_of_premises: [],
        age_group: [],
        gender: [],
      },
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const normalizedValues = normalizePayload(values);

      createOne(normalizedValues)
        .unwrap()
        .then((res) => {
          onSuccess();
        })
        .catch((err) => {
          const message = get(err, "data.errorDescription.message", "");
          if (message.includes("LIST_EXISTS")) {
            setErrors({ name: "Group name already exists" });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  const normalizePayload = ({ filters, exceptions, ...values }) => {
    const filterValues = [
      !isEmpty(filters.business_type)
        ? [
            ...map(filters.business_type, (value) =>
              valueToEq(ClientProperties.BUSINESS_TYPE, value)
            ),
          ]
        : [],
      !isEmpty(filters.state)
        ? [
            ...map(filters.state, (value) =>
              valueToEq(
                ClientProperties.CITY,
                [value.city, value.state]
                  .filter(Boolean)
                  .join(", ")
                  .toLowerCase()
              )
            ),
          ]
        : [],
      !isEmpty(filters.area_of_premises)
        ? [
            ...map(filters.area_of_premises, (value) =>
              valueToEq(ClientProperties.AREA, value)
            ),
          ]
        : [],
      !isEmpty(filters.age_group)
        ? [
            ...map(filters.age_group, (value) =>
              valueToEq(ClientProperties.AGE_GROUP, value)
            ),
          ]
        : [],
      !isEmpty(filters.gender)
        ? [
            ...map(filters.gender, (value) =>
              valueToEq(ClientProperties.GENDER, value)
            ),
          ]
        : [],
      !isEmpty(filters.pricing)
        ? [
            ...map(filters.pricing, (value) =>
              valueToEq(ClientProperties.PRICING, value)
            ),
          ]
        : [],
      !isEmpty(filters.budget)
        ? [
            ...map(filters.budget, (value) =>
              valueToEq(ClientProperties.BUDGET, value)
            ),
          ]
        : [],
    ];

    const exceptionsValues = [
      !isEmpty(exceptions.business_type)
        ? [
            ...map(exceptions.business_type, (value) =>
              valueToNq(ClientProperties.BUSINESS_TYPE, value)
            ),
          ]
        : [],
      !isEmpty(exceptions.state)
        ? [
            ...map(exceptions.state, (value) =>
              valueToNq(ClientProperties.CITY, value.city)
            ),
          ]
        : [],
      !isEmpty(exceptions.area_of_premises)
        ? [
            ...map(exceptions.area_of_premises, (value) =>
              valueToNq(ClientProperties.AREA, value)
            ),
          ]
        : [],
      !isEmpty(exceptions.age_group)
        ? [
            ...map(exceptions.age_group, (value) =>
              valueToNq(ClientProperties.AGE, value)
            ),
          ]
        : [],
      !isEmpty(exceptions.gender)
        ? [
            ...map(exceptions.gender, (value) =>
              valueToNq(ClientProperties.GENDER, value)
            ),
          ]
        : [],
    ];

    return {
      ...values,
      dynamic: false,
      city: !isEmpty(filters.state)
        ? map(filters.state, (item) => item.city).join(",")
        : "-",
      filters: [
        ...reduce(
          filterValues,
          (acc, value) => {
            if (!isEmpty(value)) {
              return [...acc, value];
            }
            return acc;
          },
          []
        ),
        ...reduce(
          exceptionsValues,
          (acc, value) => {
            if (!isEmpty(value)) {
              return [...acc, value];
            }
            return acc;
          },
          []
        ),
      ],
    };
  };

  const handleSuccess = (res) => {
    console.log("success", res);
  };
  const handleFail = (res) => {
    console.log("fail", res);
  };

  const getFieldProps = (fieldName) => {
    const error = get(errors, fieldName) && get(touched, fieldName);

    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      value: get(values, fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    isLoading,
  };
};
