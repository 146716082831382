import Box from "@mui/material/Box";
import { format } from "date-fns";
import { LinkButton } from "../../components/buttons";
import { url } from "../../core/utils/route.utils";
import { ROUTE_EMPLOYEES_DETAIL } from "../../core/constants";

export const employeesColumns = [
  {
    title: "Full name",
    dataIndex: "fullName",
    key: "fullName",
    render: (client: any, record: any) => {
      return (
        <LinkButton to={url(ROUTE_EMPLOYEES_DETAIL, { id: record.id })}>
          {client ? client : "N/A"}
        </LinkButton>
      );
    },
  },
  {
    title: "Matched groups",
    dataIndex: "matchedGroupsCount",
    key: "matchedGroupsCount",
  },
  {
    title: "Rejected groups",
    dataIndex: "rejectedGroupsCount",
    key: "rejectedGroupsCount",
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Last activity",
    dataIndex: "lastActivity",
    key: "last_activity",
    sortable: true,
    cellProps: {
      width: "150",
    },
    render: (record: any) => {
      return (
        <Box sx={{ whiteSpace: "nowrap" }}>
          {record ? format(new Date(record), "dd/MM/yyyy p") : "-"}
        </Box>
      );
    },
  },
];
