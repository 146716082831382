import React from "react";
import { Link } from "react-router-dom";
import { Link as MuiLink, LinkProps } from "@mui/material";

export const LinkButton = <C extends React.ElementType>({
  children,
  ...props
}: LinkProps<C, { component?: C }>) => {
  return (
    <MuiLink component={Link} {...props}>
      {children}
    </MuiLink>
  );
};
