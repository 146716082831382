import {
  Card as MuiCard,
  CardContent,
  CardProps,
  Typography,
} from "@mui/material";
import React, { ComponentType } from "react";
import useStyles from "./styles";
import clsx from "clsx";

interface Props extends Omit<CardProps, "variant"> {
  shadow?: boolean;
  title?: any;
  color?: "secondary" | "third";
  variant?: "outlined" | "contained";
  size?: "small" | "medium";
}

export const Card: ComponentType<Props> = ({
  title,
  shadow = false,
  children,
  color,
  variant = "contained",
  size = "medium",
  ...props
}) => {
  const classes = useStyles();
  return (
    <MuiCard
      {...props}
      className={clsx(classes.root, {
        [classes.shadow]: shadow,
        [classes.secondary]: color === "secondary" && variant === "contained",
        [classes.third]: color === "third" && variant === "contained",
        [classes.secondaryOutlined]:
          color === "secondary" && variant === "outlined",
      })}
    >
      <CardContent className={classes.content}>
        {title && (
          <Typography
            variant={size == "small" ? "body2" : "h4"}
            component={size == "small" ? "h5" : "h4"}
            className={clsx(classes.title, {
              [classes.titleSmall]: size == "small",
              [classes.titleMedium]: size == "medium",
            })}
          >
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
    </MuiCard>
  );
};
