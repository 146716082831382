import React, { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DialogProps } from "../../../core/providers/dialog.provider";
import Box from "@mui/material/Box";
import { resource } from "./resource";
import { groupTableData } from "./mock-data";
import { TableView } from "../../../components/table-view";

interface Props extends DialogProps {}

export const MarkAsMatchedDialog: ComponentType<Props> = ({ popDialog }) => {
  return (
    <Dialog open onClose={popDialog} maxWidth="lg">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Mark as Matched</Box>
          <Box>
            <Button color="third" onClick={popDialog}>
              Close
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Select clients’ group
        </Typography>
        <TableView
          striped
          columns={resource.columns}
          dataSource={groupTableData}
          rowSelection={{
            selectedRowKeys: [],
            onChange: (ids) => {
              console.log(ids);
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button>Match all clients in selected group</Button>
        <Button>Match olny this client in selected group</Button>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
