import {
  useInviteInfoMutation,
  useResetPasswordMutation,
  useSignUpMutation,
} from "../store";
import { useFormik } from "formik";
import * as yup from "yup";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { InviteInfoDto } from "../dto/InviteInfoDto";
import { plainToClass } from "class-transformer";

interface UseInviteProps {
  code: string;
  onFailInviteInfo: () => void;
  onSuccess: () => void;
  onFail: () => void;
}

const passwordMessage =
  "Password must contain at least 8 characters, including at least one number, one lowercase and one uppercase";

export const useInvite = ({
  code: inviteCode,
  onFailInviteInfo,
  onSuccess,
  onFail,
}: UseInviteProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inviteInfo, setInviteInfo] = useState<InviteInfoDto>();
  const [signUp] = useSignUpMutation();
  const [getInviteInfo] = useInviteInfoMutation();

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      role: "",
      inviteCode: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8, passwordMessage)
        .test("isValidPass", passwordMessage, (value, context) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          return hasUpperCase && hasLowerCase && hasNumber;
        })
        .required("Password is required"),
      passwordConfirmation: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("password"), null], "Passwords don’t match"),
    }),
    onSubmit: ({ inviteCode, password }) => {
      setIsLoading(true);
      signUp({ inviteCode, password })
        .unwrap()
        .then(handleSuccess)
        .catch(handleFail)
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    setIsLoading(true);
    getInviteInfo({ inviteCode })
      .unwrap()
      .then((res) => {
        setInviteInfo(res);
      })
      .catch((res) => {
        const info = plainToClass(InviteInfoDto, {
          email: "johnsmith@gmail.com",
          role: "Manager",
        });
        setInviteInfo(info);
        setFieldValue("email", "johnsmith@gmail.com");
        setFieldValue("role", "Manager");
        setFieldValue("inviteCode", inviteCode);
        // onFailInviteInfo()
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [inviteCode]);

  const handleSuccess = (res) => {
    console.log("handleSuccess", res);
    onSuccess();
  };

  const handleFail = (res) => {
    onFail();
  };

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
    };
  };

  return {
    isLoading,
    handleSubmit,
    errors,
    values,
    getFieldProps,
    inviteInfo,
  };
};
