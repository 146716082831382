import Typography from "@mui/material/Typography";
import { AccordionRoot } from "./AccordionRoot";
import { AccordionSummary } from "./AccordionSummary";
import { AccordionDetails } from "./AccordionDetails";
import { ComponentType } from "react";
import { AccordionProps } from "@mui/material/Accordion";

interface Props extends AccordionProps {
  title: string;
}
export const Accordion: ComponentType<Props> = ({
  title,
  children,
  ...props
}) => {
  return (
    <AccordionRoot {...props}>
      <AccordionSummary>
        <Typography variant="body2" fontWeight="600">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionRoot>
  );
};
