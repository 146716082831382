import { Card } from "../card";
import Stack from "@mui/material/Stack";
import Box, { BoxProps } from "@mui/material/Box";
import { LabeledRecord } from "../labeled-record";
import { Chip } from "../chip";
import { ComponentType } from "react";
import get from "lodash/get";
import { concat, flatten, groupBy, map, property, reduce } from "lodash";
import filter from "lodash/filter";
import { ClientProperties } from "../../core/constants";
import isEmpty from "lodash/isEmpty";

interface Props extends BoxProps {
  values?: any;
}

export const MatchingDisplayFilters: ComponentType<Props> = ({
  values,
  ...props
}) => {
  const locationEq = get(values, `filter.location`, []);
  const locationNeq = get(values, `exception.noLocation`, []);

  const businessTypeEq = get(values, `filter.businessTypes`, []);
  const businessTypeNeq = get(values, `exception.noBusinessTypes`, []);

  const areaOfPremisesEq = get(values, `filter.areaPermises`, []);
  const areaOfPremisesNeq = get(values, `exception.noAreaPermises`, []);

  const ageGroupEq = get(values, `filter.ageGroup`, []);
  const ageGroupNeq = get(values, `exception.noAgeGroup`, []);

  const genderEq = get(values, `filter.gender`, []);
  const genderNeq = get(values, `exception.noGender`, []);

  const exceptionIsEmpty =
    isEmpty(locationNeq) &&
    isEmpty(businessTypeNeq) &&
    isEmpty(areaOfPremisesNeq) &&
    isEmpty(ageGroupNeq) &&
    isEmpty(genderNeq);

  const filterIsEmpty =
    isEmpty(locationEq) &&
    isEmpty(businessTypeEq) &&
    isEmpty(areaOfPremisesEq) &&
    isEmpty(ageGroupEq) &&
    isEmpty(genderEq);

  return (
    <Box {...props}>
      <Card
        title="Filters"
        color="secondary"
        variant="outlined"
        size="small"
        sx={{ mb: 1 }}
      >
        {!filterIsEmpty ? (
          <Stack direction="row" spacing={1.5}>
            {!isEmpty(locationEq) && (
              <LabeledRecord label="Location">
                {map(locationEq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(businessTypeEq) && (
              <LabeledRecord label="Business type">
                {map(businessTypeEq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(areaOfPremisesEq) && (
              <LabeledRecord label="Area of premises (sq ft)">
                {map(areaOfPremisesEq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(ageGroupEq) && (
              <LabeledRecord label="Age group">
                {map(ageGroupEq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(genderEq) && (
              <LabeledRecord label="Gender">
                {map(genderEq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
          </Stack>
        ) : (
          <LabeledRecord label="No filters" />
        )}
      </Card>
      <Card title="Exceptions" size="small" color="secondary">
        {!exceptionIsEmpty ? (
          <Stack direction="row" spacing={1.5}>
            {!isEmpty(locationNeq) && (
              <LabeledRecord label="Location">
                {map(locationNeq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(businessTypeNeq) && (
              <LabeledRecord label="Business type">
                {map(businessTypeNeq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(areaOfPremisesNeq) && (
              <LabeledRecord label="Area of premises (sq ft)">
                {map(areaOfPremisesNeq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(ageGroupNeq) && (
              <LabeledRecord label="Age group">
                {map(ageGroupNeq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
            {!isEmpty(genderNeq) && (
              <LabeledRecord label="Gender">
                {map(genderNeq, (x) => (
                  <Chip label={x} />
                ))}
              </LabeledRecord>
            )}
          </Stack>
        ) : (
          <LabeledRecord label="No exceptions" />
        )}
      </Card>
    </Box>
  );
};
