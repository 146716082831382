import { endOfDay, format, startOfDay, subMonths, subYears } from "date-fns";

export const getRangeByName = (name = "month"): { from: Date; to: Date } => {
  let from = new Date();
  let to = new Date();

  switch (name) {
    case "month":
      from = subMonths(to, 1);
      break;
    case "3_month":
      from = subMonths(to, 3);
      break;
    case "6_month":
      from = subMonths(to, 6);
      break;
    case "total":
      from = subYears(to, 5);
      break;
  }

  return { from, to };
};

export const fullDayRange = (day: Date) => {
  return {
    from: startOfDay(day),
    to: endOfDay(day),
  };
};

export const toBackFormat = (date: Date) => {
  return format(date, "yyyy-MM-dd HH:mm:ss");
};
