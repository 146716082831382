import { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Space } from "../../../components/space";
import { IconArrowLeft } from "../../../components/icons";
import useStyles from "./styles";
import { ROUTE_SIGN_IN } from "../../../core/constants";
import { LinkButton } from "../../../components/buttons";
import { AuthStatusCard } from "../../../components/card/AuthStatusCard";
import { AuthCard } from "../../../components/card/AuthCard";
import { usePasswordRecovery } from "../../../core/hooks/usePasswordRecovery";

export const PasswordRecoveryContainer = () => {
  const [success, setSuccess] = useState(false);
  const { handleSubmit, getFieldProps, isLoading } = usePasswordRecovery({
    onSuccess: () => {
      setSuccess(true);
    },
    onFail: () => {},
  });

  const classes = useStyles();

  if (success) {
    return (
      <AuthStatusCard
        sx={{ minWidth: 380, maxWidth: 380 }}
        success={success}
        title="Recovery email was sent to your address"
        subtitle="Check you email agent and find our email with recovery information."
        action={<Button onClick={() => handleSubmit()}>Resend</Button>}
        footer={
          <Box textAlign="center" mt={3}>
            <LinkButton to={ROUTE_SIGN_IN} color="common.grey" variant="body2">
              Back to login
            </LinkButton>
          </Box>
        }
      />
    );
  }

  return (
    <Box sx={{ minWidth: 380, maxWidth: 380 }}>
      <Box className={classes.header} mb={3}>
        <IconButton
          className={classes.backButton}
          size="small"
          component={Link}
          to={ROUTE_SIGN_IN}
        >
          <IconArrowLeft />
        </IconButton>
        <Typography variant="h3">Password recovery</Typography>
      </Box>
      <AuthCard>
        <Space spacing={2} direction="column">
          <Typography variant="body2">
            Please enter your email address below and we will send recovery
            information.
          </Typography>
          <TextField label="Email" {...getFieldProps("email")} />
        </Space>
        <Stack direction="row" justifyContent="flex-end" marginTop={2}>
          <Button onClick={() => handleSubmit()} disabled={isLoading}>
            Send
          </Button>
        </Stack>
      </AuthCard>
    </Box>
  );
};
