import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Card } from "../../../components/card";
import { Space } from "../../../components/space";
import { PasswordField } from "../../../components/fields";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AuthStatusCard } from "../../../components/card/AuthStatusCard";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ROUTE_SIGN_IN, ROUTE_SIGN_UP } from "../../../core/constants";
import { LinkButton } from "../../../components/buttons";
import { useInvite } from "../../../core/hooks/useInvite";

export const InviteContainer = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(null);
  const { code } = useParams();
  const { handleSubmit, getFieldProps, isLoading } = useInvite({
    code,
    onFailInviteInfo: () => {
      console.log("onFailInviteInfo");
      // navigate
    },
    onSuccess: () => {
      console.log("onSuccess");
    },
    onFail: () => {
      console.log("onFail");
    },
  });

  const handleRestart = () => {};

  const handleGetStart = () => {};

  if (success !== null) {
    return (
      <AuthStatusCard
        sx={{ minWidth: 380, maxWidth: 380 }}
        success={success}
        title={
          success ? (
            <>
              Welcome Sharefront
              <br />
              Matching System
            </>
          ) : (
            <>
              Opps,
              <br />
              Something wrong!
              <br />
              Profile wasn’t created.
            </>
          )
        }
        subtitle={
          success ? "You successfully created an account." : "Try again later."
        }
        action={
          success ? (
            <Button onClick={handleGetStart}>Get started</Button>
          ) : (
            <Button component={Link} to={ROUTE_SIGN_UP}>
              Sign up
            </Button>
          )
        }
        footer={
          success ? null : (
            <Typography
              variant="body2"
              color="common.grey"
              textAlign="center"
              marginTop={2}
            >
              I have an account. {""}
              <LinkButton to={ROUTE_SIGN_IN} color="common.grey">
                Login
              </LinkButton>
            </Typography>
          )
        }
      />
    );
  }

  return (
    <Box sx={{ minWidth: 380, maxWidth: 380 }}>
      <Typography variant="h1" textAlign="center" mb={1}>
        Welcome aboard!
      </Typography>
      <Typography variant="body2" textAlign="center" mb={3}>
        Complete filling your profile
      </Typography>
      <Card sx={{ p: 1, boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)" }}>
        <Space spacing={2} direction="column">
          <Space spacing={1} direction="column">
            <TextField label="Email" disabled {...getFieldProps("email")} />
            <TextField label="Role" disabled {...getFieldProps("role")} />
            <PasswordField
              label="New password"
              {...getFieldProps("password")}
            />
            <PasswordField
              label="Confirm new password"
              {...getFieldProps("passwordConfirmation")}
            />
          </Space>
        </Space>
        <Box marginTop={2}>
          <Button onClick={() => handleSubmit()} fullWidth disabled={isLoading}>
            Let’s start
          </Button>
        </Box>
      </Card>
    </Box>
  );
};
