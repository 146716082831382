import React, { ComponentType } from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box, { BoxProps } from "@mui/material/Box";
import { get, map } from "lodash";
import { Card } from "../card";
import { SelectField } from "../fields";
import {
  ageGroupList,
  areaOfPremisesList,
  budgetList,
  businessTypeList,
  genderList,
  locationList,
  portrayingList,
  pricingList,
  statusList,
} from "../../features/mock-data";
import { LocationField } from "../fields/location-field";
import isEmpty from "lodash/isEmpty";

interface Props extends BoxProps {
  filter: Record<string, any>;
  exception: Record<string, any>;
  handleFilter: (name: string) => (value) => void;
  handleException: (name: string) => (value) => void;
  handleClearException: () => void;
}

export const SearchFilters: ComponentType<Props> = ({
  filter,
  exception,
  handleFilter,
  handleException,
  handleClearException,
  ...props
}) => {
  return (
    <Box {...props}>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item lg={3} md={6} sm={12}>
          <SelectField
            multiple
            placeholder="Select"
            label="Request status"
            items={statusList}
            value={get(filter, "statuses", [])}
            onChange={handleFilter("statuses")}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12}>
          <LocationField
            multiple
            label="Location"
            value={get(filter, "location", [])}
            onChange={(value) => {
              if (value && !isEmpty(value)) {
                handleFilter("location")(
                  map(value, (x) =>
                    [x.city, x.state].filter(Boolean).join(", ").toLowerCase()
                  )
                );
              } else {
                handleFilter("location")([]);
              }
            }}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12}>
          <SelectField
            group
            multiple
            placeholder="Select"
            label="Business type"
            items={businessTypeList}
            value={get(filter, "businessTypes", [])}
            onChange={handleFilter("businessTypes")}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12}>
          <SelectField
            multiple
            placeholder="Select"
            label="Area of premises"
            items={areaOfPremisesList}
            value={get(filter, "areaPermises", [])}
            onChange={handleFilter("areaPermises")}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} sx={{ mb: 2 }}>
        <Grid
          item
          lg={4.6}
          md={12}
          sx={{ borderRight: "1px solid #EAE8FF", paddingRight: 2 }}
        >
          <Grid container spacing={1}>
            <Grid item lg={6} md={6}>
              <SelectField
                multiple
                placeholder="Select"
                label="Age group"
                items={ageGroupList}
                value={get(filter, "ageGroup", [])}
                onChange={handleFilter("ageGroup")}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <SelectField
                multiple
                placeholder="Select"
                label="Gender"
                items={genderList}
                value={get(filter, "gender", [])}
                onChange={handleFilter("gender")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={7.4} md={12}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={12}>
              <SelectField
                multiple
                placeholder="Select"
                label="Budget"
                items={budgetList}
                value={get(filter, "budget", [])}
                onChange={handleFilter("budget")}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <SelectField
                multiple
                placeholder="Select"
                label="Pricing"
                items={pricingList}
                value={get(filter, "pricing", [])}
                onChange={handleFilter("pricing")}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <SelectField
                multiple
                placeholder="Select"
                label="Portraying"
                items={portrayingList}
                value={get(filter, "portraing", [])}
                onChange={handleFilter("portraing")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card
        title={
          <>
            Exceptions
            <Link variant="body2" sx={{ ml: 2 }} onClick={handleClearException}>
              Clear
            </Link>
          </>
        }
        color="third"
      >
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item lg={3} md={6} sm={12}>
            <SelectField
              multiple
              placeholder="Select"
              label="Request status"
              items={statusList}
              value={get(exception, "noStatuses", [])}
              onChange={handleException("noStatuses")}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <SelectField
              multiple
              placeholder="Select"
              label="Age group"
              items={ageGroupList}
              value={get(exception, "noAgeGroup", [])}
              onChange={handleException("noAgeGroup")}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <SelectField
              multiple
              placeholder="Select"
              label="Portraying"
              items={portrayingList}
              value={get(exception, "noPortraing", [])}
              onChange={handleException("noPortraing")}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <SelectField
              multiple
              placeholder="Select"
              label="Gender"
              items={genderList}
              value={get(exception, "noGender", [])}
              onChange={handleException("noGender")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} sm={12}>
            <LocationField
              multiple
              label="Location"
              value={get(filter, "location", [])}
              onChange={(value) => {
                if (value && !isEmpty(value)) {
                  handleFilter("noLocation")(
                    map(value, (x) => x.city.toLowerCase())
                  );
                } else {
                  handleFilter("noLocation")([]);
                }
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <SelectField
              group
              multiple
              placeholder="Select"
              label="Business type"
              items={businessTypeList}
              value={get(exception, "noBusinessTypes", [])}
              onChange={handleException("noBusinessTypes")}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <SelectField
              multiple
              placeholder="Select"
              label="Pricing"
              items={pricingList}
              value={get(exception, "noPricing", [])}
              onChange={handleException("noPricing")}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
