import { Components } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { typography } from "./typography";

export const themeComponents = (palette: any): Components => {
  const theme = createTheme();

  const { darkBlue, grey, mediumLightBlue, violet, lightGrey, red } =
    palette.common;

  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minHeight: "100vh",
          height: "100%",
        },
        body: {
          minHeight: "100vh",
          height: "100%",
          margin: 0,
        },
        "#root": {
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiContainer: {},
    MuiCardContent: {},
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          "&.MuiButton-outlined.Mui-disabled": {
            border: "1px solid #EAE8FF",
            color: "#C7C2FF",
          },
          "&.MuiButton-contained.Mui-disabled": {
            background: "#F4F6FF",
            color: "#C7C2FF",
          },
          "& .MuiButton-startIcon": {
            marginRight: theme.spacing(0.5),
          },
        },
        sizeLarge: {
          padding: theme.spacing(2, 2),
          fontSize: 16,
          lineHeight: "24px",
        },
        sizeMedium: {
          padding: theme.spacing(1, 2),
        },
        sizeSmall: {
          padding: theme.spacing(0.5, 2),
          fontSize: 12,
          lineHeight: "24px",
        },
        outlined: {
          color: darkBlue,
          backgroundColor: "#fff",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 1,
          backgroundColor: violet,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
          color: grey,
          padding: theme.spacing(1.5),
        },
      },
    },
    MuiPaper: {
      // styleOverrides: {
      //   root: {
      //     backgroundColor: "#fff",
      //     boxShadow: "none",
      //     border: "1px solid #E7EAF9",
      //
      //     "& .MuiCardContent-root": {
      //       padding: theme.spacing(4),
      //     },
      //   },
      // },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#2E3A59",
          "&.MuiInputAdornment-positionEnd": {
            marginRight: 12,
          },
          "&.MuiInputAdornment-positionStart": {
            marginLeft: 8,
            marginRight: 0,
            marginTop: -2,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontSize: 14,
            lineHeight: "20px",
            fontWeight: 600,
            borderBottom: `1px solid ${violet}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.25, 1),
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& svg": {
            borderRadius: 4,
            fontSize: 20,
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#EAE8FF",
          backgroundColor: "#EAE8FF",
        },
      },
    },

    MuiPagination: {
      styleOverrides: {
        root: {
          display: "inline-flex",
          padding: theme.spacing(0.5),
          backgroundColor: lightGrey,
          borderRadius: 12,
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          margin: 0,
          marginRight: 2,
          color: grey,
          "&.Mui-selected, &:hover, &.Mui-selected:hover": {
            backgroundColor: "#fff",
            color: darkBlue,
            boxShadow: "0px 1px 3px rgba(30, 42, 75, 0.08)",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        // helperText: " ",
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            position: "static",
            marginBottom: 2,
            transform: "none",
            width: "100%",
            maxWidth: "100%",
            color: darkBlue,
            fontSize: 12,
            fontWeight: 500,
            lineHeight: "24px",

            "&.Mui-error": {
              color: darkBlue,
            },
            "&.Mui-disabled": {
              color: darkBlue,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          width: 20,
          height: 20,
          transform: "none",
          marginTop: 1,
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },

      styleOverrides: {
        root: {
          ...typography.body2,
          backgroundColor: "#fff",
          padding: 0,

          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: mediumLightBlue,
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: violet,
            boxShadow: "0px 0px 0px 3px rgba(42, 0, 255, 0.1)",
          },

          "&:hover.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
          },

          "&.Mui-disabled": {
            backgroundColor: lightGrey,
            color: grey,

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: mediumLightBlue,
            },
          },
        },
        input: {
          height: "auto",
          padding: theme.spacing(1),
          "&::placeholder": {
            color: grey,
            opacity: 1,
          },

          "&.MuiSelect-select": {
            minHeight: "auto",
          },

          "&.Mui-disabled": {
            textFillColor: grey,
            color: grey,
          },
        },
        notchedOutline: {
          borderColor: mediumLightBlue,
          top: 0,
          "& > *": {
            display: "none !important",
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grey,
          marginLeft: 0,
          marginTop: 2,
          ...typography.body3,
          fontWeight: 500,

          "&.Mui-error": {
            marginTop: 8,
            marginRight: 0,
            backgroundColor: "rgba(237, 43, 0, 0.06)",
            padding: 8,
            borderRadius: 8,
            fontSize: 12,
            lineHeight: "16px",
            color: red,
            letterSpacing: "-0.02em",
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {},
        filledError: {
          borderRadius: 8,
          padding: theme.spacing(1),
          fontSize: 12,
          lineHeight: "16px",
          backgroundColor: "rgba(237, 43, 0, 0.06)",
          color: red,

          "& .MuiAlert-message": {
            padding: 0,
          },
        },
      },
    },

    MuiChip: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 500,
          color: darkBlue,

          "&.MuiChip-colorDefault": {
            backgroundColor: lightGrey,
          },
        },
      },
    },

    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "sm",
      },
      styleOverrides: {
        paperWidthSm: {
          maxWidth: "420px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...typography.h3,
          padding: theme.spacing(4, 4, 1.5, 4),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...typography.body2,
          padding: theme.spacing(0, 4, 3, 4),
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {},
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          borderTop: "1px solid #E7EAF9",
          margin: theme.spacing(0, 4, 4, 4),
          padding: theme.spacing(3, 0, 0, 0),
          "& > *": {
            minWidth: 80,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 4px rgb(0 0 0 / 8%)",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          textDecorationColor: "inherit",
          textUnderlineOffset: 2,
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "0 0 0 4px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "8px !important",
          },
          "& .MuiAutocomplete-popupIndicator svg": {
            fontSize: 20,
            marginTop: 2,
            marginRight: -2,
          },
        },
        popupIndicatorOpen: {
          transform: "rotate(0)",
        },
        input: {
          "&::placeholder": {
            color: "inherit",
          },
          "&.MuiInputBase-inputAdornedStart": {
            "&::placeholder": {
              color: "transparent",
            },
          },
        },
      },
    },
  };
};
