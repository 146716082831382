import React, { ComponentType } from "react";
import { AuthLayout } from "./auth-layout";

export const withAuthLayout =
  (ComposedComponent: ComponentType) => (props: any) => {
    return (
      <AuthLayout>
        <ComposedComponent {...props} />
      </AuthLayout>
    );
  };
