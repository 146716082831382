import { ReactNode, useContext } from "react";
import SnackbarContext from "../contexts/snackbar.context";

type SnackbarTypes = "success" | "error" | "info" | "warning";

interface UseSnackbarHook {
  showMessage: (message: string | ReactNode, type?: SnackbarTypes) => void;
}
export const useSnackbar = (): UseSnackbarHook => {
  return useContext(SnackbarContext);
};
