import { useState } from "react";
import { fullDayRange, getRangeByName } from "../utils/date.utils";
import { isArray } from "lodash";
import { endOfDay, startOfDay } from "date-fns";

export const useRangeFilter = () => {
  const [range, setRange] = useState<{ from: Date; to: Date }>(
    getRangeByName("month")
  );

  const handleMonthChange = (value) => {
    const range = getRangeByName(value);
    setRange(range);
  };

  const handlePickerChange = (value) => {
    const emptyRange = getRangeByName("month");
    if (isArray(value) && value[0]) {
      setRange({
        from: startOfDay(value[0]),
        to: endOfDay(value[1] || value[0]),
      });
    } else if (!isArray(value)) {
      setRange(fullDayRange(value));
    } else {
      setRange(emptyRange);
    }
  };

  return {
    range,
    handleMonthChange,
    handlePickerChange,
  };
};
