import React, { ComponentType, memo, useCallback, useEffect } from "react";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  items: {
    key: string;
    label: string;
  }[];
  onChange?: (key: number) => void;
}

export const FilterableTabs: ComponentType<Props> = memo(
  ({ onChange, items, children }) => {
    const navigate = useNavigate();
    const { hash, pathname } = useLocation();
    const [value, setValue] = React.useState<number>(0);

    const handleChange = useCallback((event: any, newValue: number) => {
      const key = items[newValue].key;
      setValue(newValue);
      onChange && onChange(newValue);
      navigate(pathname + `#${key}`, { replace: true });
    }, []);

    useEffect(() => {
      if (hash) {
        const index = items.findIndex((item) => item.key === hash.slice(1));
        if (index !== -1) {
          handleChange(null, index);
        }
      }
    }, [hash]);

    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <MuiTabs value={value} onChange={handleChange}>
            {items.map((item, index) => (
              <MuiTab key={index} label={item.label} />
            ))}
          </MuiTabs>
        </Box>
        <Box sx={{ pt: 3 }}>{children}</Box>
      </>
    );
  }
);
