import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "record",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(3, 3, 3, 2),
      borderRadius: 16,
    },
    icon: {
      marginRight: theme.spacing(3.125),
      "& svg": {
        fontSize: 40,
      },
    },
    up: {
      color: "#219653",
    },
    down: {
      color: "#EB5757",
    },
  }),
  options
);

export default useStyles;
