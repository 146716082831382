import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Card } from "../../components/card";
import { PageHeader } from "../../components/page-header";
import { SearchField } from "../../components/fields";
import { TableView } from "../../components/table-view";
import { EmptyState } from "../../components/empty-state";
import { IconFolder } from "../../components/icons";
import { resource } from "./resource";
import { ROUTE_CLIENT_CREATE } from "../../core/constants";
import { FilterableTabs } from "../../components/tabs/FilterableTabs";
import { get } from "lodash";
import { useClientList } from "../../core/hooks/useClientList";
import isEmpty from "lodash/isEmpty";

const TAB_ITEMS = [
  {
    key: "new",
    label: "New",
  },
  {
    key: "in-progress",
    label: "In progress",
  },
  {
    key: "onreview",
    label: "On review",
  },
  {
    key: "matched",
    label: "Matched",
  },
  {
    key: "rejected",
    label: "Rejected",
  },
  {
    key: "archived",
    label: "Archived",
  },
  {
    key: "all",
    label: "All clients",
  },
];
const DEFAULT_STATUS = "new";

export const ClientsContainer = () => {
  const navigate = useNavigate();
  const {
    getAllClients,
    clients,
    isLoading,
    handlePageChange,
    payload,
    handleParamsChange,
  } = useClientList();

  useEffect(() => {
    getAllClients(payload);
  }, [payload]);

  const handleChangeTab = (key: number) => {
    const status = get(TAB_ITEMS, `[${key}].key`, DEFAULT_STATUS);
    if (status === "all") {
      handleParamsChange({
        status: null,
        page: 1,
      });
    } else handleParamsChange({ status, page: 1 });
  };

  const handleChangeSearch = (search) => {
    handleParamsChange({ search, page: 1 });
  };

  const handleChangeSort = (sort) => {};

  const items = get(clients, "items", []);
  const pagesCount = get(clients, "pagesCount", 0);
  const showEmptyState =
    isEmpty(items) && (pagesCount === 0 || pagesCount === 1);

  return (
    <>
      <PageHeader title="Clients">
        <Button onClick={() => navigate(ROUTE_CLIENT_CREATE)}>
          Add new client
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <FilterableTabs onChange={handleChangeTab} items={TAB_ITEMS}>
          <SearchField
            placeholder="Search by ID client, Company name"
            sx={{ mb: 4 }}
            onChange={handleChangeSearch}
          />
          {showEmptyState ? (
            <EmptyState
              icon={<IconFolder />}
              loading={isLoading}
              description="0 clients found"
            />
          ) : (
            <TableView
              striped
              pagination={{
                page: payload.page,
                total: get(clients, "totalCount", 0),
                perPage: get(clients, "itemsPerPage", 20),
                onChange: handlePageChange,
              }}
              onChangeSort={handleChangeSort}
              keyExtractor={(client: any) => client.id}
              dataSource={items}
              columns={resource.columns}
            />
          )}
        </FilterableTabs>
      </Card>
    </>
  );
};
