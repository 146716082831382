import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PageHeader } from "../../components/page-header";
import { Card } from "../../components/card";
import { Tabs } from "../../components/tabs";
import { DatePicker, RadioGroup } from "../../components/fields";
import { historyData, profileFilterItems } from "../mock-data";
import { Record } from "../../components/record";
import { LabeledRecord } from "../../components/labeled-record";
import Button from "@mui/material/Button";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { ResetPasswordDialog } from "./ResetPasswordDialog";
import { EmptyState } from "../../components/empty-state";
import { IconFolderClock } from "../../components/icons";
import { TableView } from "../../components/table-view";
import { historyColumns } from "../clients/client-detail/resource";
import {
  useHistoryEmployeeMutation,
  useProfileMutation,
} from "../../core/store";
import get from "lodash/get";
import { toBackFormat } from "../../core/utils/date.utils";
import { useRangeFilter } from "../../core/hooks/useRangeFilter";
import isEmpty from "lodash/isEmpty";
import { differenceInDays, subDays } from "date-fns";
import { useLoading } from "../../core/hooks/useLoading";

const Info = ({
  handleMonthChange,
  handlePickerChange,
  data,
  prevData,
  user,
}) => {
  const [openConfirmDialog, closeConfirmDialog] = useDialog(ConfirmDialog);
  const [openResetPasswordDialog] = useDialog(ResetPasswordDialog);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <RadioGroup
          items={profileFilterItems}
          defaultValue="month"
          onChange={handleMonthChange}
        />
        <DatePicker onClose={handlePickerChange} />
      </Stack>
      <Grid container spacing={1.5}>
        <Grid item md={4}>
          <Record
            amount={get(data, "newClientsCount", 0)}
            prevAmount={get(prevData, "newClientsCount", 0)}
            title="New clients"
            color="warning"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            amount={get(data, "matchedClientsCount", 0)}
            prevAmount={get(prevData, "matchedClientsCount", 0)}
            title="MATCHED clients"
            color="success"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            amount={get(data, "rejectedClientsCount", 0)}
            prevAmount={get(prevData, "rejectedClientsCount", 0)}
            title="REJECTED Clients"
            color="error"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            amount={get(data, "createdGroupsCount", 0)}
            prevAmount={get(prevData, "createdGroupsCount", 0)}
            title="CREATED groups"
            color="warning"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            amount={get(data, "matchedGroupsCount", 0)}
            prevAmount={get(prevData, "matchedGroupsCount", 0)}
            title="MATCHED groups"
            color="success"
          />
        </Grid>
        <Grid item md={4}>
          <Record
            amount={get(data, "rejectedGroupsCount", 0)}
            prevAmount={get(prevData, "rejectedGroupsCount", 0)}
            title="REJECTED groups"
            color="error"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 1.5, mb: 3, color: "common.grey" }}>
        <Typography variant="body3">
          * % - Changes vs previous period
        </Typography>
      </Box>
      <Card variant="outlined" color="secondary" size="small" sx={{ p: 1 }}>
        <Grid container mb={3}>
          <Grid item md={6}>
            <LabeledRecord label="Email address:">
              {get(user, "email")}
            </LabeledRecord>
          </Grid>
          <Grid item md={6}>
            <LabeledRecord label="Role:">
              {get(user, "roleLabel")}
            </LabeledRecord>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            openConfirmDialog({
              title: "Are you sure you want to reset the password?",
              onConfirm: () => {
                closeConfirmDialog();
                openResetPasswordDialog();
              },
            });
          }}
        >
          Reset password
        </Button>
      </Card>
    </>
  );
};

const History = ({ id }) => {
  const [getHistoryData, { data, isLoading }] = useHistoryEmployeeMutation();

  useEffect(() => {
    if (id) {
      getHistoryData({ id });
    }
  }, [id]);

  const items = get(data, "items", []);

  return (
    <>
      {isEmpty(items) ? (
        <Card color="secondary" variant="outlined">
          <EmptyState
            minHeight={200}
            icon={<IconFolderClock />}
            loading={isLoading}
            description="Employee doesn't have historical records yet."
          />
        </Card>
      ) : (
        <Card
          title="History"
          color="secondary"
          variant="outlined"
          size="medium"
          sx={{ p: 1 }}
        >
          <TableView striped columns={historyColumns} dataSource={items} />
        </Card>
      )}
    </>
  );
};

export const ProfileContainer = () => {
  const [getProfileData, { data, isLoading }] = useProfileMutation();
  const [getPrevProfileData, { data: prevData }] = useProfileMutation();
  const [user, setUser] = useState(null);
  const { range, handlePickerChange, handleMonthChange } = useRangeFilter();

  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    getProfileData({
      statsFromDate: toBackFormat(range.from),
      statsToDate: toBackFormat(range.to),
    });
    const diffDays = differenceInDays(range.to, range.from);
    getPrevProfileData({
      statsFromDate: toBackFormat(subDays(range.from, diffDays)),
      statsToDate: toBackFormat(subDays(range.to, diffDays)),
    });
  }, [range]);

  useEffect(() => {
    if (isLoading) {
      showLoading("Loading...");
    } else {
      hideLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  if (!user) return null;

  return (
    <>
      <PageHeader title="Profile" />
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <Tabs
          items={[
            {
              key: "info",
              label: "Information",
              content: (
                <Info
                  {...{
                    handleMonthChange,
                    handlePickerChange,
                    data,
                    prevData,
                    user,
                  }}
                />
              ),
            },
            {
              key: "history",
              label: "History",
              content: <History id={get(data, "id")} />,
            },
          ]}
        />
      </Card>
    </>
  );
};
