import React, { ComponentType } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import { TableCellProps } from "@mui/material/TableCell/TableCell";
import { TableViewRow } from "./TableViewRow";
import { TableViewHeader } from "./table-view-header";
import { useTableSelection } from "./useTableSelection";
import { useTableSorting } from "./useTableSorting";
import { useTableFiltering } from "./useTableFiltering";
import useStyles from "./styles";
import clsx from "clsx";
import { Pagination } from "../pagination/Pagination";
import get from "lodash/get";

export interface DataTableFilterItem {
  label: string;
  value: string;
}

export interface DataTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  cellProps?: TableCellProps;
  render?: (value: any, record: any, renderProps: any) => any;
  sortable?: boolean;
  filters?: DataTableFilterItem[];
}

export interface DataTableRowSelection {
  selectedRowKeys: string[];
  onChange: (item: number[]) => void;
}

export interface DataTableRowExpandable {
  expandedRowRender: (record: any) => any;
  rowExpandable?: (record: any) => boolean;
}

interface Props {
  keyExtractor?: (item: Record<string, any>) => string;
  dataSource: Record<string, any>[];
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onRowClick?: (item: Record<string, any>) => void;
  expandable?: DataTableRowExpandable;
  hideColumns?: string[];
  striped?: boolean;
  renderProps?: any;
  pagination?: {
    page: number;
    total: number;
    perPage: number;
    onChange?: (page: number) => void;
  };
  onChangeSort?: (sortState: Record<string, "desc" | "asc">) => void;
  defaultSortState?: Record<string, "desc" | "asc">;
}

export const TableView: ComponentType<Props> = ({
  rowSelection,
  columns: defaultColumns,
  dataSource,
  onRowClick,
  expandable,
  hideColumns = [],
  striped,
  renderProps,
  keyExtractor = (item) => item.id,
  pagination,
  onChangeSort,
  defaultSortState = {},
}) => {
  const {
    selectedRows,
    isSelectedAll,
    handleSelectRow,
    isSelectedRowsEmpty,
    handleSelectAll,
  } = useTableSelection(dataSource, rowSelection);
  const { sortingState, sort } = useTableSorting(
    onChangeSort,
    defaultSortState
  );
  const { filteringState, filter, clearFilter } = useTableFiltering();

  const isSelectable = !!rowSelection;
  const columns = defaultColumns.filter(
    (column) => !hideColumns.includes(column.key)
  );
  const isExpandable = Boolean(expandable && !!expandable.expandedRowRender);
  const columnsCount =
    columns.length + (isSelectable ? 1 : 0) + (isExpandable ? 1 : 0);
  const classes = useStyles();

  const total = get(pagination, "total");
  const perPage = get(pagination, "perPage");
  const pagesCount = total && perPage ? Math.ceil(total / perPage) : 0;

  return (
    <>
      <TableContainer
        className={clsx(classes.root, { [classes.striped]: striped })}
      >
        <Table>
          <TableViewHeader
            {...{
              isExpandable,
              isSelectable,
              isSelectedAll,
              isSelectedRowsEmpty,
              handleSelectAll,
              columns,
              sortingState,
              sort,
              filteringState,
              filter,
              clearFilter,
            }}
          />
          <TableBody>
            {dataSource.map((item) => (
              <TableViewRow
                key={keyExtractor(item)}
                onClick={(item) => onRowClick && onRowClick(item)}
                {...{
                  id: keyExtractor(item),
                  expandable,
                  columnsCount,
                  item,
                  columns,
                  rowSelection,
                  selectedRows,
                  setSelectedRows: handleSelectRow,
                  renderProps,
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagesCount > 1 && (
        <Box mt={3}>
          <Pagination
            count={pagesCount}
            page={pagination?.page}
            onChange={(_, page) =>
              pagination?.onChange && pagination?.onChange(page)
            }
          />
        </Box>
      )}
    </>
  );
};
