import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "select",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    chipContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: 0.5,
      marginTop: "-2px",
      marginBottom: "-2px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      height: 24,
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    menuHeader: {
      lineHeight: 1,
      color: theme.palette.common.darkBlue,
      padding: theme.spacing(1, 1),
    },
    menuItem: {
      ...theme.typography.body3,
      lineHeight: 1.5,
      color: theme.palette.common.darkBlue,
      padding: theme.spacing(0.5, 1),
      "&:hover": {
        backgroundColor: theme.palette.common.lightGrey,
      },
    },
    menuItemSelected: {
      backgroundColor: `${theme.palette.common.lightGrey} !important`,
    },
  }),
  options
);

export default useStyles;
