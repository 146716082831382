import React, { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { DialogProps } from "../../core/providers/dialog.provider";
import { Space } from "../../components/space";
import { PasswordField } from "../../components/fields";
import { useProfileResetPassword } from "../../core/hooks/useProfileResetPassword";
import { useSnackbar } from "../../core/hooks/useSnackbar";

interface Props extends DialogProps {}

export const ResetPasswordDialog: ComponentType<Props> = ({ popDialog }) => {
  const { showMessage } = useSnackbar();
  const { handleSubmit, getFieldProps, isLoading } = useProfileResetPassword({
    onSuccess: () => {
      showMessage("Password successfully changed");
      popDialog();
    },
    onFail: () => {
      console.log("onFail");
    },
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { maxWidth: 480 } }}
    >
      <DialogTitle>New Password</DialogTitle>
      <DialogContent>
        <Space spacing={1} direction="column">
          <PasswordField label="New password" {...getFieldProps("password")} />
          <PasswordField
            label="Confirm new password"
            {...getFieldProps("passwordConfirmation")}
          />
        </Space>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
        <Button color="third" onClick={popDialog}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
