import React, { ComponentType, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { TableView } from "../../../components/table-view";
import { matchingColumns } from "../../matching/resource";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { useMatchingClients } from "../../../core/hooks/useMatchingClients";
import get from "lodash/get";
import { groupFiltersToMatching } from "../../../core/utils/hubspot.util";
import isEmpty from "lodash/isEmpty";
import { IconFolder } from "../../../components/icons";
import { EmptyState } from "../../../components/empty-state";
import { Card } from "../../../components/card";
import { useAddClientsToGroupMutation } from "../../../core/store";

interface Props extends DialogProps {
  group: any;
  hideLoading: () => void;
  showLoading: (message: string) => void;
  onSuccessfulAdd: () => void;
}

export const SearchNewClientsDialog: ComponentType<Props> = ({
  group,
  showLoading,
  hideLoading,
  popDialog,
  onSuccessfulAdd,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { clients, matchAllClients, isLoading } = useMatchingClients();
  const [addClients] = useAddClientsToGroupMutation();
  const { showMessage } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState<Record<any, any>>({
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    setValues(groupFiltersToMatching(get(group, "groupFilters")));
  }, [group]);

  useEffect(() => {
    matchAllClients(values);
  }, [values]);

  const onPageChange = (page: number) => {
    setValues({ ...values, page });
  };

  useEffect(() => {
    if (isLoading) {
      showLoading("Search new client by group filters");
    } else {
      hideLoading();
    }
  }, [isLoading]);

  const handleAdd = () => {
    setIsSubmitting(true);
    addClients({
      listId: get(group, "listId"),
      contactVids: selectedIds,
    })
      .unwrap()
      .then(() => {
        showMessage("Client was added to group successfully");
        popDialog();
        onSuccessfulAdd();
      })
      .catch(() => {
        showMessage("Error adding clients to the group", "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (isLoading) {
    return null;
  }

  const items = get(clients, "items");

  return (
    <Dialog open onClose={popDialog} maxWidth="lg">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Search new client by group filters</Box>
          <Box>
            <Button color="third" onClick={popDialog}>
              Close
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isEmpty(items) ? (
          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EmptyState
                icon={<IconFolder />}
                loading={isLoading}
                description="0 clients found"
              />
            </Box>
          </Card>
        ) : (
          <>
            <Box sx={{ mb: 3 }}>
              <Button
                onClick={handleAdd}
                disabled={isSubmitting || isEmpty(selectedIds)}
              >
                Add selected clients to group
              </Button>
            </Box>
            <TableView
              striped
              pagination={{
                page: values.page || 1,
                total: get(clients, "totalCount", 0),
                perPage: get(clients, "itemsPerPage", 20),
                onChange: onPageChange,
              }}
              rowSelection={{
                selectedRowKeys: [],
                onChange: setSelectedIds,
              }}
              dataSource={items}
              columns={matchingColumns}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
