import { useState } from "react";
import { useMatchingAllClientsMutation } from "../store";
import { isString } from "lodash";

export const useMatchingClients = () => {
  const [payload, setPayload] = useState<Record<string, any>>({
    page: 1,
  });
  const [clients, setClients] = useState<Record<string, any>>(null);

  const [matchAllClientsRequest, { isLoading, ...params }] =
    useMatchingAllClientsMutation();

  const handlePageChange = (page) => {
    setPayload((prev) => ({ ...prev, page, offset: (page - 1) * 20 }));
  };

  const handleParamsChange = (params) => {
    setPayload((prev) => ({ ...prev, ...params }));
  };

  const matchAllClients = (filter) => {
    matchAllClientsRequest(lowerCaseAllArrayValues(filter))
      .unwrap()
      .then((res) => {
        setClients(res);
      });
  };

  const clearClients = () => {
    setClients(null);
  };

  const lowerCaseAllArrayValues = (obj) => {
    if (!obj) return obj;

    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].map((item) =>
          item && isString(item) ? item.toLowerCase() : item
        );
      }
    });
    return obj;
  };

  return {
    matchAllClients,
    clearClients,
    clients,
    isLoading,
    handlePageChange,
    handleParamsChange,
    payload,
  };
};
