import { Expose, Transform } from "class-transformer";

export class GroupDto {
  @Expose()
  id: number;

  @Expose()
  name: string;

  @Expose()
  clients: number;

  @Expose()
  location: number;

  @Expose()
  comments: string;

  @Expose()
  budget: number;

  @Expose()
  status: number;

  @Expose()
  @Transform((value) => new Date(value))
  createdAt: Date;
}
