import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { PageHeader } from "../../components/page-header";
import { Typography, Box, Button } from "@mui/material";
import { MatchingFilters } from "./MatchingFilters";
import { Card } from "../../components/card";
import { TableView } from "../../components/table-view";
import { matchingColumns } from "./resource";
import { Checkbox } from "../../components/checkbox";
import { useDialog } from "../../core/hooks/useDialog";
import { GroupSelectionDialog } from "./GroupSelectionDialog";
import { useMatchingClients } from "../../core/hooks/useMatchingClients";
import get from "lodash/get";
import { IconFolder } from "../../components/icons";
import { EmptyState } from "../../components/empty-state";

export const MatchingContainer = () => {
  const [values, setValues] = useState<Record<any, any>>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const isIdSelected = !!selectedIds.length;
  const [openGroupSelection] = useDialog(GroupSelectionDialog);

  const {
    clients,
    matchAllClients,
    isLoading: loading,
    clearClients,
  } = useMatchingClients();

  const onSubmit = (values, isClear = false) => {
    if (isClear) {
      clearClients();
    } else {
      setValues(values);
    }
  };

  const onPageChange = (page: number) => {
    setValues({ ...values, page });
  };

  useEffect(() => {
    if (values) {
      const { filter, exception, ...other } = values;
      matchAllClients({
        page: 1,
        limit: 20,
        ...filter,
        ...exception,
        ...other,
      });
    }
  }, [values]);

  const results = get(clients, "items", []);
  const total = get(clients, "totalCount");

  const handleAddSelected = () => {
    openGroupSelection({ clientIds: selectedIds, groupValues: values });
  };

  return (
    <>
      <PageHeader title="Matching" />
      <MatchingFilters {...{ onSubmit, loading }} />

      {!isEmpty(results) && (
        <Card shadow>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3.5 }}>
            <Typography variant="h3" sx={{ mr: 4 }}>
              {total} results
            </Typography>
            <Checkbox sx={{ mr: 0 }} />
            <Typography variant="body2">Exclude "On review" clients</Typography>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Button disabled={!isIdSelected} onClick={handleAddSelected}>
              Add selected clients in new group
            </Button>
          </Box>
          <TableView
            pagination={{
              page: values.page || 1,
              total: get(clients, "totalCount", 0),
              perPage: get(clients, "itemsPerPage", 20),
              onChange: onPageChange,
            }}
            striped
            rowSelection={{
              selectedRowKeys: [],
              onChange: (ids) => setSelectedIds(ids),
            }}
            dataSource={results}
            columns={matchingColumns}
          />
        </Card>
      )}

      {total === 0 && (
        <Card shadow>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyState
              icon={<IconFolder />}
              loading={loading}
              description="0 clients found"
            />
          </Box>
        </Card>
      )}
    </>
  );
};
