import React, { ComponentType, useState } from "react";
import { Button } from "@mui/material";
import { Card } from "../../components/card";
import { Space } from "../../components/space";
import { IconSearch } from "../../components/icons";
import { SearchFilters } from "../../components/filters/SearchFilters";

interface Props {
  loading?: boolean;
  onSubmit?: (
    value: {
      filter: Record<string, any>;
      exception: Record<string, any>;
    },
    isClear?: boolean
  ) => void;
}

export const MatchingFilters: ComponentType<Props> = ({
  loading,
  onSubmit,
}) => {
  const [filter, setFilter] = useState({});
  const [exception, setException] = useState({});

  const handleFilter = (name) => (value) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handleException = (name) => (value) => {
    setException((prev) => ({ ...prev, [name]: value }));
  };

  const handleClearException = () => {
    setException({});
  };

  const handleClear = () => {
    setFilter({});
    setException({});

    onSubmit && onSubmit({ filter: {}, exception: {} }, true);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit({ filter, exception });
  };

  return (
    <Card title="Filters" shadow sx={{ mb: 2 }}>
      <SearchFilters
        sx={{ mb: 2 }}
        {...{
          filter,
          exception,
          handleFilter,
          handleException,
          handleClearException,
        }}
      />
      <Space spacing={1} justifyContent="flex-end">
        <Button onClick={handleClear} color="third">
          Clear all
        </Button>
        <Button
          disabled={!!loading}
          onClick={handleSubmit}
          startIcon={<IconSearch />}
        >
          Search
        </Button>
      </Space>
    </Card>
  );
};
