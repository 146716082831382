import React, { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-header";
import { Card } from "../../components/card";
import { Tabs } from "../../components/tabs";
import { Button } from "@mui/material";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeeInfo } from "./EmployeeInfo";
import { EmployeeHistory } from "./EmployeeHistory";
import {
  useDeleteEmployeeMutation,
  useFetchOneEmployeeMutation,
} from "../../core/store";
import { useLoading } from "../../core/hooks/useLoading";
import { useRangeFilter } from "../../core/hooks/useRangeFilter";
import { toBackFormat } from "../../core/utils/date.utils";
import { differenceInDays, subDays } from "date-fns";
import Grid from "@mui/material/Grid";
import { LabeledRecord } from "../../components/labeled-record";
import get from "lodash/get";

export const EmployeesDetailContainer = () => {
  const { id } = useParams();
  const { showLoading, hideLoading } = useLoading();
  const [getEmployeeData, { data: employee, isLoading }] =
    useFetchOneEmployeeMutation();
  const [getPrevEmployeeData, { data: employeePrev }] =
    useFetchOneEmployeeMutation();
  const [user, setUser] = useState(null);

  const { range, handlePickerChange, handleMonthChange } = useRangeFilter();

  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [openConfirmDialog, closeConfirmDialog] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      showLoading("Loading...");
    } else {
      hideLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (id && employee) {
      setUser(employee);
    }
  }, [id, employee]);

  useEffect(() => {
    getEmployeeData({
      id,
      statsFromDate: toBackFormat(range.from),
      statsToDate: toBackFormat(range.to),
    });
    const diffDays = differenceInDays(range.to, range.from);
    getPrevEmployeeData({
      id,
      statsFromDate: toBackFormat(subDays(range.from, diffDays)),
      statsToDate: toBackFormat(subDays(range.to, diffDays)),
    });
  }, [range]);

  const fullName = user?.fullName || "";

  const handleDelete = () => {
    openConfirmDialog({
      title: "Are you sure you want to delete this employee?",
      onConfirm: () => {
        closeConfirmDialog();
        deleteEmployee({ id })
          .unwrap()
          .then(() => {
            showMessage(`${fullName} was deleted successfully`);
            navigate("/employees");
          });
      },
    });
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <PageHeader title={fullName} backTitle="Employees">
        <Button variant="outlined" color="secondary" onClick={handleDelete}>
          Delete employee
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <Tabs
          items={[
            {
              key: "info",
              label: "Information",
              content: (
                <>
                  <EmployeeInfo
                    {...{
                      employee,
                      employeePrev,
                      range,
                      handlePickerChange,
                      handleMonthChange,
                    }}
                  />

                  <Card
                    variant="outlined"
                    color="secondary"
                    size="small"
                    sx={{ p: 1 }}
                  >
                    <Grid container>
                      <Grid item md={6}>
                        <LabeledRecord label="Email address:">
                          {get(user, "email")}
                        </LabeledRecord>
                      </Grid>
                      <Grid item md={6}>
                        <LabeledRecord label="Role:">
                          {get(user, "roleLabel")}
                        </LabeledRecord>
                      </Grid>
                    </Grid>
                  </Card>
                </>
              ),
            },
            {
              key: "history",
              label: "History",
              content: <EmployeeHistory id={id} />,
            },
          ]}
        />
      </Card>
    </>
  );
};
