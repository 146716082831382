import { useChangePasswordMutation, useResetPasswordMutation } from "../store";
import { useFormik } from "formik";
import * as yup from "yup";
import get from "lodash/get";
import { useEffect } from "react";

interface UseCreatePasswordProps {
  code: string;
  onSuccess: () => void;
  onFail: () => void;
}

const passwordMessage =
  "Password must contain at least 8 characters, including at least one number, one lowercase and one uppercase";

export const useCreatePassword = ({
  code,
  onSuccess,
  onFail,
}: UseCreatePasswordProps) => {
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: {
      code: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8, passwordMessage)
        .test("isValidPass", passwordMessage, (value, context) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          return hasUpperCase && hasLowerCase && hasNumber;
        })
        .required("Password is required"),
      passwordConfirmation: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("password"), null], "Passwords don’t match"),
    }),
    onSubmit: ({ code, password }) => {
      changePassword({ code, password })
        .unwrap()
        .then(handleSuccess)
        .catch(handleFail);
    },
  });

  useEffect(() => {
    setFieldValue("code", code);
  }, [code]);

  const handleSuccess = (res) => {
    console.log("handleSuccess", res);
    onSuccess();
  };

  const handleFail = (res) => {
    console.log("fail", res);
    const errorCode = get(res, "data.errorCode");
    if (errorCode === "incorrect_credentials") {
      setFieldError("password", "Incorrect reset code");
    }
    onFail();
  };

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
    };
  };

  return {
    isLoading,
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
