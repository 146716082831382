import { ComponentType } from "react";
import { StackProps } from "@mui/material";
import map from "lodash/map";
import { Chip } from "../chip";
import Stack from "@mui/material/Stack";
import { isString } from "lodash";

interface Props extends StackProps {
  value: string | string[];
}

export const ChipList: ComponentType<Props> = ({ value }) => {
  if (!value) return null;

  if (isString(value)) {
    return <Chip color="lightBlue" label={value} />;
  }

  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      width={115}
      sx={{
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      {map(value, (value) => (
        <Chip color="lightBlue" key={value} label={value} />
      ))}
    </Stack>
  );
};
