import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "password-recovery",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    backButton: {
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: 10,
      marginRight: 16,
      "&:hover": { backgroundColor: "#fff" },
      "& svg": { fontSize: 12, color: "#2E3A59" },
    },
  }),
  options
);

export default useStyles;
