import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { PageHeader } from "../../../components/page-header";
import { PhoneField, SelectField } from "../../../components/fields";
import { Card } from "../../../components/card";
import {
  ageGroupList,
  areaOfPremisesList,
  budgetList,
  businessTypeList,
  employeesList,
  genderList,
  portrayingList,
  pricingList,
} from "../../mock-data";
import { useClientForm } from "../../../core/hooks/useClientForm";
import { useNavigate, useParams } from "react-router-dom";
import { LocationField } from "../../../components/fields/location-field";
import isEmpty from "lodash/isEmpty";
import { ClientProperties, ROUTE_CLIENTS } from "../../../core/constants";

export const ClientCreateContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setFieldValue, getFieldProps, handleSubmit, isLoading } =
    useClientForm({ id });

  const handleCancel = () => {
    navigate(ROUTE_CLIENTS);
  };

  return (
    <>
      <PageHeader title="Add new client" backTitle="Clients">
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
        <Button color="secondary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </PageHeader>
      <Card shadow sx={{ p: 1, pt: 0 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Client information
        </Typography>
        <Grid container columnSpacing={1} sx={{ mb: 2 }}>
          <Grid item md={4}>
            <TextField
              label="Company"
              {...getFieldProps(ClientProperties.COMPANY)}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="First name"
              {...getFieldProps(ClientProperties.FIRSTNAME)}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Last name"
              {...getFieldProps(ClientProperties.LASTNAME)}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} sx={{ mb: 2 }}>
          <Grid item md={4}>
            <TextField
              label="Email address"
              {...getFieldProps(ClientProperties.EMAIL_ADDRESS)}
            />
          </Grid>
          <Grid item md={4}>
            <PhoneField
              label="Phone number"
              {...getFieldProps(ClientProperties.PHONE_NUMBER)}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={2}>
            <TextField
              type="number"
              label="Retail spots"
              {...getFieldProps(ClientProperties.RETAIL_SPOTS)}
            />
          </Grid>
          <Grid item md={2}>
            <SelectField
              label="Employees"
              items={employeesList}
              {...getFieldProps(ClientProperties.NUMBER_EMPLOYEES)}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Website"
              {...getFieldProps(ClientProperties.WEBSITE)}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Socials"
              {...getFieldProps(ClientProperties.SOCIALS)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <TextField
          label="Comment"
          multiline
          rows={6}
          {...getFieldProps("description")}
        />
        <Divider sx={{ mb: 2, mt: 2 }} />
        <Typography variant="h4" sx={{ mb: 2 }}>
          Quiz form
        </Typography>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item lg={4} md={6} sm={12}>
            <LocationField
              placeholder="Select"
              label="Location"
              {...getFieldProps(ClientProperties.CITY)}
              onChange={(value) => {
                if (value && value.city) {
                  setFieldValue(
                    ClientProperties.CITY,
                    [value.city, value.state].filter(Boolean).join(", ")
                  );
                  // setFieldValue(ClientProperties.STATE, value.state);
                } else {
                  setFieldValue(ClientProperties.CITY, null);
                  // setFieldValue(ClientProperties.STATE, null);
                }
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <SelectField
              group
              multiple
              placeholder="Select"
              label="Business type"
              items={businessTypeList}
              {...getFieldProps("business_type_temp")}
              onChange={(value) => {
                setFieldValue("business_type_temp", value);
                if (value && !isEmpty(value)) {
                  if (value[0])
                    setFieldValue(ClientProperties.BUSINESS_TYPE, value[0]);
                  if (value[1])
                    setFieldValue(ClientProperties.BUSINESS_TYPE2, value[1]);
                  if (value[2])
                    setFieldValue(ClientProperties.BUSINESS_TYPE3, value[3]);
                } else {
                  setFieldValue(ClientProperties.BUSINESS_TYPE, null);
                  setFieldValue(ClientProperties.BUSINESS_TYPE2, null);
                  setFieldValue(ClientProperties.BUSINESS_TYPE3, null);
                }
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <SelectField
              placeholder="Select"
              label="Area of premises (sq ft)"
              items={areaOfPremisesList}
              {...getFieldProps(ClientProperties.AREA)}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} sx={{ mb: 2 }}>
          <Grid
            item
            lg={4}
            md={12}
            sx={{ borderRight: "1px solid #EAE8FF", paddingRight: 2 }}
          >
            <Grid container spacing={1}>
              <Grid item lg={6} md={6}>
                <SelectField
                  multiple
                  placeholder="Select"
                  label="Age group"
                  items={ageGroupList}
                  {...getFieldProps("age_temp")}
                  onChange={(value) => {
                    setFieldValue("age_temp", value);
                    if (value && !isEmpty(value)) {
                      if (value[0])
                        setFieldValue(ClientProperties.AGE, value[0]);
                      if (value[1])
                        setFieldValue(ClientProperties.AGE2, value[1]);
                    } else {
                      setFieldValue(ClientProperties.AGE, null);
                      setFieldValue(ClientProperties.AGE2, null);
                    }
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <SelectField
                  placeholder="Select"
                  label="Gender"
                  items={genderList}
                  {...getFieldProps(ClientProperties.GENDER)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={12}>
            <Grid container spacing={1}>
              <Grid item lg={4} md={4} sm={12}>
                <SelectField
                  placeholder="Select"
                  label="Budget"
                  items={budgetList}
                  {...getFieldProps(ClientProperties.BUDGET)}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <SelectField
                  placeholder="Select"
                  label="Pricing"
                  items={pricingList}
                  {...getFieldProps(ClientProperties.PRICING)}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <SelectField
                  placeholder="Select"
                  label="Portraying"
                  items={portrayingList}
                  {...getFieldProps(ClientProperties.PORTRAY)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
