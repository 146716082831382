import { useState } from "react";
import { useFetchAllClientsMutation } from "../store";

export const useClientList = () => {
  const [payload, setPayload] = useState({
    status: "new",
    page: 1,
    limit: 20,
  });

  const [getAllClients, { data: clients, isLoading }] =
    useFetchAllClientsMutation();

  const handlePageChange = (page) => {
    setPayload((prev) => ({ ...prev, page }));
  };

  const handleParamsChange = (params) => {
    setPayload((prev) => ({ ...prev, ...params }));
  };

  return {
    getAllClients,
    clients,
    isLoading,
    handlePageChange,
    handleParamsChange,
    payload,
  };
};
