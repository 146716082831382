import { useState, ReactNode, ComponentType, memo } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import useStyles from "./styles";

interface Props extends BoxProps {
  editable?: boolean;
  editableView: ReactNode;
  actionText?: string;
  onSubmit?: () => void;
}

export const Editable: ComponentType<Props> = memo(
  ({
    editable = true,
    actionText = "Edit",
    editableView,
    children,
    onSubmit,
    ...props
  }) => {
    const [isEditable, setIsEditable] = useState(false);
    const classes = useStyles();

    const handleToggle = () => {
      setIsEditable(!isEditable);
    };

    const handeSubmit = () => {
      onSubmit && onSubmit();
      setIsEditable(false);
    };

    return (
      <Box className={classes.root} {...props}>
        {isEditable ? (
          <>
            <Box sx={{ mb: 2 }}>{editableView}</Box>
            <Stack spacing={1} direction="row">
              <Button onClick={handeSubmit} sx={{ minWidth: 80 }}>
                Save
              </Button>
              <Button
                color="third"
                onClick={handleToggle}
                sx={{ minWidth: 80 }}
              >
                Cancel
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Box sx={editable ? { mb: 2 } : {}}>{children}</Box>
            {editable && (
              <Link onClick={handleToggle} variant="body2">
                {actionText}
              </Link>
            )}
          </>
        )}
      </Box>
    );
  }
);
